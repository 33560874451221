import { useEffect, useMemo, useState, useCallback } from "react";
//import { getQueryStringValue, setQueryStringValue } from "./queryString";

import qs from "query-string";

// setQueryStringWithoutPageReload 
const setQueryStringWithoutPageReload = qsValue => {
  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    qsValue;
  window.history.pushState({ path: newurl }, "", newurl);
};

// getQueryStringValue 
const getQueryStringValue = (
  key,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  return values[key];
};

// setQueryStringValue
const setQueryStringValue = (
  key,
  value,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({
    ...values,
    [key]: value
  });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};
//useQueryString
const useQueryString = (key, initialValue) => {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}
//useQueryStringWithIndexValue
const useQueryStringWithIndexValue = (key, initialIndex, values) => {
  const computedValues = useMemo(() => values.map(v => v.toLowerCase()), [
    values
  ]);
  const [value, onSetValue] = useQueryString(key, values[initialIndex]);
  const [index, setIndex] = useState(initialIndex);

  const onSetIndex = useCallback(
    index => {
      const newValue = computedValues[index];
      onSetValue(newValue);
    },
    [computedValues, onSetValue]
  );

  useEffect(
    function onValueChange() {
      const newIndex = computedValues.findIndex(v => v === value);
      setIndex(newIndex >= 0 ? newIndex : initialIndex);
    },
    [value, computedValues, initialIndex]
  );

  return [index, onSetIndex];
}

//arrayToQueryString
const arrayToQueryString = (array_in) => {
  var out = new Array();
  for (var key in array_in) {
    out.push(key + '=' + array_in[key]);
  }
  return out.join('&');
}
export default {
  useQueryString,
  arrayToQueryString,
  useQueryStringWithIndexValue
}


//import axios from "axios";
import authHeader from "../helpers/header";
import apiInstance from "./api";
import helper from "../helpers/query";

// import { API_URL } from "../configs";
//const API_URL = "http://localhost:8000/users/";
//const API_URL = "http://localhost:8000/";

// get user data by id
const getUser = async (id) => {
  //const res = await axios.get(API_URL + `users/${id}`, { headers: authHeader() });
  const res = await apiInstance.get(`users/${id}`, { headers: authHeader() });
  return res.data;
};

// get user data by id
const getCreatorStat = async (id) => {
  //const res = await axios.get(API_URL + `users/${id}`, { headers: authHeader() });
  const res = await apiInstance.get(`reports/total/by_creator/${id}`, {
    headers: authHeader(),
  });
  return res.data;
};

// create new user from backend
const createUser = async (data) => {
  //return axios.post(API_URL + 'users/', {
  return await apiInstance.post(
    "users/",
    {
      email: data.email,
      password: "",
      first_name: data.first_name,
      last_name: data.last_name,
      disabled: data.disabled,
      roles: [data.roles],
    },
    { headers: authHeader() }
  );
};

// create new user from backend
const updateUser = async (id, data) => {
  //return axios.patch(API_URL + `users/${id}`, {
  return await apiInstance.patch(
    `users/${id}`,
    {
      data,
    },
    { headers: authHeader() }
  );
};

// function refreshToken () {
//   return apiInstance.post('/auth/refresh', { headers: authHeader() }
//     //{
//     //refresh_token: getLocalRefreshToken()
//     // headers: {
//     //   //'x-access-token': getLocalRefreshToken() // headers token
//     //   Authorization: 'Bearer ' + getLocalRefreshToken()
//     // }
//     //}
//   )
// }

// send request to get current loggedin user
const getRemoteUser = async () => {
  const res = await apiInstance.get("/users/staff", { headers: authHeader() });
  return res.data;
};

// get all user data
const getAllUsers = async (query) => {
  let newQuery = {};

  if (query.page) {
    newQuery.offset = parseInt(query.page) - 1;
  }
  if (query.pageSize) {
    newQuery.limit = parseInt(query.pageSize);
  }

  if (query.s) {
    newQuery.s = query.s;
  }

  if (query.status) {
    newQuery.disabled = query.status;
  }

  if (query.role) {
    newQuery.role = query.role;
  }

  if (query.create_from) {
    newQuery.create_from = query.create_from;
  }
  if (query.create_to) {
    newQuery.create_to = query.create_to;
  }
  if (query.sortby) {
    newQuery.sortby = query.sortby;
  }
  if (query.direction) {
    newQuery.direction = query.direction;
  }

  let q = helper.arrayToQueryString(newQuery);

  // console.log(`users/?${q}`)
  //const response = await axios.get(API_URL + `users/?${q}`)
  //return response.data
  const res = await apiInstance.get(`users/?${q}`);
  return res.data;
};

//@TODO : should check this
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const exportedObject = {
  getUser,
  createUser,
  updateUser,
  getAllUsers,
  getRemoteUser,
  getCurrentUser,
  getCreatorStat,
};

export default exportedObject;

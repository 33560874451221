import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import OrderApi from "../../api/orderAPI";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import format from "../../helpers/format";

const OrderDetail = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      await OrderApi.getOrderDetail(id)
        .then((res) => {
          setOrder(res);
        })
        .catch((err) => console.log(err));

      setLoading(false);
    })();
  }, [id]);

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h1>Order Detail</h1>
        <Link className={"btn btn-primary"} to={`/orders/edit/${order._id}`}>
          Edit
        </Link>
      </header>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : order && Object.keys(order).length > 0 ? (
        <div>
          <div className="row mb-2">
            <div className="col-3">Email</div>
            <div className="col-9">{order.email}</div>
          </div>
          <div className="row mb-2">
            <div className="col-3">Price</div>
            <div className="col-9">{format.formatCurrency(order.price)}</div>
          </div>
          <div className="row mb-2">
            <div className="col-3">Payment Status</div>
            <div className="col-9">
              {format.formatPaymentStatus(order.paymentStatus)}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3">Order Status</div>
            <div className="col-9">
              {format.formatOrderStatus(order.orderStatus.status)}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3">Created At</div>
            <div className="col-9">
              <Moment format="YYYY-MM-DD HH:mm:ss">{order.created_at}</Moment>
            </div>
          </div>
          {order.updated_at && (
            <div className="row">
              <div className="col-3">Updated At</div>
              <div className="col-9">
                <Moment format="YYYY-MM-DD HH:mm:ss">{order.updated_at}</Moment>
              </div>
            </div>
          )}
        </div>
      ) : (
        <p>{"Not found"}</p>
      )}
    </AuthLayout>
  );
};

export default OrderDetail;

import { SET_MESSAGE, CLEAR_MESSAGE } from "../actions/types";

const initialState = {
  message: "",
  messageType: "danger",
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      //console.log(action.messageType)
      return {
        ...state,
        message: payload,
        messageType: action.messageType
          ? action.messageType
          : initialState.messageType,
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: "",
        messageType: "danger",
      };

    default:
      return state;
  }
}

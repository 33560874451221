import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import helper from "../../helpers/query";
import format from "../../helpers/format";
import { history } from "../../redux/store";
import Pagination from "../../components/Pagination";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import Filter from "../../components/Order/Filter";
import { Table } from "react-bootstrap";
import OrderApi from "../../api/orderAPI";
import qs from "query-string";

const initialQuery = {
  status: "",
  pageSize: 20,
  page: "",
};

const OrderList = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [pageSize, setPageSize] = useState(initialQuery.pageSize); //@TODO : should correct this
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1); //helper.useQueryString("page");
  const [query, setQuery] = useState(initialQuery);

  const loadPage = (params) => {
    let q = helper.arrayToQueryString(params);
    setSearchParams(params);

    history.push({
      pathname: "/orders",
      search: `?${q}`,
      state: params,
    });
  };

  const fetchUsers = async (query) => {
    setLoading(true); //@TODO
    //get users data via API
    await OrderApi.getAllOrders(query)
      .then((res) => {
        setCount(res.length);
        setOrders(res);
        setLoading(false); //@TODO
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (page) => {
    let params = {};
    if (query) {
      if (query.status) {
        params.status = query.status;
      }
      if (query.s) {
        params.s = query.s;
      }
    }
    params.page = page;
    loadPage(params);
  };

  const filterHandle = (data) => {
    let params = {};

    if (data.status) {
      params.status = data.status;
    }

    if (data.orderType) {
      params.orderType = data.orderType;
    }

    params.page = 1;
    loadPage(params);
  };

  // handling filter reset function
  const handleReset = () => {
    setQuery(initialQuery);
    loadPage({});
  };

  useEffect(async () => {
    const newQuery = Object.assign({}, query);

    if (searchParams.get("status")) {
      newQuery.status = searchParams.get("status");
    }

    if (searchParams.get("page")) {
      newQuery.page = searchParams.get("page");
      setCurrentPage(searchParams.get("page"));
    }

    if (searchParams.get("orderType")) {
      newQuery.page = searchParams.get("orderType");
      setCurrentPage(searchParams.get("orderType"));
    }

    setQuery(newQuery);
    await fetchUsers(qs.stringify(newQuery));
  }, [location]);

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h2>{"Orders"}</h2>
        {/* <Link className={"btn btn-primary px-4"} to={`/orders/new`}>
          {"Create"}
        </Link> */}
      </header>

      <Filter
        currentFilter={query}
        filterHandle={filterHandle}
        resetHandle={handleReset}
      />
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : orders.length > 0 ? (
        <>
          <Table responsive className="table table-striped mb-4">
            <thead>
              <tr className="text-capitalize">
                <th scope="col" className="fw-normal text-muted">
                  email
                </th>
                <th scope="col" className="fw-normal text-muted">
                  amount
                </th>
                <th scope="col" className="fw-normal text-muted">
                  order type
                </th>
                <th scope="col" className="fw-normal text-muted">
                  date
                </th>
                <th scope="col" className="fw-normal text-muted">
                  payment status
                </th>
                <th scope="col" className="fw-normal text-muted">
                  status
                </th>
                <th scope="col" className="fw-normal text-muted">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="border-top-0">
              {orders.map((item, index) => (
                <tr key={index} className="align-middle">
                  <td>
                    <Link to={`/orders/view/${item._id}`}>{item.email}</Link>
                  </td>
                  <td>{format.formatCurrency(item.total)}</td>
                  <td className="text-capitalize">{item.orderType}</td>
                  <td className="text-muted">
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {item.created_at}
                    </Moment>
                  </td>
                  <td>{format.formatPaymentStatus(item.paymentStatus)}</td>
                  <td>{format.formatOrderStatus(item.orderStatus.status)}</td>
                  <td>
                    <div className="button-group">
                      <Link
                        to={`/orders/edit/${item._id}`}
                        className="btn btn-sm"
                      >
                        <i className="bi bi-pencil-square text-muted"></i>
                      </Link>
                      {/* <Link
                        to={`/oders/delete/${index}`}
                        className="btn btn-sm"
                      >
                       
                        <i className="bi bi-trash-fill text-muted"></i>
                      </Link> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            count={count}
            currentPage={currentPage}
            pageSize={pageSize}
            path={"orders"}
            moduleName={"orders"}
            handlePageChange={handlePageChange}
          />
        </>
      ) : (
        <p>{"Not found"}</p>
      )}
    </AuthLayout>
  );
};

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  return {
    token,
    user,
  };
}

export default connect(mapStateToProps)(OrderList);

const getLocalRefreshToken = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return token.refresh_token
};

const getLocalAccessToken = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return token.access_token
};

const updateLocalAccessToken = (token) => {
  // let token = JSON.parse(localStorage.getItem("token"));
  // token.accessToken = token;
  // localStorage.setItem("token", JSON.stringify(token));
  let existing_token = Object.assign({}, JSON.parse(localStorage.getItem("token")));
  //update token value
  existing_token.access_token = token;
  localStorage.setItem("token", JSON.stringify(existing_token));
};

const setLocalToken = (token) => {
  //console.log(JSON.stringify(token));
  localStorage.setItem("token", JSON.stringify(token));
};

// const getUser = () => {
//   return JSON.parse(localStorage.getItem("token"));
// };

const removeToken = () => {
  localStorage.removeItem("token");
};

const apiStorage = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  // getUser,
  setLocalToken,
  removeToken,
};

export default apiStorage;
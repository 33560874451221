import axios from "axios";
import authHeader from "../../helpers/header";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { setMessage } from "../../redux/actions/message";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import ResourceAPI from "../../api/resourceAPI";
import { API_URL, MEDIA_URL, OPENAI_KEY } from "../../configs";
import promptsData from '../../data/openai-prompts.json';

function ResourcesBulkEdit(props) {
    const {
      register,
      handleSubmit,
      setValue,
      getValues,
      watch,
      formState: { errors },
    } = useForm();

    const dispatch = useDispatch();
    const { message, messageType } = props;
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    //const [imageUrl, setImageUrl] = useState(null);
    const [data, setData] = useState([]);
    const [generateStatistic, setGenerateStatistic] = useState([]);

    const [prompt, setPrompt] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [generating, setGenerating] = useState(false)
    const [selectedId, setSelectedId] = useState([]);
    const [selectedThumbnails, setSelectedThumbnails] = useState([]);
    const [generatedData, setGeneratedData] = useState(null);
    const [selectedData, setSelectedData] = useState({
      title: '',
      description: '',
      tags: []
    });
    
    // Function to handle prompt generation and fetch data
    const handleGenerateData = async () => {
      
      // Generate prompt if empty or use the adjusted one
      const promptText = prompt || `Generate a title, description, and tags for the image located at: ${imageUrl}`;
      console.log(`${imageUrl}`)

      let msg_content = [
        { type: 'text', text: promptText }
      ];

      // console.log(selectedThumbnails)
      // return

      //build prompt and content base selectedThumbnail
      if (selectedThumbnails.length > 0) {
        selectedThumbnails.map((url) => (
          msg_content.push({
            type: "image_url",
            image_url: {
              url: url
            }
          }) 
        ));
      }else{
        dispatch(setMessage(`Please select a resource`, "danger"));
        return;
      }

      let a =JSON.stringify(msg_content)
      console.log(a)
      console.log({
        model: 'gpt-4o-mini',  // Specify the model you're using
        messages: [
          { role: 'system', content: 'You are a helpful assistant.' },
          {
            role: 'user',
            content: msg_content
          },
        ],
        response_format: { type: "json_object" },
    })
      try {
        setGenerating(true)
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${OPENAI_KEY}`,  // Replace with your actual API key
          },
          body: JSON.stringify({
            model: 'gpt-4o-mini',  // Specify the model you're using
            messages: [
              { role: 'system', content: 'You are a helpful assistant.' },
              {
                role: 'user',
                content: msg_content
              },
            ],
            response_format: { type: "json_object" },
          }),
        });
    
        // Parse the response
        const completion = await response.json();
        // Extract the content from the message in the response
        const { content } = completion.choices[0].message;
        // Log the content for debugging
        console.log(content.replace(/`/g, '').replace('json', ''));
        // Parse the JSON-formatted content from OpenAI response
        const parsedData = JSON.parse(content.replace(/`/g, '').replace('json', '')); 
        console.log(parsedData)
        // Set the parsed data in the state
        setGeneratedData(parsedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }finally {
        setGenerating(false)
      }
    };

    const handleGenerate = async() => {
      //let ids = searchParams.get('resources_id')

      if(!selectedId || selectedId.length <= 0) {
        dispatch(setMessage(`Please select a resource`, "danger"));
        return;
      }
      selectedId.forEach( async (id) => {
        try {
          const res = await axios.get(API_URL + `datas/renamePreview/${id}`)
          if(res.status === 200){
              console.log(res.data)
              //setData(res.data)
              // Update the state by appending the new result
              setGenerateStatistic(prevState => [...prevState, res.data]);
          }else{
            console.log('err')
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      })
      //async function getResourcesImages(ids) {
      
      //}
  }

    /**
     * Save resources data on submit form
     * @param {form} data
     */
    const handleUpdate = async (data) => {
      
      if(!selectedId || selectedId.length <= 0) {
        dispatch(setMessage(`Please select a resource`, "danger"));
        return;
      }

      try {
        //const response = await axios.patch(API_URL + `resources/${data.resource_id}`, 
        const response = await axios.post(API_URL + `resources/bulkUpdate`,
          { data : { 
              resource_ids : selectedId,
              new_name: data.name,
              description: data.description,
              new_tags: data.tags_str,
              canonical_resource : data.resource_id[0],
              generate_thumbnail : data.generate_thumbnail
            }
          }, 
          { headers: authHeader() }
        )
        dispatch(setMessage(`${selectedId.length} resources  was updated`, "success"));
      } catch (e) {
        dispatch(setMessage(`${e}`, "danger"));
      };
      // setIsLoading(false);
    };

     // Handle checkbox change
    const handleIdChange = (e, imageUrl) => {
      const { checked, value } = e.target;

      if (checked) {
        // Add the ID to the selected list
        setSelectedId((prevSelectedId) => [...prevSelectedId, value]);

        // Add the image URL to the selected list
        setSelectedThumbnails((prevSelectedThumbnails) => [...prevSelectedThumbnails, imageUrl]);
      } else {
        // Remove the ID from the selected list
        setSelectedId((prevSelectedId) =>
          prevSelectedId.filter((id) => id !== value)
        );

        // Remove the image URL from the selected list
        setSelectedThumbnails((prevSelectedThumbnails) =>
          prevSelectedThumbnails.filter((url) => url !== imageUrl)
        );
      }
    };

    // Handle selected all
    const handleCheckAll = () => {
      if (selectedId.length === 0) {
        data.map((resource) => {
          setSelectedId((selectedId) => [...selectedId, resource._id]);
          return selectedId;
        });
      } else {
        setSelectedId([]);
      }
    };

    const handleSelectData = (data) => {
      setSelectedData({
        title: data.title,
        description: data.description,
        tags: data.tags,
      });
      console.log(data.title)
      setValue('name', data.title, { shouldValidate: true })
      setValue('description', data.description)
      setValue('tags_str', data.tags.join(','))
    };

    useEffect(() => {
        let ids = searchParams.get('resources_id')
        async function getResourcesImages(ids) {
            const res = await axios.get(API_URL + `resources/getbulk/?ids=${ids}`)
            if(res.status === 200){
                setData(res.data)
            }
        }
        getResourcesImages(ids)
        console.log(promptsData)
        //setPrompt(`${MEDIA_URL}${data[0].thumbnail.path}`)
        
      
    }, [generateStatistic])

    useEffect(() => {
      //console.log(data[0])
      if(data[0]) {
        //setPrompt(`${MEDIA_URL}${data[0].thumbnail.path}`)
        setImageUrl(data[0].thumbnail ? `${MEDIA_URL}${data[0].thumbnail.path}` : '')
        setPrompt( promptsData.single_resource );
      }
    }, [data])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Test data generate"}</h2>
            </header>
            <div>
                {data && 
                <>
                <div className="mb-4 flex">
                <button
                    className="btn bg-light px-3 btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCheckAll();
                    }}
                  >
                    {selectedId.length > 0 ? "Unselect all" : "Select all"}
                  </button>
                  {selectedId.length > 0 && (
                    <span className="small mx-4 text-muted">
                      {selectedId.length} {"selected"}
                    </span>
                  )}
                  </div>
                  
                <div className="row mb-4">
                    {data.map((data, index) => {
                        const thumbnailUrl = data.thumbnail ? `${MEDIA_URL}${data.thumbnail.path}` : ''; // Get image URL
                        return(
                            <div key={index} className="col-md-2">
                                <img className="img-thumbnail bg-light" src={`${thumbnailUrl}`} with="200" />
                                <p className="mt-2 mb-0 py-0">{data.name}</p>
                                <p className="text-muted mt-1 mb-1 py-0">
                                    <Link to={`/resources/view/${data._id}`} target="_blank" rel="noreferrer">
                                        <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data._id}</span>
                                    </Link>
                                </p>
                                {data.thumbnail &&
                                <p className="text-muted mb-2 py-0">
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2 text-uppercase">{data.thumbnail?.contentType}</span>
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data.thumbnail?.width}{' x '}{data.thumbnail?.height}</span>
                                </p>
                                }
                                <input
                                  type="checkbox"
                                  value={data._id}
                                  className="form-check-input"
                                  {...register(`resource_id.${index}`)}
                                  //name={resource._id}
                                  onChange={(e) => handleIdChange(e, thumbnailUrl)}
                                  //checked={selectedId.includes(resource._id) ? 'checked' : ''}
                                  checked={
                                    selectedId.indexOf(data._id.trim()) > -1
                                      ? true
                                      : false
                                  }
                                />
                            </div>
                        )
                    })
                    }
                </div>
                </>
                }
                <div className="mb-4">
                <label className="form-label">Prompt:</label>
                <textarea rows={5} 
                  type="text"
                  value={prompt}
                  className="form-control mb-2 input-sm"
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Enter or adjust the prompt"
                  style={{ width: '100%' }}
                >
                </textarea>
                <button className="btn btn-secondary" onClick={handleGenerateData}>Generate Data</button>
                </div>
                {/* Show generated data */}
                {generatedData && (
                  <div className="mb-4">
                    <h3>Generated Data</h3>
                    <p><span className="text-muted">Title:</span> {generatedData.title}</p>
                    <p><span className="text-muted">Description:</span> {generatedData.description}</p>
                    <p><span className="text-muted">Tags:</span> {generatedData.tags.join(',')}</p>

                    <button className="btn btn-outline-secondary" onClick={() => handleSelectData(generatedData)}>Use This Data</button>
                  </div>
                )}
                {
                  generating &&
                  <p className="alert alert-warning my-2">{'Your request is proccessing, please wait...!!!'}</p>
                }
                {/* Form to display selected data */}
                <h6>Selected Data</h6>
                <form className="flex" onSubmit={handleSubmit(handleUpdate)}>
                  <div className="mb-2">
                  <label className="form-label">
                    Title:
                    </label>
                    <input type="text" 
                           className="form-control" 
                           //value={''}
                          //  value={selectedData?.title ? selectedData.title : ''}
                          //  onChange={(event) => {
                          //   setValue("name", event.target.value);
                          //   setSelectedData((prevData) => ({
                          //     ...prevData,
                          //     title: event.target.value
                          //   }));
                          //  }}
                           //value={'black friday sale promotion with modern minimalism and eye-catching offer details for retail shoppers'}
                           {...register("name", { required: true })} 
                            />
                 
                  </div>
                  <div className="mb-2">
                  <label className="form-label">
                    Description:</label>
                    <textarea 
                    className="form-control" 
                    rows={5} 
                    //value={selectedData?.description ? selectedData.description : ''} 
                    //value={'A stylish promotional graphic tailored for Black Friday sales, featuring a striking combination of modern minimalism and vibrant colors. This design emphasizes a special offer with 50% discount details, perfect for attracting retail shoppers and enhancing online shopping experiences. Ideal for e-commerce platforms looking to boost seasonal sales.'}
                    {...register("description")} 
                    ></textarea>
                  
                  </div>
                  <div className="mb-2">
                  <label className="form-label">
                    Tags:</label>
                    <textarea 
                    className="form-control" 
                    //value={selectedData?.tags? selectedData.tags : ''} 
                    //value={'black friday, sale promotion, retail, seasonal sale, minimalism, e-commerce, special offers, shopping, marketing, discounts, advertising, attractive graphic'}
                    {...register("tags_str")} 
                     >
                    </textarea>
                  </div>
                  {/* <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="generate_thumbnail"
                      aria-label="Checkbox for following text input"
                      {...register("generate_thumbnail")}
                    />
                    <label
                      htmlFor="generate_thumbnail"
                      className="form-check-label ms-2"
                    >
                      <span> Generate new preview url</span>
                    </label> */}
                  {message && (
                    <div className="mb-4">
                      <div className={`alert alert-${messageType}`} role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                  <button 
                    className="btn btn-primary"
                  >Update resource</button>
                </form>
                {generateStatistic.length > 0 ? (
                  generateStatistic.map((stat, index) => (
                    // <li key={index}>
                    //   <strong>Status:</strong> {stat[0].status} <br />
                    //   <strong>Message:</strong> {stat[0].message} <br />
                    // </li>
                    <div key={index} className={`alert py-2 mt-2 mb-1 alert-${stat[0].status}`} role="alert">
                    {stat[0].message}
                  </div>
                  ))
                ) : ('')}
                <button 
                  className="btn btn-secondary mt-2"
                  onClick={() => {
                    handleGenerate()
                }}
                >Generate new preview url</button>
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ResourcesBulkEdit);
      
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setMessage } from "../../redux/actions/message";
import OrderApi from "../../api/orderAPI";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import format from "../../helpers/format";

const OrderEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const { message, messageType } = useSelector((state) => state.message);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    (async () => {
      await OrderApi.getOrderDetail(id)
        .then((res) => {
          setOrder(res);
          setValue("orderStatus", res.orderStatus.status);
        })
        .catch((err) => console.log(err));

      setLoading(false);
    })();
  }, [id]);

  const handleOnSubmit = async () => {
    const updateInfo = {
      ...order,
      orderStatus: { status: getValues("orderStatus") },
    };
    await OrderApi.updateOrder(id, updateInfo)
      .then(() => {
        dispatch(setMessage("Update successfully", "success"));
      })
      .catch((err) => {
        dispatch(setMessage(err, "danger"));
      });
  };

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h1>Order Edit</h1>
      </header>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : order && Object.keys(order).length > 0 ? (
        <div>
          {message && (
            <div className="mb-4">
              <div className={`alert alert-${messageType}`} role="alert">
                {message}
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="row mb-2">
              <div className="col-3">Email</div>
              <div className="col-9">{order.email}</div>
            </div>
            <div className="row mb-2">
              <div className="col-3">Price</div>
              <div className="col-9">{format.formatCurrency(order.price)}</div>
            </div>
            <div className="row mb-2">
              <div className="col-3">Payment Status</div>
              <div className="col-9">
                {format.formatPaymentStatus(order.paymentStatus)}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-3">Order Status</div>
              <div className="col-9">
                <select {...register("orderStatus")}>
                  <option value="SUCCESS">Success</option>
                  <option value="PENDING">Pending</option>
                  <option value="FALSE">False</option>
                  <option value="DELETED">Deleted</option>
                </select>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-3">Created At</div>
              <div className="col-9">
                <Moment format="YYYY-MM-DD HH:mm:ss">{order.created_at}</Moment>
              </div>
            </div>
            {order.updated_at && (
              <div className="row mb-3">
                <div className="col-3">Updated At</div>
                <div className="col-9">
                  <Moment format="YYYY-MM-DD HH:mm:ss">
                    {order.updated_at}
                  </Moment>
                </div>
              </div>
            )}
            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <p>{"Not found"}</p>
      )}
    </AuthLayout>
  );
};

export default OrderEdit;

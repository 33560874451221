import FileItem from "./FileItem";

const ResourceFiles = ({ resourceFiles }) => {
  return (
    <>
      <ul className="list-unstyled">
        {resourceFiles.map((file, i) => {
          return <FileItem file={file} key={i} />;
        })}
      </ul>
    </>
  );
};
export default ResourceFiles;

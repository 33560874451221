import axios from "axios";
//import authHeader from "../../helpers/header";
import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import { useForm } from "react-hook-form";

import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";

import AuthLayout from "../../components/AuthLayout/AuthLayout";
import format from "../../helpers/format";
import queryHelper from "../../helpers/query";
import ResourceAPI from "../../api/resourceAPI";
import { setMessage } from "../../redux/actions/message";

import Filter from "../../components/Filter/Filter";
import Pagination from "../../components/Pagination";

import { API_URL, FE_WEBSITE_URL } from "../../configs";

const filters = [
    {
      type: "text",
      name: "q",
      defaultValue: "",
    },
    {
        type: "select",
        name: "canonical",
        options: [
          { title: "Canonical", value: "" },
          { title: "Yes", value: "yes" },
          { title: "No", value: "no" },
        ],
        defaultValue: "",
    },
    {
        type: "select",
        name: "redirect",
        options: [
          { title: "Redirect", value: "" },
          { title: "Yes", value: "yes" },
          { title: "No", value: "no" },
        ],
        defaultValue: "",
    },
    {
        type: "select",
        name: "meta",
        options: [
          { title: "Meta data", value: "" },
          { title: "Yes", value: "yes" },
          { title: "No", value: "no" },
        ],
        defaultValue: "",
    },
    {
        type: "select",
        name: "status",
        options: [
          { title: "Select status", value: "" },
          { title: "Reject", value: "reject" },
          { title: "Schedule", value: "schedule" },
          { title: "Active", value: "active" },
          { title: "Missing", value: "missing" },
          { title: "Pending", value: "pending" },
          { title: "Draft", value: "draft" },
          { title: "Deleted", value: "deleted" },
        ],
        defaultValue: "",
    },
]

const defaultFilter = filters.reduce(
    (obj, cur) => ({ ...obj, [cur.name]: cur.defaultValue }),
    {}
); 

const initialQuery = {
    ...defaultFilter,
    sortby: "",
    direction: "",
    page: "",
};

const pageSize = 48;

function ReportMeta(props) {

    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [currentPage, setCurrentPage] = useState(1); 
    const [count, setCount] = useState(0);
    const [query, setQuery] = useState(initialQuery);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedId, setSelectedId] = useState([]);

    //offcanvas state
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //message state
    const { message, messageType } = props;

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
      } = useForm();
    
    //handle select resource
    const handleCheckAll = () => {
        if (selectedId.length === 0) {
            data.map((item) => {
                setSelectedId((selectedId) => [...selectedId, item._id]);
            });
        } else {
            setSelectedId([]);
        }
    };

    //handle change selected resource
    const handleIdChange = (e) => {
        // console.log(e.target.value);
        if (selectedId.length === 0) {
          setSelectedId([...selectedId, e.target.value]);
        } else {
          let index = selectedId.indexOf(e.target.value);
          // console.log(index);
          if (index === -1) {
            setSelectedId((selectedId) => [...selectedId, e.target.value]);
          } else {
            selectedId.splice(index, 1);
            setSelectedId([...selectedId]);
          }
        }
    };

    /**
   * bulk update resource meta
   * @param {form} data
   */
    const updateMeta = async (data) => {
        data.resource_ids = selectedId;
        if (selectedId.length > 0) {
            await ResourceAPI.bulkUpdateResourceMeta(data)
              .then((response) => {
                console.log(response)
                if (response.status === 200) {
                  setIsLoading(false);
                  dispatch(
                    setMessage(
                      `${selectedId.length} resources  was updated`,
                      "success"
                    )
                  );
                  fetchSeoData(query);
                }
             // }).catch((e) => {
            }).catch(function (e) {
                if (e.response) {
                    //console.log(e.response.data.detail)
                    dispatch(setMessage(`${e.response.data.detail}`, "danger"));
                }else{
                    dispatch(setMessage(`${e}`, "danger"));
                }
                setIsLoading(false);
            });
        } else {
            dispatch(setMessage("Please select an item", "danger"));
        }
    }

    //handle reset filter
    const handleResetFilter = () => {
        setQuery(initialQuery);
        //setFullQuery({ ...initialQuery, pageSize });
    };
    
    const fetchSeoData = async (query) => {
    //async function fetchSeoData(query) {
        let queryObj = {};
        console.log(queryObj)

        if (query.q) {
            queryObj.q = query.q;
        } else {
            queryObj.q = "";
        }
        
        if (query.page) {
            queryObj.offset = parseInt(query.page) - 1;
        }

        if (query.meta) {
            queryObj.meta = query.meta;
        }

        if (query.canonical) {
            queryObj.canonical = query.canonical;
        }

        if (query.redirect) {
            queryObj.redirect = query.redirect;
        }

        if (query.status) {
            queryObj.status = query.status;
        }

        queryObj.limit = parseInt(pageSize)
        // const response = await apiInstance.get(API_URL + `resources/?${q}`, {
        //     headers: authHeader(),
        // });
        let q = queryHelper.arrayToQueryString(queryObj);
        console.log(API_URL + `reports/seo/urls/?${q}`)

        setIsLoading(true);
        const res = await axios.get(API_URL + `reports/seo/urls/?${q}`)
        //console.log(res.data[0])
        if(res.status === 200){
            console.log(res.data[0].metadata)
            setData(res.data[0].data)
            if(res.data[0].metadata[0]) {
                setCount(res.data[0].metadata[0].totalCount)
            }
            setIsLoading(false);
        }else{
            setIsLoading(false);
        }
    }

    useEffect(() => {
        //console.log(query)
        const newQuery = Object.assign({}, query);

        Object.keys(defaultFilter).map((filter) => {
            if (searchParams.get(filter)) {
                newQuery[filter] = searchParams.get(filter);
            }
            return newQuery
        });

        if (searchParams.get("page")) {
            newQuery.page = searchParams.get("page");
            setCurrentPage(searchParams.get("page"));
        } else {
            //fix initial page without page params
            newQuery.page = 1;
            setCurrentPage(1);
        }
        
        setQuery(newQuery);
        console.log(newQuery)

        fetchSeoData(newQuery)
        //console.log(data)
    }, [location])

    // useEffect(() => {
    //     fetchSeoData(query)
    // }, [])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Urls"}</h2>
                <div className="d-flex align-self-end">
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                        <i className="bi bi-check"></i>
                        <span className="ms-2">Action</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item onClick={handleShow}>Update</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            console.log('test')
                        }}>Export data</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Offcanvas
                    className="shadow"
                    show={show}
                    onHide={handleClose}
                    backdrop={false}
                    scroll={true}
                    placement={"end"}
                >
                    <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="px-4">Update meta data</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className={"p-4"} onSubmit={handleSubmit(updateMeta)}>
                            <div className="mb-3">
                            <label htmlFor="tags" className="form-label">
                                Meta keywords
                            </label>
                            <input
                                className="form-control"
                                {...register("meta_keywords")}
                            />
                            </div>
                            <div className="mb-3">
                            <label htmlFor="tags" className="form-label">
                                Canonical Url
                            </label>
                            <input
                                className="form-control"
                                {...register("meta_canonicalURL")}
                            />
                            </div>
                            <div className="mb-3">
                            <label htmlFor="tags" className="form-label">
                                Redirect Url
                            </label>
                            <input
                                className="form-control"
                                {...register("meta_redirectURL")}
                            />
                            </div>
                            <div className="d-grid gap-2">
                            {/* <input
                                value={selectedId}
                                type={"hidden"}
                                {...register("resource_ids")}
                            /> */}
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isLoading}
                            >
                                Update
                            </button>
                        </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </header>
            {message && (
                <div className="mb-4">
                <div className={`alert alert-${messageType}`} role="alert">
                    {message}
                </div>
                </div>
            )}
            <div className="mb-3">
             <Filter
                defaultFilter={defaultFilter}
                filters={filters}
                reset={handleResetFilter}
            />
           
            { isLoading ? (
                <p className="text-center p-5">Loading</p>
            ) : (
                <>
                {data && data.length > 0 ? (
                    <>
                     <Table className="table-hover table-striped mb-4" responsive>
                <thead>
                  <tr>
                    <th scope="col" className="fw-normal text-muted">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckAll();
                        }}
                      >
                        {"#"}
                      </a>
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                        {'Slug'}
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                        {'Canonical'}
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                        {'Redirect'}
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                        {'Meta title'}
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                        {'Keyword'}
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                        {'Status'}
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                        {'Action'}
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((item, index) => {
                        return(
                            <tr key={index} className="align-middle">
                                <td>
                                    <input
                                        type="checkbox"
                                        value={item._id}
                                        className="form-check-input"
                                        {...register(`tmp_id.${index}`)}
                                        //name={resource._id}
                                        onChange={(e) => handleIdChange(e)}
                                        //checked={selectedId.includes(resource._id) ? 'checked' : ''}
                                        checked={
                                            selectedId.indexOf(item._id.trim()) > -1
                                                ? true
                                                : false
                                        }
                                    />
                                </td>
                                <td className="small">
                                    <Link to={`/resources/view/${item._id}`} target="_blank" rel="noreferrer">
                                        <strong className="rname">{item.slug}</strong>
                                    </Link>
                                    {!item.canonicalURL && 
                                        <>
                                        <span className="badge bg-primary bg-opacity-10 text-primary rounded-pill ms-2">
                                            {"P"}
                                        </span>
                                        </>
                                    }
                                </td>
                                <td className="small">{item.canonicalURL}</td>
                                <td className="small">{item.redirectURL}</td>
                                <td className="small">{item.metaTitle}</td>
                                <td className="small">{item.metaKeywords}</td>
                                <td>{format.formatResourceStatus(item.status)}</td>
                                <td>
                                <div className="d-flex align-items-center gap-2">
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${FE_WEBSITE_URL}vector/${item.slug}`}
                                        className="p-1 btn text-muted d-inline-flex"
                                    >
                                        <i className="bi bi-link"></i>
                                    </a>
                                    <Link
                                        to={`/resources/edit/${item._id}`}
                                        className="btn p-1"
                                        target="_blank"
                                        >
                                        <i className="bi bi-pencil d-flex text-muted"></i>
                                    </Link>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
                </Table>
                </>
                ):( 
                    <div className="text-center p-4 my-4">{'No result'}</div>
                )}
                </>
                )} {/* loading condition */}
                <div className="my-4">
                    <Pagination
                        count={count}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        path={"resources"}
                        moduleName={"resource"}
                        query={query}
                    />
                </div>
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ReportMeta);
      
import apiInstance from "./api";
import apiStorage from "./storage";
import { logout, refreshToken } from "../redux/actions/auth";
import axios from "axios";
import { API_URL, AUTH_API_URL } from "../configs";

const setup = (store) => {
  const { dispatch } = store;

  apiInstance.interceptors.request.use(
    (config) => {
      const token = apiStorage.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for fastapi back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      //if (originalConfig.url !== "/auth/signin" && err.response) {
      if (
        originalConfig.url !== "/auth/login" &&
        originalConfig.url !== "/auth/refresh" &&
        err.response
      ) {
        // Access Token was expired
        if (err.response.status === 422 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            //DO NOT USE interceptors => USE axios instead
            const rs = await axios.post(
              AUTH_API_URL + "auth/refresh",
              {},
              {
                headers: {
                  Authorization: "Bearer " + apiStorage.getLocalRefreshToken(),
                },
              }
            );

            const { access_token } = rs.data;
            apiStorage.updateLocalAccessToken(access_token);
            return apiInstance(originalConfig);
          } catch (_error) {
            //force logout
            dispatch(logout());
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;

import { useEffect, useState } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Moment from "react-moment";
// import axios from "axios";
// import FileFilter from "../../components/File/FileFilter";
import FileAPI from "../../api/fileAPI";
import format from "../../helpers/format";
// import { MEDIA_URL } from "../../configs";
import Pagination from "../../components/Pagination";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
// import FileSorter from "../../components/File/FileSorter";
import { Modal, Table } from "react-bootstrap";
// import Filter from "../../components/Filter/Filter";
import Sorter from "../../components/Sorter/Sorter";
import { SCAN_ALL_KEY } from "../../configs";

const filters = [
  {
    type: "text",
    name: "q",
    defaultValue: "",
  },
  // {
  //   type: "select",
  //   name: "format",
  //   options: [
  //     { title: "Select format", value: "" },
  //     { title: "AI", value: "ai" },
  //     { title: "EPS", value: "eps" },
  //     { title: "SVG", value: "svg" },
  //   ],
  //   defaultValue: "",
  // },
  {
    type: "select",
    name: "file_size",
    options: [
      { title: "Select size", value: "" },
      { title: "Null", value: "0" },
      { title: "<= 30MB", value: "0-31457280" },
      { title: "> 30MB", value: "31457280" },
    ],
    defaultValue: "",
  },
  {
    type: "select",
    name: "storage",
    options: [
      { title: "Select storage", value: "" },
      { title: "AWS S3", value: "aws" },
      { title: "Local", value: "local" },
    ],
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "create_from",
    placeholder: "Create From",
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "create_to",
    placeholder: "Create To",
    defaultValue: "",
  },
  {
    type: "select",
    name: "status",
    options: [
      { title: "Select status", value: "" },
      { title: "Active", value: "active" },
      { title: "Deleted", value: "deleted" },
    ],
    defaultValue: "",
  },
  {
    type: "select",
    name: "metadata",
    options: [
      { title: "Have metadata?", value: "" },
      { title: "Yes", value: "yes" },
      { title: "No", value: "no" },
    ],
    defaultValue: "",
  },
];

const defaultFilter = filters.reduce(
  (obj, cur) => ({ ...obj, [cur.name]: cur.defaultValue }),
  {}
);

const initialQuery = {
  ...defaultFilter,
  sortby: "",
  direction: "",
  page: "",
};

const pageSize = 50;

function ScanAiFiles() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [files, setFiles] = useState([]);
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1); //helper.useQueryString("page");
  const [query, setQuery] = useState(initialQuery);
  const [show, setShow] = useState(false);
  const [scanKey, setScanKey] = useState("");
  const [fullQuery, setFullQuery] = useState({
    ...initialQuery,
    pageSize,
  });

  const fetchFiles = async (query = "") => {
    setLoading(true); //@TODO
    //get files data via API
    await FileAPI.getAiFiles(query)
      .then((response) => {
        setCount(response.size);
        setFiles(response.items);
        setLoading(false); //@TODO
        //console.log(response.items)
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  useEffect(async () => {
    const newQuery = Object.assign({}, query);

    Object.keys(defaultFilter).map((filter) => {
      if (searchParams.get(filter)) {
        newQuery[filter] = searchParams.get(filter);
      }
    });

    if (searchParams.get("sortby")) {
      newQuery.sortby = searchParams.get("sortby");
    }
    if (searchParams.get("direction")) {
      newQuery.direction = searchParams.get("direction");
    }

    if (searchParams.get("page")) {
      newQuery.page = searchParams.get("page");
      setCurrentPage(searchParams.get("page"));
    } else {
      //fix initial page without page params
      newQuery.page = 1;
      setCurrentPage(1);
    }
    setQuery(newQuery);

    const fullQuery = Object.assign({}, newQuery);
    fullQuery.pageSize = pageSize;
    setFullQuery(fullQuery);

    await fetchFiles(fullQuery);
  }, [location]);

  const reset = () => {
    setQuery(initialQuery);
    setFullQuery({ ...initialQuery, pageSize });
  };

  const scanFile = async (id) => {
    setScanning(true);
    await FileAPI.scanAiFile(id);
    setScanning(false);
  };

  const scanAllFiles = async () => {
    if (scanKey === SCAN_ALL_KEY) {
      setScanning(true);
      await FileAPI.scanAllAiFiles();
      setScanning(false);
    }
  };

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h2>{"Scan AI files"}</h2>
      </header>

      {/* <div className="mb-3">
        <Filter defaultFilter={defaultFilter} filters={filters} reset={reset} />
      </div> */}
      <div className="mb-3 text-center">
        <button type="button" onClick={() => setShow(true)}>
          Scan All
        </button>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Body>
            <form action="">
              <label htmlFor="scan-key" className="mb-2 fw-bold">
                Key for scan all
              </label>
              <input
                type="password"
                name="scan-key"
                id="scan-key"
                className="form-control"
                onChange={(e) => setScanKey(e.target.value)}
              />
              <div className="mt-3 text-center">
                <button
                  type="button"
                  onClick={() => scanAllFiles()}
                  disabled={scanning}
                  className="btn btn-secondary px-4"
                >
                  {scanning ? "Scanning" : "Scan All"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
      {loading ? (
        <p className="text-center p-5">Loading</p>
      ) : (
        <>
          {files.length > 0 ? (
            <>
              <Table responsive className="table table-striped mb-4">
                <thead>
                  <tr>
                    <th scope="col" className="fw-normal text-muted">
                      Format
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="File name"
                        name="filename"
                      />
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Path
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Resource
                    </th>

                    <th scope="col" className="fw-normal text-muted">
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="Size"
                        name="file_size"
                      />
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      <div style={{ minWidth: "95px" }}>
                        <Sorter
                          defaultFilter={defaultFilter}
                          query={query}
                          title="Created at"
                          name="created_at"
                        />
                      </div>
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Status
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Metadata
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="border-top-0">
                  {files.map((file, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {file.format === "ai" && (
                            <span className="format format--ai border p-3">
                              {file.format.toUpperCase()}
                            </span>
                          )}
                          {file.format === "svg" && (
                            <span className="format format--svg border p-3">
                              {file.format.toUpperCase()}
                            </span>
                          )}
                          {file.format === "eps" && (
                            <span className="format format--eps border p-3">
                              {file.format.toUpperCase()}
                            </span>
                          )}
                        </td>
                        <td>
                          {file.filename}
                          <br />
                          <small className="text-muted">{file._id}</small>
                        </td>
                        <td>
                          {file.aws_path && (
                            <span className="badge bg-warning">{"S3"}</span>
                          )}
                          {file.path && (
                            <span className="badge bg-dark">{"Local"}</span>
                          )}
                        </td>
                        <td>
                          <div className="align-items-center">
                            <small className="text-muted me-2">
                              {file.resourceId}
                            </small>
                            {format.formatResourceStatusNoText(
                              file.resourceStatus
                            )}
                          </div>
                          <Link to={`/resources/view/${file.resourceId}`}>
                            {file.resourceName}
                          </Link>
                        </td>

                        <td className="text-end align-middle">
                          {file.file_size > 0 ? (
                            <>
                              <span className="small fw-semibold">
                                {format.formatBytes(file.file_size)}
                              </span>
                            </>
                          ) : (
                            <>
                              <div
                                className="bg-danger d-inline-flex rounded-circle ml-auto"
                                style={{ width: "14px", height: "14px" }}
                              ></div>
                            </>
                          )}
                        </td>
                        <td className="text-muted align-middle">
                          <Moment format="YYYY/MM/DD HH:mm:ss">
                            {file.created_at}
                          </Moment>
                          {/* {new Date(file.created_at).toDateString()} */}
                        </td>
                        <td className="align-middle">
                          {format.formatStatus(file.status)}
                        </td>
                        <td className="align-middle">
                          <div className="button-group">
                            {file.metaData && file.metaData.documentTitle
                              ? "Yes"
                              : "No"}
                          </div>
                        </td>
                        <td className="align-middle">
                          <div className="button-group">
                            <button
                              type="button"
                              onClick={() => scanFile(file._id)}
                              disabled={scanning}
                            >
                              Scan
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination
                count={count}
                currentPage={currentPage}
                pageSize={pageSize}
                path={"files"}
                moduleName={"files"}
                query={query}
              />
            </>
          ) : (
            <p>{"Not found"}</p>
          )}
        </>
      )}
    </AuthLayout>
  );
}

export default ScanAiFiles;

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const GET_USER = "GET_USER";

export const FETCH_RESOURCES_PENDING = "FETCH_RESOURCES_PENDING";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_RESOURCES_ERROR = "FETCH_RESOURCES_ERROR";

export const RESOURCE_CREATED_SUCCESS = "RESOURCE_CREATED_SUCCESS";
export const RESOURCE_CREATED_FAIL = "RESOURCE_CREATED_FAIL";

export const LOAD_DATA = "LOAD_DATA";
export const APPLY_TAG_FILTER = "APPLY_TAG_FILTER";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const FETCH_USERS_PENDING = "FETCH_USERS_PENDING";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

export const UPDATE_USERS_PENDING = "UPDATE_USERS_PENDING";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_FAILED = "UPDATE_USERS_FAILED";

export const FETCH_USER_DETAIL_PENDING = "FETCH_USER_DETAIL_PENDING";
export const FETCH_USERS_DETAIL_SUCCESS = "FETCH_USERS_DETAIL_SUCCESS";
export const FETCH_USERS_DETAIL_FAILED = "FETCH_USERS_DETAIL_FAILED";

export const FETCH_FILES_PENDING = "FETCH_FILES_PENDING";
export const FETCH_FILES_SUCCESS = "FETCH_FILES_SUCCESS";
export const FETCH_FILES_FAILED = "FETCH_FILES_FAILED";

export const UPDATE_FILES_PENDING = "UPDATE_FILES_PENDING";
export const UPDATE_FILES_SUCCESS = "UPDATE_FILES_SUCCESS";
export const UPDATE_FILES_FAILED = "UPDATE_FILES_FAILED";

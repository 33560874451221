import { GET_USER, SET_MESSAGE } from "./types";

import UserAPI from "../../api/userAPI";
import { logout } from "../actions/auth";
//import AuthAPI from "../api/authAPI";

export const getLoggedInUser = () => (dispatch) => {
  return UserAPI.getRemoteUser().then(
    (data) => {
      // if(data.roles.indexOf('ADMIN') == -1 || data.roles.indexOf('STAFF') == -1) {
      //   dispatch({
      //     type: SET_MESSAGE,
      //     payload: 'You do not have permission',
      //   });
      //   return Promise.reject();
      // }
      //if()
      dispatch({
        type: GET_USER,
        payload: data,
      });
      return Promise.resolve();
    },
    (error) => {
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     error.response.data.message) ||
      //   error.message ||
      //   error.detail ||
      //   error.toString();
      // console.log(message)
      //console.log(message)
      //@TODO : should check this
      //AuthAPI.logout();
      dispatch(logout());

      dispatch({
        type: SET_MESSAGE,
        payload: error.response.data.detail,
      });
      //return Promise.reject();
    }
  );
};

// export const getLoggedInUser = () => (dispatch) => {
//   const data = UserAPI.getCurrentUser();
//   //console.log(data)
//   dispatch({
//     type: GET_USER,
//     payload: data,
//   });
//   return Promise.resolve();
// };

import axios from "axios";
//import authHeader from "../../helpers/header";

import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { Table } from "react-bootstrap";
import { API_URL } from "../../configs";

function ReportFile(props) {
    // const dispatch = useDispatch();
    // const location = useLocation();
    // const [searchParams, setSearchParams] = useSearchParams();
    // const [data, setData] = useState([]);

    // useEffect(() => {

    //     async function fetchFilesData() {
    //         // Get exclude format query parameter
    //         const excl = searchParams.get('excl');
    //         const limit = searchParams.get('limit') || 50;  // Default limit is 10
    //         const offset = searchParams.get('offset') || 0;  // Default offset is 0
      
    //         // Build the API URL
    //         // let apiUrl = `${API_URL}reports/files_format`;
    //         let apiUrl = `${API_URL}reports/files_format?limit=${limit}&offset=${offset}`;

    //         // Split the excl parameter into an array of formats
    //         const excludedFormats = excl ? excl.split(',') : [];
    //         if (excludedFormats.length) {
    //             //apiUrl += `&excl=${excl}`;  // Add range to the URL if it exists
    //             apiUrl += `&excl=${excludedFormats.join(',')}`;
    //         }

    //         try {
    //           const res = await axios.get(apiUrl);  // Fetch data from the API
    //           if (res.status === 200) {
    //             //setData(res.data);  // Set data in state if the request is successful
    //             setData(res.data[0].data)
    //           }
    //         } catch (error) {
    //           console.error("Error fetching search logs:", error);
    //         }
    //     }
    //     fetchFilesData()
    // }, [searchParams])

    const [searchParams] = useSearchParams(); // Not destructuring setSearchParams as it's unused
  const [data, setData] = useState([]);
  const [error, setError] = useState(null); // Optional: To handle errors
  const [page, setPage] = useState(1); // Track the current page
  const [totalCount, setTotalCount] = useState(0); // Track total items
  const limit = 50; // Define limit (items per page)
  const totalPages = Math.ceil(totalCount / limit);

  // Calculate offset based on current page
  const offset = (page - 1);

  useEffect(() => {
    async function fetchFilesData() {
      try {
        const excl = searchParams.get('excl') || '';
        const response = await axios.get(`${API_URL}reports/files_format`, {
          params: {
            excl: excl,
            limit: limit,
            offset: offset,
          }
        });

        if (response.status === 200) {
          const { data, metadata } = response.data[0];
          setData(data);
          setTotalCount(metadata.length > 0 ? metadata[0].totalCount : 0);
        } else {
          setData([]);
          setTotalCount(0);
        }
      } catch (err) {
        console.error("Error fetching files data:", err);
        setError("Failed to fetch data. Please try again.");
      }
    }

    fetchFilesData();
  }, [searchParams, page]); // Trigger re-fetch when page or searchParams change

  // Handle page navigation
  const handleNextPage = () => {
    if (offset + limit < totalCount) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  }

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Files reports"}</h2>
                <div className="mb-3">
                    <Link 
                        to={`/reports`} 
                        target="_blank" 
                        rel="noreferrer"
                        className="btn btn-secondary"
                    >
                        Report home
                    </Link>
                </div>
            </header>
            <div>
                <small className="text-muted">/file?excl=svg,eps</small>
                {data &&
                <>
                 <Table className="table-hover table-striped mb-4" responsive>
                <thead>
                  <tr>
                    <th scope="col" className="fw-normal text-muted">{'Resources'}</th>
                    <th scope="col" className="fw-normal text-muted">{'AI'}</th>
                    <th scope="col" className="fw-normal text-muted">{'EPS'}</th>
                    <th scope="col" className="fw-normal text-muted">{'SVG'}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {
                data.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="align-middle">
                                <Link to={`/resources/view/${item._id}`} target="_blank" rel="noreferrer">
                                 {item._id}
                                </Link>
                            </td>
                            <td className="align-middle">{item.ai}</td>
                            <td className="align-middle">{item.eps}</td>
                            <td className="align-middle">{item.svg}</td>
                            <td>
                                <Link to={`/resources/convert-file/?resources_id=${item._id}`} 
                                className="btn btn-sm btn-light"
                                target="_blank" 
                                rel="noreferrer">
                                <i className="bi bi-arrow-left-right"></i>
                                </Link>
                            </td>
                        </tr>
                    )
                })
                }
                </tbody>
                </Table>
                </>
                }
                <div className="pagination">
                    <button className="btn btn-light btn-sm me-2" onClick={handlePreviousPage} disabled={page === 1}>
                    Previous
                    </button>
                    <span className="py-3 me-2">Page {page} of {totalPages}</span>
                    <button className="btn btn-light btn-sm me-2" onClick={handleNextPage} disabled={offset + limit >= totalCount}>
                    Next
                    </button>
                    <span className="text-muted py-3 me-2">Total : {totalCount}</span>
                    <span className="text-muted py-3 me-2">No of Pages : {totalPages}</span>
                    
                </div>
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ReportFile);
      
import axios from "axios";
//const AUTH_API_URL = "http://localhost:8000/auth/";
import { AUTH_API_URL } from "../configs";

// send register request
const register = (email, password) => {
  return axios.post(AUTH_API_URL + "auth/register", {
    email,
    password,
  });
};

// Send logging in request
const login = async (email, password) => {
  const response = await axios.post(AUTH_API_URL + "auth/login", {
    email,
    password,
  });
  
  if (response.data.access_token) {
    localStorage.setItem("token", JSON.stringify(response.data));
  }
  return response.data;
};

// Send request to API to get new token
const refresh = async (refresh_token) => {
  const response = await axios.post(AUTH_API_URL + "auth/refresh", {}, {
    headers: { Authorization: 'Bearer ' + refresh_token }
  });
  
  if (response.data.access_token) {
    const newToken = Object.assign({}, JSON.parse(localStorage.getItem("token")));
    newToken.access_token = response.data.access_token;
    localStorage.setItem("token", JSON.stringify(newToken));
  }
  return response.data;
};

// logout user
const logout = () => {
  //console.log('logout')
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

const exportedObject = {
  register,
  login,
  logout,
  refresh
};

export default exportedObject


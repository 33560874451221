import { useState } from 'react';
import axios from 'axios';
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { API_URL } from "../../configs";

const ReplaceTags = () => {
  const [oldTag, setOldTag] = useState('');
  const [newTag, setNewTag] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const handleReplaceTags = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await axios.put(`${API_URL}resources/tags/replace`, {
        old_tag: oldTag,
        new_tag: newTag,
      });

      // Update the message with a success response
      setMessage(response.data.message);
      setError(null); // Clear any previous errors

      // Optionally redirect or reset the form
      setOldTag('');
      setNewTag('');
    } catch (err) {
      // Handle errors and set error message
      if (err.response) {
        // Server error response
        setError(err.response.data.detail);
      } else {
        // Network error or other issue
        setError("An error occurred while trying to replace tags.");
      }
      setMessage(''); // Clear any previous messages
    }
  };

  return (
    <AuthLayout>
    <header className={"d-flex justify-content-between align-items-center mb-4"}>
        <h2>{"Replace Tags"}</h2>
    </header>
    <div>
      <form onSubmit={handleReplaceTags}>
        <div>
          <label>
            Old Tag:
            <input
              type="text"
              value={oldTag}
              onChange={(e) => setOldTag(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            New Tag:
            <input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              required
            />
          </label>
        </div>
        <button type="submit">Replace Tag</button>
      </form>

      {/* Show messages */}
      {message && <div style={{ color: 'green' }}>{message}</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
    </AuthLayout>
  );
};

export default ReplaceTags;

import parse from "html-react-parser";

const formatResourceOrientation = (orientation, text) => {
  let statusHtml = "";
  if (orientation === "vertical") {
    statusHtml = `<i class="bi bi-vr small mx-1"></i>`;
  }
  if (orientation === "horizontal") {
    statusHtml = `<i class="bi bi-hr small mx-1"></i>`;
  }
  if (orientation === "square") {
    statusHtml = `<i class="bi bi-square small mx-1"></i>`;
  }
  if(text) {
    statusHtml += orientation
  }
  return parse(statusHtml);
};

const formatResourceStatus = (status) => {
  let statusHtml = "";
  if (status === "active") {
    statusHtml = `<small className="badge rounded-pill bg-success bg-opacity-10 fw-normal text-success">${status}</small>`;
  }
  if (status === "draft") {
    statusHtml = `<small className="badge rounded-pill bg-secondary bg-opacity-10 fw-normal text-secondary">${status}</small>`;
  }
  if (status === "pending") {
    statusHtml = `<small className="badge rounded-pill bg-info bg-opacity-10 fw-normal text-info">${status}</small>`;
  }
  if (status === "schedule") {
    statusHtml = `<small className="badge rounded-pill bg-primary bg-opacity-10 text-primary fw-normal">${status}</small>`;
  }
  if (status === "missing") {
    statusHtml = `<small className="badge rounded-pill bg-dark bg-opacity-10 text-dark fw-normal">${status}</small>`;
  }
  if (status === "reject") {
    statusHtml = `<small className="badge rounded-pill bg-warning bg-opacity-10 text-warning fw-normal">${status}</small>`;
  }
  if (status === "deleted") {
    statusHtml = `<small className="badge rounded-pill bg-danger bg-opacity-10 text-danger fw-normal">${status}</small>`;
  }
  return parse(statusHtml);
};

const formatResourceStatusNoText = (status) => {
  let statusHtml = "";
  if (status === "active") {
    statusHtml = `<small className="badge rounded-pill bg-success bg-opacity-10 text-success text-uppercase">${status.charAt(
      0
    )}</small>`;
  }
  if (status === "draft") {
    statusHtml = `<small className="badge rounded-pill bg-secondary bg-opacity-10 text-secondary text-uppercase">${status.charAt(
      0
    )}</small>`;
  }
  if (status === "pending") {
    statusHtml = `<small className="badge rounded-pill bg-info bg-opacity-10 text-info text-uppercase">${status.charAt(
      0
    )}</small>`;
  }
  if (status === "reject") {
    statusHtml = `<small className="badge rounded-pill bg-warning bg-opacity-10 text-warning text-uppercase">${status.charAt(
      0
    )}</small>`;
  }
  if (status === "schedule") {
    statusHtml = `<small className="badge rounded-pill bg-primary bg-opacity-10 text-primary text-uppercase">${status.charAt(
      0
    )}</small>`;
  }
  if (status === "missing") {
    statusHtml = `<small className="badge rounded-pill bg-dark bg-opacity-10 text-dark text-uppercase">${status.charAt(
      0
    )}</small>`;
  }
  if (status === "deleted") {
    statusHtml = `<small className="badge rounded-pill bg-danger bg-opacity-10 text-danger text-uppercase">${status.charAt(
      0
    )}</small>`;
  }
  return parse(statusHtml);
};

const formatFileEx = (extension, size) => {
  let formatHtml = `<span className="format format--${extension.toLowerCase()} p-3">`;
  if (extension.toLowerCase() === 'svg') {
    formatHtml += `<i class="bi bi-filetype-svg ${size}"></i>`;
  }
  if (extension.toLowerCase() === 'ai') {
    formatHtml += `<i class="bi bi-filetype-ai ${size}"></i>`;
  }
  if (extension.toLowerCase() === 'eps') {
    formatHtml += `<i class="bi bi-files-alt ${size}"></i>`;
  }
  formatHtml += `</span>`
  return parse(formatHtml);
}

const formatUserStatus = (disabled) => {
  let statusHtml = "";
  if (disabled === true) {
    statusHtml = `<small className="badge bg-danger bg-opacity-10 text-danger fw-normal">${`Disabled`}</small>`;
  } else {
    statusHtml = `<small className="badge bg-success bg-opacity-10 text-success fw-normal">${`Enable`}</small>`;
  }
  return parse(statusHtml);
};

const formatStatus = (status) => {
  let statusHtml = "";
  if (status === "active") {
    statusHtml = `<small className="badge bg-opacity-10 text-success bg-success fw-normal">${status}</small>`;
  }
  if (status === "draft") {
    statusHtml = `<small className="badge bg-opacity-10 text-secondary bg-secondary fw-normal">${status}</small>`;
  }
  if (status === "pending") {
    statusHtml = `<small className="badge bg-opacity-10 text-warning bg-warning fw-normal">${status}</small>`;
  }
  if (status === "deleted") {
    statusHtml = `<small className="badge bg-opacity-10 text-danger bg-danger fw-normal">${status}</small>`;
  }
  return parse(statusHtml);
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 MB";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const formatPaymentStatus = (status) => {
  let statusHtml = "";
  if (status === "complete") {
    statusHtml = `<small className="badge bg-success">${status}</small>`;
  }
  if (status === "cancel") {
    statusHtml = `<small className="badge bg-secondary">${status}</small>`;
  }
  if (status === "pending") {
    statusHtml = `<small className="badge bg-warning">${status}</small>`;
  }

  return parse(statusHtml);
};

const formatOrderStatus = (status) => {
  let statusHtml = "";
  if (status === "SUCCESS") {
    statusHtml = `<small className="badge bg-success">${status}</small>`;
  }
  if (status === "CANCEL") {
    statusHtml = `<small className="badge bg-secondary">${status}</small>`;
  }
  if (status === "PENDING") {
    statusHtml = `<small className="badge bg-warning">${status}</small>`;
  }
  if (status === "FALSE") {
    statusHtml = `<small className="badge bg-danger">${status}</small>`;
  }

  return parse(statusHtml);
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export default {
  formatStatus,
  formatFileEx,
  formatUserStatus,
  formatResourceStatus,
  formatResourceStatusNoText,
  formatBytes,
  formatCurrency,
  formatPaymentStatus,
  formatOrderStatus,
  formatResourceOrientation
};

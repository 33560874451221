import axios from "axios";
import { API_URL } from "../configs";

const apiInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiInstance;
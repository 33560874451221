import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import { login } from "../../redux/actions/auth";

function Login({ token, message, messageType }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = (data) => {
    setLoading(true);
    dispatch(login(data.email, data.password))
      .then(() => {
        setLoading(false);
        navigate("/", { replace: true });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (token) {
      return <Navigate to="/" />;
    }
  }, [token]);

  return (
    <>
      {token ? (
        <>
          <Navigate to="/" />
        </>
      ) : (
        <>
          <div
            className="mx-auto d-flex justify-content-center align-items-center"
            style={{ height: "90vh" }}
          >
            <div className="login-box px-5">
              <div className="text-center mb-5">
                <img
                  src="/magipik_m_logo_black.svg"
                  alt="Magipik Backend"
                  style={{ width: 64 }}
                  className="mx-auto"
                />
              </div>

              <form onSubmit={handleSubmit(handleLogin)}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label text-muted">
                    E-mail
                  </label>
                  <input
                    className="form-control py-2"
                    placeholder={"Enter your email"}
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="small text-danger p-1">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="password" className="form-label text-muted">
                    Password
                  </label>
                  <input
                    className="form-control py-2"
                    type="password"
                    placeholder={"Enter your password"}
                    {...register("password", { required: true })}
                  />
                  {errors.password && (
                    <span className="small text-danger">
                      This field is required
                    </span>
                  )}
                </div>
                {message && (
                  <div className="mb-4">
                    <div className={`alert alert-${messageType}`} role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 py-2 mb-4 shadow-sm"
                    disabled={loading}
                  >
                    Login
                  </button>
                  {/* <Link to={"/register"} className="btn btn-link ml-4 px-5">
                    {"Register"}
                  </Link> */}
                  <Link
                    to={"/forgotpassword"}
                    className="btn btn-link ml-4 px-5"
                  >
                    {"Forgot password?"}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
  //}
}

function mapStateToProps(state) {
  const { token } = state.auth;
  const { message, messageType } = state.message;
  return {
    token,
    message,
    messageType,
  };
}

export default connect(mapStateToProps)(Login);

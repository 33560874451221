import { useRef, useState } from "react";
import Moment from "react-moment";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import format from "../../helpers/format";
import jsPDF from "jspdf";
import styles from "./InvoiceDetail.module.css";

const invoice = {
  code: "456ABC24",
  datePaid: new Date(),
  name: "Test",
  item: "Vip Pro",
  qty: 1,
  price: 15,
};

const InvoiceDetail = () => {
  const [loading, setLoading] = useState(false);
  const invoiceRef = useRef(null);

  const handleGenPdf = () => {
    const doc = new jsPDF("p", "pt", "a4");

    doc.html(invoiceRef.current, {
      async callback(doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.deletePage(pageCount);
        await doc.save(`in-${invoice.code}`);
      },
      margin: [35, 0, 35, 0],
      html2canvas: { width: 595, height: 842 },
    });
  };

  console.log("ref", invoiceRef);
  return (
    <AuthLayout>
      <div className={`d-inline-block shadow ${styles.wrapper}`}>
        <div ref={invoiceRef} className={styles.pdf}>
          <header
            className={"d-flex justify-content-between align-items-center mb-4"}
          >
            <h1>Invoice</h1>
          </header>
          {loading ? (
            <p className="text-center">Loading...</p>
          ) : invoice && Object.keys(invoice).length > 0 ? (
            <div>
              <div className={styles.header}>
                <div className="d-flex mb-2">
                  <div className={styles.headerTitle}>Invoice Number</div>
                  <div className={styles.headerContent}>{invoice.code}</div>
                </div>
                <div className="d-flex mb-2">
                  <div className={styles.headerTitle}>Date</div>
                  <div className={styles.headerContent}>
                    <Moment format="YYYY-MM-DD">{invoice.created_at}</Moment>
                  </div>
                </div>
              </div>

              <div className="d-flex mb-4">
                <div className="col-6 fw-bold">{invoice.name}</div>
                <div className="col-6">
                  <div className="fw-bold mb-2">Bill to</div>
                  <div>2 Nguyen Hoang, My Dinh, Ha Noi</div>
                </div>
              </div>
              <div>
                <div className="d-flex border-bottom border-dark py-2">
                  <div className="col-6">Description</div>
                  <div className="col-2 text-end">Qty</div>
                  <div className="col-2 text-end">Unit Price</div>
                  <div className="col-2 text-end">Amount</div>
                </div>
                <div className="d-flex py-2">
                  <div className="col-6">Sieu cap vip pro</div>
                  <div className="col-2 text-end">2</div>
                  <div className="col-2 text-end">
                    {format.formatCurrency(15)}
                  </div>
                  <div className="col-2 text-end">
                    {format.formatCurrency(15 * 2)}
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <div className="col-6">
                    <div className="border-top py-2 d-flex">
                      <div className="col-6">Subtotal</div>
                      <div className="col-6 text-end">
                        {format.formatCurrency(15 * 2)}
                      </div>
                    </div>
                    <div className="border-top py-2 d-flex">
                      <div className="col-6 fw-bold">Total</div>
                      <div className="col-6 text-end fw-bold">
                        {format.formatCurrency(15 * 2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>{"Not found"}</p>
          )}
        </div>
      </div>

      <div>
        <button onClick={handleGenPdf} className="btn btn-primary">
          Download
        </button>
      </div>
    </AuthLayout>
  );
};

export default InvoiceDetail;

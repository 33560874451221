import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import UserAPI from "../../api/userAPI";
import { setMessage } from "../../redux/actions/message";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

function UserEdit({ message, messageType }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);

  const onSubmit = async (data) => {
    // console.log(data);
    setIsLoading(true);

    data.disabled = data.status === "1" ? false : true;

    //fix role array while value is string only
    if (data.roles !== "") {
      data.roles = [data.roles];
    }

    await UserAPI.updateUser(id, data)
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        dispatch(setMessage("Save user data successful", "success"));
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
  };
  // fetch user data via API
  const fetchUserData = async (id) => {
    setIsLoading(true);
    await UserAPI.getUser(id)
      .then((response) => {
        setUserData(response);
        setIsLoading(false);
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
  };

  // call data fetching on first load
  useEffect(() => {
    fetchUserData(id);
  }, []);

  // process data with second useEffect
  useEffect(() => {
    if (userData.email) {
      setValue("email", userData.email);
    }
    if (userData.first_name) {
      setValue("first_name", userData.first_name);
    }
    if (userData.last_name) {
      setValue("last_name", userData.last_name);
    }
    if (userData.disabled) {
      setValue("status", 0);
    }
    if (userData.roles && userData.roles[0]) {
      setValue("roles", userData.roles[0]);
    }
    // if (resource.tags) {
    //   setValue('tags', getInputTags(resource.tags))
    // }
    // if (resource.description) {
    //   setValue('description', resource.description)
    // }
    // if (resource.preview && resource.preview[0]) {
    //   setValue('preview', resource.preview[0].filename)
    // }
  }, [userData]);

  return (
    <AuthLayout>
      <header
        className={"d-md-flex d-sm-inline justify-content-between align-items-center mb-4"}
      >
        <h1>{"Edit"}</h1>
        <button className="btn btn-outline-secondary" onClick={(e) => {}}>
          Go Back
        </button>
      </header>
      {message && (
        <div className="mb-4">
          <div className={`alert alert-${messageType}`} role="alert">
            {message}
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="mb-3 col-md-6">
            <label htmlFor="email" className="form-label">
              E-mail
            </label>
            <input
              className="form-control"
              placeholder={"Enter a valid e-mail address"}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="small text-danger">This field is required</span>
            )}
          </div>

          <div className="mb-3 col-md-3">
            <label htmlFor="status" className="form-label">
              Status
            </label>
            <select
              className="form-select"
              defaultValue={"1"}
              {...register("status")}
            >
              <option value="1">Enable</option>
              <option value="0">Disable</option>
            </select>
          </div>
          <div className="mb-3 col-md-3">
            <label htmlFor="roles" className="form-label">
              Roles
            </label>
            <select
              className="form-select"
              defaultValue={"USER"}
              {...register("roles")}
            >
              <option value="ADMIN">Admin</option>
              <option value="STAFF">Staff</option>
              <option value="USER">User</option>
            </select>
          </div>

          <div className="mb-3 col-md-6">
            <label htmlFor="first_name" className="form-label">
              First name
            </label>
            <input
              className="form-control"
              placeholder={"First name"}
              {...register("first_name")}
            />
            {errors.first_name && (
              <span className="small text-danger">This field is required</span>
            )}
          </div>

          <div className="mb-3 col-md-6">
            <label htmlFor="last_name" className="form-label">
              Last name
            </label>
            <input
              className="form-control"
              placeholder={"Last name"}
              {...register("last_name")}
            />
            {errors.last_name && (
              <span className="small text-danger">This field is required</span>
            )}
          </div>
        </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
          Save
        </button>
      </form>
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  const { message, messageType } = state.message;
  return {
    token,
    user,
    message,
    messageType,
  };
}

export default connect(mapStateToProps)(UserEdit);

import axios from "axios";
import authHeader from "../../helpers/header";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { setMessage } from "../../redux/actions/message";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import ResourceAPI from "../../api/resourceAPI";
import { API_URL, MEDIA_URL, OPENAI_KEY } from "../../configs";
import promptsData from '../../data/openai-prompts.json';

const defaultSocialNetworks = ['Facebook', 'Twitter', 'Instagram', 'LinkedIn', 'Tumblr'];

function ResourcesSocial(props) {
    const {
      register,
      handleSubmit,
      setValue,
      getValues,
      watch,
      formState: { errors },
    } = useForm();

    const dispatch = useDispatch();
    const { message, messageType } = props;
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    //const [imageUrl, setImageUrl] = useState(null);
    const [data, setData] = useState([]);
    const [selectedNetworks, setSelectedNetworks] = useState(defaultSocialNetworks);

    const [prompt, setPrompt] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [generating, setGenerating] = useState(false)
    const [selectedId, setSelectedId] = useState([]);
    const [selectedThumbnails, setSelectedThumbnails] = useState([]);
    const [generatedData, setGeneratedData] = useState(null);

    // Handle checkbox change
    const handleCheckboxChange = (network) => {
      if (selectedNetworks.includes(network)) {
          setSelectedNetworks(selectedNetworks.filter((n) => n !== network));
      } else {
          setSelectedNetworks([...selectedNetworks, network]);
      }
    };

    // Generate the JSON output format based on selected networks
    const generateSocialJson = () => {
      const socialJson = { post: {} };
      
      selectedNetworks.forEach((network) => {
          socialJson.post[network] = { content: "" };
      });
      return JSON.stringify(socialJson, null, 2); // Format JSON with indentation
  };

    // Function to handle prompt generation and fetch data
    const handleGenerateData = async () => { 
      // Generate prompt if empty or use the adjusted one
      const promptText = prompt || `Generate a title, description, and tags for the image located at: ${imageUrl}`;
      console.log(`${imageUrl}`)

      let msg_content = [
        { type: 'text', text: promptText }
      ];

      //build prompt and content base selectedThumbnail
      if (selectedThumbnails.length > 0) {
        selectedThumbnails.map((url) => (
          msg_content.push({
            type: "image_url",
            image_url: {
              url: url
            }
          }) 
        ));
      }else{
        dispatch(setMessage(`Please select a resource`, "danger"));
        return;
      }

      let a =JSON.stringify(msg_content)
      console.log(a)
      console.log({
        model: 'gpt-4o-mini',  // Specify the model you're using
        messages: [
          { role: 'system', content: 'You are a social media assistant.' },
          {
            role: 'user',
            content: msg_content
          },
        ],
        response_format: { type: "json_object" },
      })

      try {
        setGenerating(true)
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${OPENAI_KEY}`,  // Replace with your actual API key
          },
          body: JSON.stringify({
            model: 'gpt-4o-mini',  // Specify the model you're using
            messages: [
              { role: 'system', content: 'You are a helpful assistant.' },
              {
                role: 'user',
                content: msg_content
              },
            ],
            response_format: { type: "json_object" },
          }),
        });
    
        // Parse the response
        const completion = await response.json();
        // Extract the content from the message in the response
        const { content } = completion.choices[0].message;
        // Log the content for debugging
        console.log(content.replace(/`/g, '').replace('json', ''));
        // Parse the JSON-formatted content from OpenAI response
        const parsedData = JSON.parse(content.replace(/`/g, '').replace('json', '')); 
        // Set the parsed data in the state
        setGeneratedData(parsedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }finally {
        setGenerating(false)
      }
      };

     // Handle checkbox change
    const handleIdChange = (e, imageUrl) => {
      const { checked, value } = e.target;

      if (checked) {
        // Add the ID to the selected list
        setSelectedId((prevSelectedId) => [...prevSelectedId, value]);

        // Add the image URL to the selected list
        setSelectedThumbnails((prevSelectedThumbnails) => [...prevSelectedThumbnails, imageUrl]);
      } else {
        // Remove the ID from the selected list
        setSelectedId((prevSelectedId) =>
          prevSelectedId.filter((id) => id !== value)
        );

        // Remove the image URL from the selected list
        setSelectedThumbnails((prevSelectedThumbnails) =>
          prevSelectedThumbnails.filter((url) => url !== imageUrl)
        );
      }
      console.log(selectedThumbnails)
    };

    // Handle selected all
    const handleCheckAll = () => {
      if (selectedId.length === 0) {
        data.map((resource) => {
          setSelectedId((selectedId) => [...selectedId, resource._id]);
          return selectedId;
        });
      } else {
        setSelectedId([]);
      }
    };

    useEffect(() => {
        let ids = searchParams.get('resources_id')
        async function getResourcesImages(ids) {
            const res = await axios.get(API_URL + `resources/getbulk/?ids=${ids}`)
            if(res.status === 200){
                setData(res.data)
            }
        }
        getResourcesImages(ids)
        console.log(promptsData)
        //setPrompt(`${MEDIA_URL}${data[0].thumbnail.path}`)
    }, [])

    useEffect(() => {
      //console.log(data[0])
      const networkList = selectedNetworks.join(', ');
      const socialJson = generateSocialJson();

      if(data[0]) {
        //setPrompt(`${MEDIA_URL}${data[0].thumbnail.path}`)
        setImageUrl(data[0].thumbnail ? `${MEDIA_URL}${data[0].thumbnail.path}` : '')
        //set prompt with network replace
        setPrompt( promptsData.social_post.replace('SOCIAL_NETWORK', networkList).replace('SOCIAL_OUTPUT', socialJson));
      }
    }, [data, selectedNetworks])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Test Social Post generation (ChatGPT)"}</h2>
            </header>
            <div>
                {data && 
                <>
                <div className="mb-4 flex">
                <button
                    className="btn bg-light px-3 btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCheckAll();
                    }}
                  >
                    {selectedId.length > 0 ? "Unselect all" : "Select all"}
                  </button>
                  {selectedId.length > 0 && (
                    <span className="small mx-4 text-muted">
                      {selectedId.length} {"selected"}
                    </span>
                  )}
                  </div>
                <div className="row mb-4">
                    {data.map((data, index) => {
                        const thumbnailUrl = data.thumbnail ? `${MEDIA_URL}${data.thumbnail.path}` : ''; // Get image URL
                        return(
                            <div key={index} className="col-md-1">
                                <img className="img-thumbnail" src={`${thumbnailUrl}`} with="200" />
                                <p className="mt-2 mb-1 py-0">{data.name}</p>
                                <p className="text-muted text-center mt-2 mb-1 py-0">
                                    <Link to={`/resources/view/${data._id}`} target="_blank" rel="noreferrer">
                                        <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data._id}</span>
                                    </Link>
                                </p>
                                {data.thumbnail &&
                                <p className="text-muted text-center mb-2 py-0">
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2 text-uppercase">{data.thumbnail?.contentType}</span>
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data.thumbnail?.width}{' x '}{data.thumbnail?.height}</span>
                                </p>
                                }
                                <input
                                  type="checkbox"
                                  value={data._id}
                                  className="form-check-input"
                                  {...register(`resource_id.${index}`)}
                                  //name={resource._id}
                                  onChange={(e) => handleIdChange(e, thumbnailUrl)}
                                  //checked={selectedId.includes(resource._id) ? 'checked' : ''}
                                  checked={
                                    selectedId.indexOf(data._id.trim()) > -1
                                      ? true
                                      : false
                                  }
                                />
                            </div>
                        )
                    })
                    }
                </div>
                </>
                }
                <div className="mb-4">
                <label className="form-label">
                            Select social network
                        </label><br />
                {defaultSocialNetworks.map((network) => (
                    <div key={network} className="form-check form-check-inline">

                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedNetworks.includes(network)}
                                onChange={() => handleCheckboxChange(network)}
                            />
                            <label className="form-check-label">
                            {network.charAt(0).toUpperCase() + network.slice(1)}
                        </label>
                    </div>
                ))}
                </div>
                <div className="mb-4">
                <label className="form-label">Prompt:</label>
                <textarea rows={5} 
                  type="text"
                  value={prompt}
                  className="form-control mb-2 input-sm"
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Enter or adjust the prompt"
                  style={{ width: '100%' }}
                >
                </textarea>
                <button className="btn btn-secondary" onClick={handleGenerateData}>Generate Data</button>
                </div>
                {/* Show generated data */}
                {generatedData && (
                  <div className="mb-4">
                    <h3>Generated Data</h3>
                    {selectedNetworks.map((net, index) => {
                        return (
                          <div className="" key={index}>
                        <p><strong className="text-muted">{`${net} content:`}</strong></p>
                        <p>{generatedData.post[net].content}</p>
                        </div>
                        )
                      })
                    }
                  </div>
                //   {generatedData && (
                //   generatedData.post.map(p, index) =>
                //   <div className="mb-4">
                //     <h3>Generated Data</h3>
                //     {selectedNetworks.map(net, index)}
                //     <p><strong className="text-muted">Facebook content:</strong></p>
                //     <p>{generatedData.post.facebook.content}</p>
                //   </div>
                // )}
                )}
                {
                  generating &&
                  <p className="alert alert-warning my-2">{'Your request is proccessing, please wait...!!!'}</p>
                }
                {message && (
                    <div className="mb-4">
                      <div className={`alert alert-${messageType}`} role="alert">
                        {message}
                      </div>
                    </div>
                )}
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ResourcesSocial);
      
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { createRouterMiddleware, createRouterReducerMapObject, push, ReduxRouter } from '@lagunovsky/redux-react-router'
//import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
//import { promiseMiddleware, localStorageMiddleware } from './middleware';
import createRootReducer from './reducers/';
//import { logout, refreshToken } from './actions/auth';

//import { routerMiddleware } from 'connected-react-router';

import { createBrowserHistory} from 'history';
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

export const jwtMiddleWare = (store) => (next) => (action) => {
  return next(action)
}
// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = createRouterMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(jwtMiddleWare, myRouterMiddleware, thunk);
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(jwtMiddleWare, myRouterMiddleware, thunk)
  }
};

export const store = createStore(createRootReducer(history), composeWithDevTools(getMiddleware()));

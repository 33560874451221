import axios from "axios";
import authHeader from "../../helpers/header";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { setMessage } from "../../redux/actions/message";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { API_URL, MEDIA_URL } from "../../configs";

function ResourcesCollection(props) {

    const {
      register,
      handleSubmit,
      setValue,
      getValues,
      watch,
      formState: { errors },
    } = useForm();
    
    const { message, messageType } = props;
    const dispatch = useDispatch();
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const [data, setData] = useState([]);
    const [selectedId, setSelectedId] = useState([]);
    const [generateStatistic, setGenerateStatistic] = useState([]);

    // Handle checkbox change
    const handleIdChange = (e) => {
      const { checked, value } = e.target;

      if (checked) {
        // Add the ID to the selected list
        setSelectedId((prevSelectedId) => [...prevSelectedId, value]);
      } else {
        // Remove the ID from the selected list
        setSelectedId((prevSelectedId) =>
          prevSelectedId.filter((id) => id !== value)
        );
      }
    };

    // Handle selected all
    const handleCheckAll = () => {
      if (selectedId.length === 0) {
        data.map((resource) => {
          setSelectedId((selectedId) => [...selectedId, resource._id]);
          return selectedId;
        });
      } else {
        setSelectedId([]);
      }
    };
    
    //Update collection
    const handleUpdate = async(data) => {
      console.log(data.collection_id)

      if(!selectedId || selectedId.length <= 0) {
        dispatch(setMessage(`Please select a resource`, "danger"));
        return;
      }

      if(!data.collection_id || data.collection_id == '') {
        dispatch(setMessage(`Please add a collection ID`, "danger"));
        return;
      }

      try {
        //const response = await axios.patch(API_URL + `resources/${data.resource_id}`, 
        await axios.post(API_URL + `collections/addResources`,
          { data : { 
              resource_ids : selectedId,
              collection_id: data.collection_id,
            }
          }, 
          { headers: authHeader() }
        )
        dispatch(setMessage(`${selectedId.length} was added to collection ${data.collection_id}`, "success"));
      } catch (e) {
        dispatch(setMessage(`${e}`, "danger"));
      };
      console.log(selectedId)
    }

    useEffect(() => {
        let ids = searchParams.get('resources_id')
        async function getResourcesImages(ids) {
            const res = await axios.get(API_URL + `resources/getbulk/?ids=${ids}`)
            console.log(API_URL + `resources/getbulk/?ids=${ids}`)
            if(res.status === 200){
                setData(res.data)
            }
        }
        getResourcesImages(ids)
        console.log(generateStatistic)
    }, [generateStatistic])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Add or remove resource from collection"}</h2>
            </header>
              {generateStatistic.length > 0 ? (
                generateStatistic.map((stat, index) => (
                  // <li key={index}>
                  //   <strong>Status:</strong> {stat[0].status} <br />
                  //   <strong>Message:</strong> {stat[0].message} <br />
                  // </li>
                  <div key={index} className={`alert py-2 mb-1 alert-${stat[0].status}`} role="alert">
                  {stat[0].message}
                </div>
                ))
              ) : ('')}
            <div>
                {data && 
                <>
                <div className="mb-4 flex">
                <button
                    className="btn bg-light px-3 btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCheckAll();
                    }}
                  >
                    {selectedId.length > 0 ? "Unselect all" : "Select all"}
                  </button>
                  {selectedId.length > 0 && (
                    <span className="small mx-4 text-muted">
                      {selectedId.length} {"selected"}
                    </span>
                  )}
                  </div>
                
                <div className="row">
                    {data.map((data, index) => {
                        return(
                            <div key={index} className="col-md-2">
                                <img className="img-thumbnail" src={`${MEDIA_URL}${data.coverImages.path}`} with="200" />
                                <p className="mt-2 mb-1 py-0 text-center">{data.name}</p>
                                <p className="text-muted text-center mt-2 mb-1 py-0">
                                    <Link to={`/resources/view/${data._id}`} target="_blank" rel="noreferrer">
                                        <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data._id}</span>
                                    </Link>
                                </p>
                                <p className="text-muted text-center mb-2 py-0">
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2 text-uppercase">{data.coverImages.contentType}</span>
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data.coverImages.width}{' x '}{data.coverImages.height}</span>
                                    {data.thumbnail && 
                                      <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data.thumbnail.width}{' x '}{data.thumbnail.height}</span>
                                    }
                                </p>
                                <input
                                  type="checkbox"
                                  value={data._id}
                                  className="form-check-input"
                                  {...register(`resource_id.${index}`)}
                                  //name={resource._id}
                                  onChange={(e) => handleIdChange(e)}
                                  //checked={selectedId.includes(resource._id) ? 'checked' : ''}
                                  checked={
                                    selectedId.indexOf(data._id.trim()) > -1
                                      ? true
                                      : false
                                  }
                                />
                            </div>
                        )
                    })
                    }
                </div>
                </>
                }
                <form className="flex" onSubmit={handleSubmit(handleUpdate)}>
                  <div className="my-2">
                    <label className="form-label">
                    Collection (ID):
                    </label>
                    <input type="text" 
                          className="form-control" 
                          {...register("collection_id", { required: true })} 
                            />
                    {errors.collection_id && (
                      <span className="small text-danger">
                        This field is required
                      </span>
                    )}
                
                  </div>
                  {message && (
                    <div className="mb-4">
                      <div className={`alert alert-${messageType}`} role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                  <div>
                  <button
                      type="submit"
                      className="btn btn-primary me-2"
                    >
                      Add to collection
                  </button>
                  </div>
                </form>
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ResourcesCollection);
      
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import { TagsInput } from "react-tag-input-component";
import ResourceAPI from "../../api/resourceAPI";
import FileAPI from "../../api/fileAPI";
import { connect, useDispatch } from "react-redux";
import { setMessage } from "../../redux/actions/message";
import { history } from "../../redux/store";
import {
  MEDIA_URL,
  S3_KEY,
  S3_SECRET,
  S3_REGION,
  S3_BUCKET,
} from "../../configs";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import PreviewImage from "../../components/Resource/PreviewImage";

AWS.config.update({
  accessKeyId: S3_KEY,
  secretAccessKey: S3_SECRET,
});

function ResourceCreate(props) {
  const { user, message, messageType } = props;

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState([]);
  const [mainImage, setMainImage] = useState(0);
  const [resourceFiles, setResourceFiles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [progress, setProgress] = useState(0);

  const [selectedTags, setSelectedTags] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);
  const tempPreviewRef = useRef([]);
  const tempFilesRef = useRef([]);

  const { previewProcess, previewProgress, previewUploadError } =
    FileAPI.useUploadPreview();

  const awsBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: S3_REGION,
    //   region: S3_REGION,
  });

  const resetForm = () => {
    setSelectedFile([]);
    setMainImage(0);
    setResourceFiles([]);
    setCategories([]);
    setSelectedTags([]);
    setSuggestedTags([]);
    tempFilesRef.current = [];
    tempPreviewRef.current = [];
    reset();
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    data.creator = {
      _id: user._id,
      email: user.email,
    };
    //process category
    data.categories = [];
    if (data.selected_category) {
      data.categories.push({ name: data.selected_category });
    }

    if (data.new_category) {
      data.categories.push({ name: data.new_category });
    }
    //console.log(selectedFile)
    if (selectedFile.length > 0) {
      data.images = selectedFile;
      data.mainImage = mainImage;
    }
    //console.log(data)
    await ResourceAPI.createResource(data)
      .then((response) => {
        //console.log(response)

        if (response.data._id && resourceFiles.length) {
          //check if file uploaded
          resourceFiles.map((file, index) => {
            //console.log(data.res)
            let filename = file.filename.split(/[\\\/]/).pop();
            let file_ext = filename.split(".").pop();

            const fileData = {
              filename: filename,
              resourceId: response.data._id,
              aws_path: file.aws_path,
              format: file_ext,
              file_size: file.file_size,
            };

            FileAPI.createResourceFile(fileData)
              .then((res) => {
                if (res?.data?.format === "ai" || res?.data?.format === "eps") {
                  dispatch(setMessage("Create success", "success"));
                  resetForm();
                  // FileAPI.scanAiFile(res.data._id)
                  //   .then(() => {
                  //     dispatch(setMessage("Create success", "success"));
                  //     resetForm();
                  //   })
                  //   .catch(() => {
                  //     // console.log("error", `Gen file ${res.data._id} failed`);
                  //     dispatch(
                  //       setMessage(`Gen file ${res.data._id} failed`, "danger")
                  //     );
                  //   });
                }
              })
              .catch(function (response) {
                dispatch(setMessage(response.message, "danger"));
              });
          });
          //create without upload file
          // } else {
          //   dispatch(setMessage('Create success', "success"));
          // }
        }
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });

    setIsLoading(false);
  };

  // const fetchCategories = async () => {
  //   const existingFile = selectedFile;
  //   await ResourceAPI.getCategories()
  //     .then((response) => {
  //       setCategories(response.data);
  //     })
  //     .catch((e) => {
  //       dispatch(setMessage(`${e}`, "danger"));
  //     });
  // };

  const handlePreview = async (files) => {
    let tempResult = [];
    let filtedFiles = [];
    if (tempPreviewRef.current) {
      filtedFiles = [...files].filter(
        (obj1) =>
          !tempPreviewRef.current.some((obj2) => obj1.name === obj2.name)
      );
      tempPreviewRef.current = [...tempPreviewRef.current, ...filtedFiles];
    } else {
      filtedFiles = [...files];
      tempPreviewRef.current = [...files];
    }

    if (filtedFiles.length) {
      await Promise.all(
        filtedFiles.map(async (file) => {
          const formData = new FormData();
          formData.append("data", file);
          const {data} = await previewProcess(formData);

          console.log(data)

          if (data) {
            
            tempPreviewRef.current.map((item) => {
              if (item.name === file.name) {
                item.filename = data.detail.filename;
                return item;
              } else {
                return item;
              }
            });

            const newPreview = {
              filename: data.detail.filename,
              sizes: [
                {
                  size: "original",
                  path: data.detail.original.path,
                  contentType: data.detail.original.contentType,
                  width: data.detail.original.width,
                  height: data.detail.original.height,
                },
              ],
            };
            if(data.detail.thumbnail) {
              newPreview.sizes.push(
                {
                  size: "small",
                  path: data.detail.thumbnail.path,
                  contentType: data.detail.thumbnail.contentType,
                  width: data.detail.thumbnail.width,
                  height: data.detail.thumbnail.height,
                }
              )
            }
            console.log(newPreview)
            tempResult.push(newPreview);
          }
        })
      );
      setSelectedFile([...selectedFile, ...tempResult]);
    }
  };

  const generateNameFromFile = (filename) => {
    if (filename) {
      let part = filename.split(".");
      return part[0]
        .replace(/[^a-zA-Z ]/g, " ")
        .replace(/  +/g, " ")
        .trim();
    } else {
      return "";
    }
  };

  const removePreview = (filename) => {
    const tempMainImage = selectedFile[mainImage];
    tempPreviewRef.current = [
      ...tempPreviewRef.current.filter((item) => item.filename !== filename),
    ];

    const removedPreview = selectedFile.filter(
      (item) => item.filename !== filename
    );
    const indexMainImage = removedPreview.findIndex(
      (item) => item.filename === tempMainImage.filename
    );

    setSelectedFile(removedPreview);
    setMainImage(indexMainImage);
  };

  const awsUpload = async (file) => {
    let path = `resources/tmp/${file.name}`;
    let params = {
      //ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: path,
    };
    try {
      await awsBucket
        .upload(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err, data) => {
          if (err) {
            console.log(err);
            dispatch(
              setMessage(
                "There was an error durring upload progres. Please try again!",
                "danger"
              )
            );
          } else {
            //console.log(data)
            setResourceFiles((oldArray) => [
              ...oldArray,
              { filename: file.name, aws_path: path, file_size: file.size },
            ]);
          }
        });
    } catch (error) {
      dispatch(
        setMessage(
          "There was an error durring upload progres. Please try again!",
          "danger"
        )
      );
    }
  };

  const handleFile = async (files) => {
    //console.log(files)

    let filtedFiles = [];
    if (tempFilesRef.current) {
      filtedFiles = [...files].filter(
        (obj1) => !tempFilesRef.current.some((obj2) => obj1.name === obj2.name)
      );
      tempFilesRef.current = [...tempFilesRef.current, ...filtedFiles];
    } else {
      filtedFiles = [...files];
      tempFilesRef.current = [...files];
    }

    setIsLoading(true);
    if (filtedFiles.length) {
      filtedFiles.map(async (file, index) => {
        await awsUpload(file);
      });

      if (!getValues("name")) {
        let convert_name = generateNameFromFile(filtedFiles[0].name);
        setValue("name", convert_name);
        setValue("description", convert_name);
        //process tag
        let converted_tags = convert_name.replace(/ +/g, ",");
        let new_tags = [];
        converted_tags.split(",").map((t) => {
          if (t.length > 2) {
            new_tags.push(t);
          }
        });
        setSelectedTags(new_tags);
        setValue("tags", new_tags.join(","));
        if (new_tags.length > 0) {
          new_tags.map((tg) => {
            fetchRelatedTags(tg);
          });
        }
      }
    }
    setIsLoading(false);
  };

  /**
   *
   * @param {string} tag
   */
  const fetchRelatedTags = async (tag) => {
    await ResourceAPI.getRelatedTag(tag)
      .then((response) => {
        setSuggestedTags((current) => [...current, ...response.data]);
        // console.log(suggestedTags);
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
  };

  /**
   * Add tag
   * @param {array} tag
   */

  const handleTags = async (tag) => {
    //get new tag to get related
    let new_tag = tag.filter((x) => !selectedTags.includes(x));
    //get related tag
    if (new_tag != "") {
      fetchRelatedTags(new_tag);
    }
    //set selected tag state
    setSelectedTags(tag);
    //process tags input value
    let tag_values = getValues("tags").split(",");

    tag.map((t) => {
      if (t !== "" && !tag_values.includes(t)) {
        //avoid adding empty value
        if (getValues("tags").length > 0) {
          tag_values.push(t);
        } else {
          tag_values = [t];
        }
      }
    });
    //set tags value
    setValue("tags", tag_values.join(","));
  };

  /**
   * Remove tag
   * @param {string} tag
   */
  const handleRemoveTag = (tag) => {
    let tag_values = getValues("tags").split(",");
    let index = tag_values.indexOf(tag);
    if (index > -1) {
      //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
      tag_values.splice(index, 1);
    }
    setValue("tags", tag_values.join(","));
    //rebuild suggested tags list
    // if (selectedTags.length > 0) {
    //   setSuggestedTags((current) => [...current, { _id: tag }]);
    // }
    suggestedTags.map((tg, i) => {
      //if (tg._id.includes(tag)) {
      if (tg._id.indexOf(tag) !== -1) {
        setSuggestedTags((prevState) =>
          prevState.filter((prevItem) => prevItem !== tg)
        );
      }
    });
  };

  /**
   * add suggested tag to selected
   */
  const addSugggestTag = (tag) => {
    if (selectedTags.indexOf(tag) == -1) {
      //add tagg to selected tags
      setSelectedTags((current) => [...current, tag]);
      //remove added tag from suggestedTags
      if (suggestedTags.length > 0) {
        suggestedTags.map((tg, i) => {
          if (tg._id === tag) {
            setSuggestedTags((current) =>
              current.filter((item, itemIndex) => itemIndex != i)
            );
          }
        });
      }
    }
  };

  useEffect(() => {
    //fetchCategories();
    setIsLoading(false);

    suggestedTags.map((tg, i) => {
      // console.log(tg._id);
    });
  }, [progress, selectedFile, resourceFiles, suggestedTags, selectedTags]);

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h1>{"Create"}</h1>
        <button className="btn btn-outline-secondary" onClick={history.back}>
          Go Back
        </button>
      </header>
      {message && (
        <div className="mb-4">
          <div className={`alert alert-${messageType}`} role="alert">
            {message}
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-5 me-auto">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Resource name
              </label>
              <input
                id="name"
                className="form-control"
                defaultValue={""}
                placeholder={"Enter resource name"}
                {...register("name", { required: true })}
              />
              {errors.description && (
                <span className="small text-danger">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="tags" className="form-label">
                Tags
              </label>
              <input
                id="tags"
                className="form-control"
                hidden
                defaultValue={""}
                placeholder={"Enter tags"}
                {...register("tags")}
              />
              <TagsInput
                value={selectedTags}
                onChange={handleTags}
                onRemoved={handleRemoveTag}
                name="input-tags"
                placeHolder="Enter tags"
              />
              <div className="mt-2"></div>
              {suggestedTags &&
                suggestedTags.map((tg, i) => {
                  return (
                    <span
                      key={i}
                      className="badge bg-white border rounded me-2 text-dark fw-normal fs-6 my-1 py-2 px-3 suggested-tag"
                      onClick={() => {
                        addSugggestTag(tg._id);
                      }}
                    >
                      {tg._id}
                    </span>
                  );
                })}
            </div>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label htmlFor="tags" className="form-label">
                  License
                </label>
                <select
                  className="form-select"
                  defaultValue={"free"}
                  {...register("license")}
                >
                  <option value="free">Free</option>
                  <option value="premium">Premium</option>
                </select>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select className="form-select" {...register("status")}>
                  <option value="draft">Draft</option>
                  <option value="reject">Reject</option>
                  <option value="schedule">Schedule</option>
                  <option value="pending">Pending</option>
                  <option value="missing">Missing</option>
                  <option value="active">Active</option>
                  <option value="deleted">Deleted</option>
                </select>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="orientation" className="form-label">
                  Orientation
                </label>
                <select
                  id="orientation"
                  className="form-select"
                  defaultValue={""}
                  {...register("orientation")}
                >
                  <option value="square">Square</option>
                  <option value="vertical">Vertical</option>
                  <option value="horizontal">Horizontal</option>
                </select>
              </div>
            </div>
            <div className="mb-3 d-flex justify-content-between align-items-center d-none">
              <div className="col-5">
                <label htmlFor="categories" className="form-label">
                  Select categories
                </label>
                <select
                  id="categories"
                  defaultValue={""}
                  className="form-select"
                  {...register("selected_category")}
                >
                  <option value="">{"-"}</option>
                  {categories &&
                    categories.map((category, index) => {
                      return (
                        <option key={index} value={category._id}>
                          {category._id}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-2 text-center mt-4">
                <span className="text-muted">OR</span>
              </div>
              <div className="col-5">
                <label htmlFor="new_category" className="form-label">
                  New category
                </label>
                <input
                  id="new_category"
                  className="form-control"
                  placeholder={"Enter categoryname"}
                  {...register("new_category")}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Descrtiption
              </label>
              {/* include validation with required or other standard HTML validation rules */}
              <textarea
                id="description"
                className="form-control"
                defaultValue={""}
                placeholder={"Add short description for resource"}
                {...register("description", { required: true })}
              />
              {/* errors will return when field validation fails  */}
              {errors.description && (
                <span className="small text-danger">
                  This field is required
                </span>
              )}
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={isLoading}
            >
              Save
            </button>
          </div>
          {/* col */}
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="rfile" className="form-label">
                Resource files
              </label>
              <input
                className="form-control"
                type="hidden"
                {...register("uploaded_file")}
              />
              <Dropzone
                onDrop={(acceptedFiles) => handleFile(acceptedFiles)}
                accept={{
                  "application/postscript": [".ai", ".eps"],
                  "image/svg+xml": [".svg"],
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="bg-light text-center p-4 border rounded">
                    <div {...getRootProps()}>
                      <input id="rfile" {...getInputProps()} />
                      <span className="text-muted small">
                        Drag 'n' drop some files here, or click to select files
                      </span>
                    </div>
                  </section>
                )}
              </Dropzone>
              {/* {fileUploadError && (
                <div className="alert alert-danger text-center py-2 mt-2">
                  {fileUploadError}
                </div>
              )} */}
              {progress > 0 && progress < 100 && (
                <>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                    {progress}
                  </div>
                </>
              )}
            </div>
            {resourceFiles.length > 0 && (
              <>
                <strong className="mt-3 mb-0">{"Uploaded file"}</strong>
                <ul className="list-unstyled mt-2">
                  {resourceFiles.map((file, i) => {
                    return (
                      <li
                        className="mb-1 d-flex justify-content-start align-items-center"
                        key={i}
                      >
                        {<div className="me-4">{file.filename}</div>}
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

            <div className="mb-4">
              <div className="mb-3">
                <label htmlFor="pfile" className="form-label">
                  Preview file
                </label>

                <Dropzone
                  onDrop={(acceptedFiles) => handlePreview(acceptedFiles)}
                  accept={{
                    "image/png": [".png"],
                    "image/jpeg": [".jpg", ".jpeg"],
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="bg-light text-center p-4 border rounded">
                      <div {...getRootProps()}>
                        <input id="pfile" {...getInputProps()} />
                        <span className="text-muted small">
                          Drag 'n' drop some files here, or click to select
                          files
                        </span>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {previewUploadError && (
                  <div className="alert alert-danger text-center py-2 mt-2">
                    {previewUploadError}
                  </div>
                )}
                {previewProgress > 0 && previewProgress < 100 && (
                  <>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${previewProgress}%` }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      {previewProgress}
                    </div>
                  </>
                )}
              </div>
              {selectedFile.length > 0 && (
                <div className="d-flex bg-gray">
                  {selectedFile.map((preview, index) => {
                    return (
                      <div className="text-center" key={index}>
                        <div className="shadow mx-2 position-relative">
                          <PreviewImage
                            image={`${MEDIA_URL}${preview.sizes[0].path}`}
                            index={index}
                            width={200}
                            height={100}
                            mainImage={mainImage}
                            setMainImage={setMainImage}
                          />
                          {mainImage !== index && (
                            <button
                              className="position-absolute top-0 end-0 border-0 bg-transparent"
                              onClick={() => removePreview(preview.filename)}
                            >
                              <i className="bi bi-x-circle-fill"></i>
                            </button>
                          )}
                        </div>
                        {mainImage === index && (
                          <p className="pt-2 fw-semibold">Cover Image</p>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user; //console.log(response)
  const { message, messageType } = state.message;
  return {
    token,
    user,
    message,
    messageType,
  };
}

export default connect(mapStateToProps)(ResourceCreate);

import axios from "axios";
//import authHeader from "../../helpers/header";
import Moment from "react-moment";
import TimeAgo from "react-timeago";
import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { Table } from "react-bootstrap";
import { API_URL } from "../../configs";

function ReportSearch(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([]);
    
    const [totalResults, setTotalResults] = useState(0);  // To keep track of total results
    const limit = parseInt(searchParams.get('limit')) || 50;  // Number of results per page (default: 10)
    const offset = parseInt(searchParams.get('offset')) || 0;  // Current offset (default: 0)
    const orderBy = searchParams.get('order_by') || 'count';  // Default order_by is 'count'
    const order = searchParams.get('order') || 'desc';  // Default order is 'desc'

    const handleSortChange = (field, direction) => {
        // Update searchParams to include the new sorting parameters
        setSearchParams({ ...Object.fromEntries(searchParams), order_by: field, order: direction });
    };


    // Handle pagination logic
    const handlePageChange = (newOffset) => {
        setSearchParams({
            ...Object.fromEntries(searchParams),
            offset: newOffset
        });
    };

    
    useEffect(() => {
        async function fetchSearchLog() {
            // Get the range query parameter
            const range = searchParams.get('range'); // Could be null or a number
        
            // Build the API URL
            //let apiUrl = `${API_URL}reports/search?limit=${limit}&offset=${offset}`;
            let apiUrl = `${API_URL}reports/search?limit=${limit}&offset=${offset}&order_by=${orderBy}&order=${order}`;

            if (range) {
              apiUrl += `&range=${range}`;  // Add range to the URL if it exists
            }
      
            try {
              const res = await axios.get(apiUrl);  // Fetch data from the API
              if (res.status === 200) {
                setData(res.data.results);  // Set data in state if the request is successful
                setTotalResults(res.data.total_count);  // Set total results (from API response header)
              }
            } catch (error) {
              console.error("Error fetching search logs:", error);
            }
        }
        fetchSearchLog()
        //console.log(data)
    }, [searchParams])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Search log"}</h2>
                {/* Sorting Dropdown */}
                <div>
                    <label htmlFor="sort-field">Sort By:</label>
                    <select
                        id="sort-field"
                        onChange={(e) => handleSortChange(e.target.value, searchParams.get('order') || 'desc')}
                        value={searchParams.get('order_by') || 'count'}
                    >
                        <option value="count">Count</option>
                        <option value="max_results">Max Results</option>
                        <option value="last_query_timestamp">Last Query Timestamp</option>
                    </select>

                    <label htmlFor="sort-direction">Order:</label>
                    <select
                        id="sort-direction"
                        onChange={(e) => handleSortChange(searchParams.get('order_by') || 'count', e.target.value)}
                        value={searchParams.get('order') || 'desc'}
                    >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </div>
                <div>
                    <span className="me-4 text-muted">Range</span>
                    <Link 
                        to={`?range=7`} 
                        rel="noreferrer"
                        className="btn btn-outline-secondary me-3"
                    >
                        Last 7 days
                    </Link>
                    <Link 
                        to={`?range=30`} 
                        rel="noreferrer"
                        className="btn btn-outline-secondary me-3"
                    >
                        Last 30 days
                    </Link>
                    <Link 
                        to={`/reports/search`} 
                        rel="noreferrer"
                        className="btn btn-outline-secondary me-3"
                    >
                        All time
                    </Link>
                </div>
            </header>
            <div>
                {data &&
                <>
                 <Table className="table-hover table-striped mb-4" responsive>
                <thead>
                  <tr>
                    <th scope="col" className="fw-normal text-muted">{'Query'}</th>
                    <th scope="col" className="fw-normal text-muted">{'Number of querry'}</th>
                    <th scope="col" className="fw-normal text-muted">{'Max results'}</th>
                    <th scope="col" className="fw-normal text-muted">{'Last query'}</th>
                  </tr>
                </thead>
                <tbody>
                {
                data.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td>
                                <Link to={`/resources/view/${item._id}`} target="_blank" rel="noreferrer">
                                 {item.search_query}
                                </Link>
                            </td>
                            <td>{item.count}</td>
                            <td>{item.max_results}</td>
                            {/* <td>{item.last_query_timestamp}</td> */}
                            <td className="text-muted text-start">
                            <Moment
                                format="YYYY-MM-DD HH:mm:ss"
                                className="small"
                            >
                                {item.last_query_timestamp}
                            </Moment>
                            <span className="me-2 my-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2 ms-2">
                                <i className="bi bi-clock me-1"></i>
                                <span className="">
                                <TimeAgo date={item.last_query_timestamp} />
                                </span>
                            </span>
                            {/* {new Date(user.created_at).toDateString()} */}
                            </td>
                        </tr>
                    )
                })
                }
                </tbody>
                </Table>
                </>
                }
                {/* Pagination Controls */}
                <div>
                    <button
                        disabled={offset === 0}
                        onClick={() => handlePageChange(offset - limit)}
                    >
                        Previous
                    </button>

                    <span>Page {parseInt(offset / limit) + 1}</span>

                    <button
                        disabled={(offset + limit) >= totalResults}
                        onClick={() => handlePageChange(parseInt(offset / limit) + 1)}
                    >
                        Next
                    </button>
                </div>
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ReportSearch);
      
import axios from "axios";
//import authHeader from "../../helpers/header";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { API_URL, MEDIA_URL } from "../../configs";

const InputWithCopyButton = ({ initialText }) => {

    const [text, setText] = useState(initialText);
    const [isCopied, setIsCopied] = useState(false);
    
  
    const handleCopyClick = () => {
      const input = document.createElement('input');
      input.value = text;
  
      input.style.position = 'absolute';
      input.style.left = '-9999px';
  
      document.body.appendChild(input);
  
      input.select();
      document.execCommand('copy');
  
      document.body.removeChild(input);
  
      setIsCopied(true);
  
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };
  
    const handleTextChange = (event) => {
      setText(event.target.value);
    };
  
    return (
      <div className="d-flex g-3 align-items-center">
        <input type="text" className="form-control form-control-sm" value={text} onChange={handleTextChange} />
        <button className="btn btn-secondary" onClick={handleCopyClick} disabled={isCopied}>
            {isCopied ? 
            <i className="bi bi-check text-white fs-6"></i>
            : 
            <i className="bi bi-clipboard fs-6"></i>
          }
        </button>
      </div>
    );
};

const TextAreaWithCopyButton = ({ initialText, label, wrapClass }) => {
    const [text, setText] = useState(initialText);
    const [isCopied, setIsCopied] = useState(false);
  
    const handleCopyClick = () => {
      const textarea = document.createElement('textarea');
      textarea.value = text;
  
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
  
      document.body.appendChild(textarea);
  
      textarea.select();
      document.execCommand('copy');
  
      document.body.removeChild(textarea);
  
      // Update the state to indicate that the text has been copied
      setIsCopied(true);
  
      // Reset the isCopied state after a brief period (e.g., 2 seconds)
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };
  
    const handleTextChange = (event) => {
      setText(event.target.value);
    };
  
    return (
      <div className={`${wrapClass ? wrapClass : ''}`}>
        <label htmlFor="label" className="form-label text-muted">
            {label}
        </label>
        <textarea className="form-control form-control-sm text-xs text-muted mb-2" value={text} onChange={handleTextChange} rows={4} cols={5} />
        <button className="btn btn-secondary" onClick={handleCopyClick} disabled={isCopied}>
          {isCopied ? 
            <i className="bi bi-check text-white fs-6"></i>
            : 
            <i className="bi bi-clipboard fs-6"></i>
          }
        </button>
      </div>
    );
  };

function ResourcesImageProcess(props) {

    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    //const [imageUrl, setImageUrl] = useState(null);
    const [data, setData] = useState([]);
    const [generateStatistic, setGenerateStatistic] = useState([]);

    //image downloader function
    //ratio could be 1:1, 4:5, 9:16 , 4:3, 16:9
    const imageDownloadHandler = async (pictures, ratio = "9:16") => {
      // Parse the ratio string, e.g., "4:5" becomes widthRatio = 4 and heightRatio = 5
      const [widthRatio, heightRatio] = ratio.split(":").map(Number);
  
      for (let i = 0; i < pictures.length; i++) {
          console.log(pictures[i].slug);
          let pictureUrl = `${MEDIA_URL}${pictures[i].coverImages.path}`;
          let output = pictures[i].slug;
  
          const response = await fetch(`${pictureUrl}`);
          const blob = await response.blob();
          
          const img = document.createElement('img');
          img.src = URL.createObjectURL(blob);
  
          img.onload = () => {
              // Determine canvas dimensions based on the image size and the specified aspect ratio
              const imageWidth = img.width;
              const imageHeight = img.height;
              const imageAspectRatio = imageWidth / imageHeight;
              const targetAspectRatio = widthRatio / heightRatio;
  
              let canvasWidth, canvasHeight;
  
              if (imageAspectRatio > targetAspectRatio) {
                  // Image is wider than the target ratio, so limit by width
                  canvasWidth = imageWidth;
                  canvasHeight = imageWidth * (heightRatio / widthRatio);
              } else {
                  // Image is taller or exactly the target ratio, so limit by height
                  canvasHeight = imageHeight;
                  canvasWidth = imageHeight * (widthRatio / heightRatio);
              }
  
              // Create the canvas with the calculated dimensions
              const canvas = document.createElement('canvas');
              canvas.width = canvasWidth;
              canvas.height = canvasHeight;
              const ctx = canvas.getContext('2d');
  
              // Fill the canvas with a white background
              ctx.fillStyle = '#FFFFFF';
              ctx.fillRect(0, 0, canvasWidth, canvasHeight);
  
              // Center the image on the canvas
              const offsetX = (canvasWidth - imageWidth) / 2;
              const offsetY = (canvasHeight - imageHeight) / 2;
              ctx.drawImage(img, offsetX, offsetY, imageWidth, imageHeight);
  
              // Convert the canvas content to a blob (JPG format with quality 0.9)
              canvas.toBlob((newBlob) => {
                  let url = window.URL.createObjectURL(newBlob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `${output}.jpg`; // Set the desired download filename with the .jpg extension
                  a.click();
              }, 'image/jpeg', 0.9);
          };
      }
    };
  
    const socialPost = async () => {
      const ids = searchParams.get('resources_id')
      window.open(
          `/resources/socialPost/?resources_id=${ids}`,
          "_blank"
        );
    }

    const handleDownload = (ratio) => {
      imageDownloadHandler(data, ratio);
    };

    const handleGenerate = async() => {
        let ids = searchParams.get('resources_id')
        //console.log(ids.split(','))
        ids.split(',').forEach( async (id) => {
          console.log(id)
          try {
            const res = await axios.get(API_URL + `datas/regenerateThumbnail/${id}`)
            if(res.status === 200){
                console.log(res.data)
                //setData(res.data)
                // Update the state by appending the new result
                setGenerateStatistic(prevState => [...prevState, res.data]);
            }else{
              console.log('err')
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        })
        //async function getResourcesImages(ids) {
        
        //}
    }

    useEffect(() => {
        let ids = searchParams.get('resources_id')
        async function getResourcesImages(ids) {
            const res = await axios.get(API_URL + `resources/getbulk/?ids=${ids}`)
            console.log(API_URL + `resources/getbulk/?ids=${ids}`)
            if(res.status === 200){
                setData(res.data)
            }
        }
        getResourcesImages(ids)
        console.log(generateStatistic)
    }, [generateStatistic])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Download resource images"}</h2>
                <div className="d-flex">
                {/* <button
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={() => {
                        handleDownload()
                    }}
                  >
                    Download images
                </button> */}

                  <Dropdown className="me-2">
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        <i className="bi bi-download"></i>
                        <span className="ms-2">Download images</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleDownload("1:1")}>Download 1:1 (Square)</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDownload("4:5")}>Download 4:5 (Portrait)</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDownload("9:16")}>Download 9:16 (Vertical)</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDownload("4:3")}>Download 4:3 (Landscape)</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDownload("16:9")}>Download 16:9 (Wide)</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <button
                    type="submit"
                    className="btn btn-outline-secondary me-2"
                    onClick={() => {
                        handleGenerate()
                    }}
                  >
                    <i className="bi bi-rolate"></i>
                    Generate thumbnail
                </button>
                <button
                    type="submit"
                    className="btn btn-secondary"
                    onClick={socialPost}
                  >
                    <i className="bi bi-share"></i>
                </button>
                
                </div>
            </header>
              {generateStatistic.length > 0 ? (
                generateStatistic.map((stat, index) => (
                  // <li key={index}>
                  //   <strong>Status:</strong> {stat[0].status} <br />
                  //   <strong>Message:</strong> {stat[0].message} <br />
                  // </li>
                  <div key={index} className={`alert py-2 mb-1 alert-${stat[0].status}`} role="alert">
                  {stat[0].message}
                </div>
                ))
              ) : ('')}
            <div>
                {data && 
                <div className="row">
                    {data.map((data, index) => {
                        return(
                            <div key={index} className="col-md-2 mb-4">
                                <img className="img-thumbnail" src={`${MEDIA_URL}${data.coverImages.path}`} with="200" />
                                <p className="mt-2 mb-1 py-0 text-center">{data.name}</p>
                                <p className="text-muted text-center mt-2 mb-1 py-0">
                                    <Link to={`/resources/view/${data._id}`} target="_blank" rel="noreferrer">
                                        <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data._id}</span>
                                    </Link>
                                </p>
                                <p className="text-muted text-center mb-2 py-0">
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2 text-uppercase">{data.coverImages.contentType}</span>
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data.coverImages.width}{' x '}{data.coverImages.height}</span>
                                    {data.thumbnail ?
                                      (<span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data.thumbnail.width}{' x '}{data.thumbnail.height}</span>)
                                        :
                                      (
                                        <>
                                                    <span
                                                    className="bg-danger d-inline-flex rounded-circle ml-auto"
                                                    style={{ width: "10px", height: "10px" }}
                                                    ></span>
                                                </>
                                      )
                                    }
                                </p>
                                {data.thumbnail && 
                                <>
                                <TextAreaWithCopyButton 
                                wrapClass="mb-2"
                                label={'Embeded'}
                                initialText={`<a href='https://magipik.com/vector/${data.slug}' title='${data.slug.replace('-', ' ')}' target="_blank"><img src='https://media.magipik.com/${data.thumbnail?.path}' alt='${data.slug.replace('-', ' ')}' /></a>`} />
                                </>
                                }
                                <InputWithCopyButton initialText={`https://magipik.com/vector/${data.slug}`} />
                            </div>
                        )
                    })
                    }
                </div>
                }
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ResourcesImageProcess);
      
import {
  FETCH_FILES_FAILED,
  FETCH_FILES_PENDING,
  FETCH_FILES_SUCCESS,
  UPDATE_FILES_SUCCESS,
} from "../actions/types";

const initialState = {
  files: [],
  size: 0,
  fileDetail: {},
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FILES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FILES_SUCCESS:
      // console.log(action.page)
      return {
        ...state,
        files: payload.items,
        size: payload.size,
        loading: false,
      };

    case FETCH_FILES_FAILED:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_FILES_SUCCESS:
      const index = state.files.findIndex((item) => item._id === payload._id);
      state.files[index] = { ...state.files[index], ...payload };
      return {
        ...state,
      };

    default:
      return state;
  }
};

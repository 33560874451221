import axios from "axios";
import authHeader from "../../helpers/header";

import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'; // or use useNavigate if you're using React Router v6

//import { useForm } from "react-hook-form";
import Moment from "react-moment";
import TimeAgo from "react-timeago";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { connect, useDispatch } from "react-redux";
import CollectionAPI from "../../api/collectionAPI";
import UserAPI from "../../api/userAPI";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { setMessage } from "../../redux/actions/message";

import format from "../../helpers/format";
import { MEDIA_URL, API_URL, FE_WEBSITE_URL } from "../../configs";



//export default function ResourceDetail(props) {
function CollectionEdit(props) {

    const { id, status } = useParams();

    //const search = useLocation().search
    //const searchParams = new URLSearchParams(search)

    const [isLoading, setLoading] = useState(true);
    const [selectedId, setSelectedId] = useState([]);
    const [collection, setCollection] = useState({});
    const [collectionResources, setCollectionResources] = useState([]);

    const [userData, setUserData] = useState({});
    const dispatch = useDispatch();
    const { token, user, message, messageType } = props;

    //state use to handle form submit
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    //const navigate = useNavigate(); // or useNavigate() if using React Router v6

    //function slugify(str) {
    // const slugify = async (event) => {
    //     let str = getValues("name");
    //     let slug_id = getValues("slug_id");
    //     // let today = Math.round((new Date()).getTime() / 1000);
    //     // console.log(today);
    //     //console.log(a)
    //     let slug = String(str + " " + slug_id)
    //         .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    //         .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    //         .trim() // trim leading or trailing whitespace
    //         .toLowerCase() // convert to lowercase
    //         .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    //         .replace(/\s+/g, "-") // replace spaces with hyphens
    //         .replace(/-+/g, "-"); // remove consecutive hyphens
    //     setValue("slug", slug);
    //     event.preventDefault();
    //     };

    // Handle checkbox change
    const handleIdChange = (e) => {
        const { checked, value } = e.target;
  
        if (checked) {
          // Add the ID to the selected list
          setSelectedId((prevSelectedId) => [...prevSelectedId, value]);
        } else {
          // Remove the ID from the selected list
          setSelectedId((prevSelectedId) =>
            prevSelectedId.filter((id) => id !== value)
          );
        }
    };

    const handleUpdate = async (data) => {
        console.log(selectedId)
        //const resourceIds = selectedId//data.resource_ids.split(',').map(id => id.trim());
        //const format = data.format.trim();
        if(!selectedId || selectedId.length <= 0) {
            dispatch(setMessage(`Please select a resource`, "danger"));
            return;
        }
        // Add resource_ids as an array
        data.resources = selectedId;
        try {
            setLoading(true);

            await CollectionAPI.updateCollection(id, data);
            //setCollection(response.data);
            dispatch(setMessage(`Update collection successfull`, "success"));
            
        } catch (e) {
            dispatch(setMessage(`${e}`, "danger"));
        }finally {
            setLoading(false);
        }
    };

    // Handle selected all
    const handleCheckAll = () => {
        if (selectedId.length === 0) {
            collectionResources.map((resource) => {
            setSelectedId((selectedId) => [...selectedId, resource._id]);
            return selectedId;
          });
        } else {
          setSelectedId([]);
        }
    };

    // Fetch owner data
    const fetchOwnerData = async (id) => {
        try {
            const response = await UserAPI.getUser(id);
            setUserData(response);
        } catch (e) {
            dispatch(setMessage(`${e}`, "danger"));
        }
    };

    // Fetch collection data
    const fetchData = async (collectionId) => {
        try {
            const response = await CollectionAPI.getCollectionById(collectionId);
            setCollection(response.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    // Fetch collection resources
    const getCollectionResources = async (collectionId) => {
        try {
            const response = await CollectionAPI.getResourceFromCollection(collectionId);
            setCollectionResources(response.data);
            // setValue( 'resource_ids', response.data 
            //     ? response.data.map(resource => resource._id).join(',') // Join without adding extra comma
            //     : '')
        } catch (e) {
            console.log(e);
        }
    };
    
    const deleteCollection = async (id) => {
        try {
            const response = await CollectionAPI.removeCollection(id);
            console.log(response)
            if (response.status === 204) {
                dispatch(setMessage(`Collection ${id} was deleted`, "success"));
                const updatedCollection = await fetchData(id); // Re-fetch the collection after deletion
                setCollection(updatedCollection);            // Update state with the re-fetched collection data
            }else{
                dispatch(setMessage(`There was an error occur, try again!`, "danger"));
            }
        } catch (e) {
            dispatch(setMessage(`${e}`, "danger"));
        }
    };

    // First useEffect to fetch data
    useEffect(() => {
        (async () => {
            await fetchData(id);
            await getCollectionResources(id);
            //let arr = []
            
        })();
    }, [id]);

    // Second useEffect to fetch owner data based on collection.ownerId
    useEffect(() => {
        (async () => {
            if (collection.ownerId) {
                await fetchOwnerData(collection.ownerId);
            }
            
        })();
    }, [collection?.ownerId]);

    // First useEffect to fetch data
    useEffect(() => {
  
            collectionResources.map((resource) => {
                selectedId.push(resource._id)
            })
           
    }, [collectionResources]);

    return (
        <AuthLayout>
          {isLoading ? (
            <>
              <p>{'Loading...'}</p>
            </>
          ) : (
            <>
            <header
                className={
                "d-md-flex d-sm-inline justify-content-between align-items-center mb-4 gap-3"
                }
            >
                <div className="d-flex align-items-center">
                    {collection.collection_name && 
                        <h2 className="mb-2 me-2">{collection.collection_name}</h2>
                    }
                    <span className="ms-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                        {collectionResources && collectionResources.length}       
                    </span>
                    
                </div>
                <div className="justify-content-between">
                {(collection.status && collection.status !== 'deleted' )?
                (
                <button
                    className="btn btn-danger mx-1"
                    onClick={(e) => {
                        e.preventDefault();
                        deleteCollection(collection._id);
                    }}
                    >Delete</button>
                ):('')}
                <Link
                className={"btn btn-primary"}
                to={`/collections/view/${collection._id}`}
              >
                View
              </Link>
              </div>
            </header>
            {message && (
                <div className="mb-4">
                <div className={`alert alert-${messageType}`} role="alert">
                    {message}
                </div>
                </div>
            )}

            <div>
                <div className="row">
                    <div className="col-md-8">
                        <form onSubmit={handleSubmit(handleUpdate)} className="row">
                            <div className="col-md-6 mb-3 d-none">
                                <label htmlFor="resource_ids" className="d-none">Resource IDs (comma separated):</label>
                                <input
                                id="resources_id"
                                type="hidden"
                                // value={collectionResources 
                                //     ? collectionResources.map(resource => resource._id).join(',') // Join without adding extra comma
                                //     : ''}
                                //{...register('resource_ids', { required: true })}
                                placeholder="Enter resource IDs, comma separated"
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="slug" className="form-label">
                                    Collection name
                                </label>
                                <div className="d-flex">
                                    <input className="form-control"
                                    defaultValue={collection.collection_name} 
                                    {...register("collection_name")} />
                                    {/* <div>
                                    <button
                                        className="btn btn-link"
                                        onClick={(event) => {
                                        slugify(event);
                                        }}
                                    >
                                        <i className="bi bi-check text-success fs-6"></i>
                                    </button>
                                    </div> */}
                                </div>
                                {errors.collection_name && (
                                    <span className="small text-danger">
                                    This field is required
                                    </span>
                                )}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="slug" className="form-label">
                                    Resource slug
                                </label>
                                <div className="d-flex">
                                    <input className="form-control" 
                                    defaultValue={collection.slug} 
                                    {...register("slug")} />
                                    {/* <div>
                                    <button
                                        className="btn btn-link"
                                        onClick={(event) => {
                                        slugify(event);
                                        }}
                                    >
                                        <i className="bi bi-check text-success fs-6"></i>
                                    </button>
                                    </div> */}
                                </div>
                                {errors.slug && (
                                    <span className="small text-danger">
                                    This field is required
                                    </span>
                                )}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="slug" className="form-label">
                                    Resource tags
                                </label>
                                <div className="d-flex">
                                    <input className="form-control" 
                                    defaultValue={collection.tags.map(tag => tag.name).join(',')} 
                                    {...register("tags")} />
                                    {/* <div>
                                    <button
                                        className="btn btn-link"
                                        onClick={(event) => {
                                        slugify(event);
                                        }}
                                    >
                                        <i className="bi bi-check text-success fs-6"></i>
                                    </button>
                                    </div> */}
                                </div>
                                {errors.tags && (
                                    <span className="small text-danger">
                                    This field is required
                                    </span>
                                )}
                            </div>
                            <div className="col-md-2 mb-3">
                                <label htmlFor="status" className="form-label">
                                Status
                                </label>
                                <select className="form-select" 
                                defaultValue={collection.status}
                                {...register("status")}>
                                <option value="active">Active</option>
                                <option value="deleted">Deleted</option>
                                </select>
                            </div>
                            <div className="col-md-2 mb-3">
                                <label htmlFor="status" className="form-label">
                                Private {collection.private}
                                </label>
                                <select className="form-select" 
                                defaultValue={collection.private}
                                {...register("private")}>
                                <option value="true">True</option>
                                <option value="false">False</option>
                                </select>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="mt-2 btn btn-primary">Save</button>
                            </div>
                        </form> 
                    </div>
                </div>
                <div className="row border-top-0 gx-2 mt-3">
                    {collectionResources && 
                    <>
                    <div className="mb-4 flex">
                <button
                    className="btn bg-light px-3 btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCheckAll();
                    }}
                  >
                    {selectedId.length > 0 ? "Unselect all" : "Select all"}
                  </button>
                  {selectedId.length > 0 && (
                    <span className="small mx-4 text-muted">
                      {selectedId.length} {"selected"}
                    </span>
                  )}
                  </div>
                   {
                    collectionResources.map((resource, index) => {
                        
                        return (
                            <div
                            key={index}
                            className={`col-2 mb-4 align-middle`}
                            >
                            <div
                                className={`position-relative p-3 
                                    `
                            }
                            >
                                <div className="text-center">
                                {resource?.thumbnail?.path ? (
                                    <>
                                    {resource?.originalImage?.path ? (
                                        <a
                                        href={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        >
                                        <LazyLoadImage
                                            alt={resource.name}
                                            src={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                            height={160}
                                            effect="blur"
                                            className="img-preview"
                                        />
                                        </a>
                                    ) : (
                                        <>
                                        <LazyLoadImage
                                            alt={resource.name}
                                            src={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                            height={160}
                                            effect="blur"
                                            className="img-preview"
                                        />
                                        </>
                                    )}
                                    </>
                                ) : (
                                    <>
                                    {resource?.originalImage?.path && (
                                        <a
                                        href={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        >
                                        <LazyLoadImage
                                            alt={resource.name}
                                            src={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                            height={160}
                                            delayMethod="debounce"
                                            effect="blur"
                                            className="img-preview"
                                        />
                                        </a>
                                    )}
                                    </>
                                )}
                                </div>
                                <div className="d-flex justify-content-start align-items-center py-1">
                                <span>
                                    {format.formatResourceStatusNoText(resource.status)}
                                </span>
                                <div className="small mt-1 ms-2">
                                    <span className="ms-2">
                                    {format.formatResourceOrientation(
                                        resource.orientation
                                    )}
                                    </span>
                                </div>
                                </div>
                                <div>
                                <Link to={`/resources/view/${resource._id}`}>
                                    <strong className="rname small">
                                    {resource.name}
                                    </strong>
                                </Link>
                                <div className="mb-1">
                                    <Link to={`/resources/view/${resource._id}`}>
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                                        {resource._id}
                                    </span>
                                    </Link>
                                </div>
                                <div className="d-flex align-items-center py-1 small">
                                    <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                                    {resource.slug.split("-").pop()}
                                    </small>
                                    {resource.metaSeo &&
                                    resource.metaSeo.canonicalURL ? (
                                    <>
                                        <span className="badge bg-primary bg-opacity-10 text-primary rounded-pill ms-2">
                                        {"C"}
                                        </span>
                                        <span className="badge bg-light ms-1 text-dark fw-normal ">
                                        {resource.metaSeo.canonicalURL
                                            .split("-")
                                            .pop()}
                                        </span>
                                    </>
                                    ) : (
                                    ""
                                    )}
                                    {resource.metaSeo &&
                                    resource.metaSeo.redirectURL ? (
                                    <>
                                        <span className="badge bg-danger rounded-pill ms-2">
                                        {"R"}
                                        </span>
                                        <span className="badge bg-light ms-1 text-dark">
                                        {resource.metaSeo.redirectURL
                                            .split("-")
                                            .pop()}
                                        </span>
                                    </>
                                    ) : (
                                    ""
                                    )}
                                </div>
                                </div>
                                <div className="mx-1">
                                <input
                                        type="checkbox"
                                        value={resource._id}
                                        className="form-check-input"
                                        {...register(`resource_id.${index}`)}
                                        //name={resource._id}
                                        onChange={(e) => handleIdChange(e)}
                                        //checked={selectedId.includes(resource._id) ? 'checked' : ''}
                                        checked={
                                            selectedId.indexOf(resource._id.trim()) > -1
                                            ? true
                                            : false
                                        }
                                        />
                                </div>
                            </div>
                            </div>
                        );
                    })}
                    </>
                    }
                </div>
            </div>
            </>
          )}
       </AuthLayout>
    )
}

function mapStateToProps(state) {
    const { token } = state.auth;
  
    const { message, messageType } = state.message;
    return {
      token,
      message,
      messageType,
    };
  }
  
  export default connect(mapStateToProps)(CollectionEdit);
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import TimeAgo from "react-timeago";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
// import UserAPI from "../../api/userAPI";
import format from "../../helpers/format";
import Pagination from "../../components/Pagination";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
// import UserFilter from "../../components/User/UserFilter";
// import UserSorter from "../../components/User/UserSorter";
import Filter from "../../components/Filter/Filter";
import { Table, Dropdown } from "react-bootstrap";
import Sorter from "../../components/Sorter/Sorter";

import { setMessage } from "../../redux/actions/message";

import { API_URL, MEDIA_URL } from "../../configs";

const filters = [
  {
    type: "text",
    name: "s",
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "create_from",
    placeholder: "Create from",
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "create_to",
    placeholder: "Create to",
    defaultValue: "",
  },
  {
    type: "select",
    name: "status",
    options: [
      { title: "Status", value: "" },
      { title: "Enable", value: "0" },
      { title: "Disabled", value: "1" },
    ],
    defaultValue: "",
  },
];

const defaultFilter = filters.reduce(
  (obj, cur) => ({ ...obj, [cur.name]: cur.defaultValue }),
  {}
);

const initialQuery = {
  ...defaultFilter,
  role: "",
  created_from: "",
  created_to: "",
  sortby: "",
  direction: "",
  page: "",
};

const pageSize = 50;

function ContactList() {
  
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [contactData, setContactData] = useState([]);
  //const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1); //helper.useQueryString("page");
  const [query, setQuery] = useState(initialQuery);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckList = (e) => {
    // const { value, checked } = e.target;
    // if (checked) {
    //   const [filterItem] = contacts.filter((item) => item._id === value);
    //   setSelected((prev) => [
    //     ...prev,
    //     { _id: filterItem._id, disabled: filterItem.disabled },
    //   ]);
    // } else {
    //   setSelected((prev) => prev.filter((item) => item._id !== value));
    // }
  };

  const handleSelectAll = () => {
    // if (selectAll) {
    //   setSelected([]);
    //   setSelectAll(false);
    // } else {
    //   const listFileSelected = contacts.map((item) => ({
    //     _id: item._id,
    //     disabled: item.disabled,
    //   }));

    //   setSelected(listFileSelected);
    //   setSelectAll(true);
    // }
  };

  const handleBulkActive = async () => {
    const filterSelected = selected.filter((item) => item.disabled !== false);

    if (filterSelected.length) {
      await Promise.all(
        filterSelected.map(async (item) => handleActive(item._id))
      );
      setSelected([]);
      dispatch(
        setMessage(
          `Active ${filterSelected.length} user(s) successfully`,
          "success"
        )
      );
    }
  };

  const handleBulkDeactive = async () => {
    const filterSelected = selected.filter((item) => item.disabled !== true);

    if (filterSelected.length) {
      await Promise.all(
        filterSelected.map(async (item) => handleDeactive(item._id))
      );
      setSelected([]);
      dispatch(
        setMessage(
          `Deactive ${filterSelected.length} user(s) successfully`,
          "success"
        )
      );
    }
  };

  const handleActive = (userId) => {
    //updateStatusUser(dispatch, userId, { disabled: false });
  };

  const handleDeactive = (userId) => {
    //updateStatusUser(dispatch, userId, { disabled: true });
  };

  // handling filter reset function
  const handleReset = () => {
    setQuery(initialQuery);
  };

  const fetchContacts = async () => {
    //get resources report data
    //console.log(API_URL + `contacts/${fullQuery}`)
    await axios
      .get(API_URL + `contacts/`)
      .then((response) => {
        console.log(response.data)
        setContactData(response.data);
      })
      .catch((e) => {
        console.log(e);
    });
  };

  useEffect(() => {
    (async () => {
    const newQuery = Object.assign({}, query);

    if (searchParams.get("role")) {
      newQuery.role = searchParams.get("role");
    }

    Object.keys(defaultFilter).map((filter) => {
      if (searchParams.get(filter)) {
        newQuery[filter] = searchParams.get(filter);
      }
      return newQuery
    });

    if (searchParams.get("sortby")) {
      newQuery.sortby = searchParams.get("sortby");
    }
    if (searchParams.get("direction")) {
      newQuery.direction = searchParams.get("direction");
    }

    if (searchParams.get("page")) {
      newQuery.page = searchParams.get("page");
      setCurrentPage(searchParams.get("page"));
    } else {
      //fix initial page without page params
      newQuery.page = 1;
      setCurrentPage(1);
    }
    setQuery(newQuery);

    const fullQuery = Object.assign({}, newQuery);
    fullQuery.pageSize = pageSize;

    
    await fetchContacts(fullQuery);
    })();
  }, [location]);

  return (
    <AuthLayout>
      <header
        className={"d-md-flex d-sm-inline justify-content-between align-items-center mb-4"}
      >
        <h2>{"Contacts"}</h2>
        <div className="d-flex gap-2">
          <Link className={"btn btn-primary px-4"} to={`/contacts/new`}>
            {"Create"}
          </Link>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-success"
              className={"px-4"}
            >
              Action
            </Dropdown.Toggle>

            <Dropdown.Menu className="min-w-100">
              <Dropdown.Item as="button" onClick={() => handleBulkActive()}>
                Enable
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleBulkDeactive()}>
                Disabled
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      {/* <Search/> */}
      <div className="mb-3">
        <Filter
          defaultFilter={defaultFilter}
          filters={filters}
          reset={handleReset}
        />
        {/* <div className="col-md-10">
          <UserFilter
            currentFilter={query}
            filterHandle={filterHandle}
            resetHandle={handleReset}
          />
        </div>
        <div className="col-md-2 text-right">
          <UserSorter sorterHandle={sorterHandle} />
        </div> */}
      </div>
      {loading ? (
        <p>Loading</p>
      ) : (
        <>
          {contactData.length > 0 ? (
            <>
              <Table responsive className="mb-4">
                <thead>
                  <tr>
                    <th scope="col" className="fw-normal text-muted">
                      <div className="d-flex algin-items-end">
                        <button
                          className="btn py-0 px-2 text-muted"
                          title="Select All"
                          onClick={() => handleSelectAll()}
                        >
                          #
                        </button>
                      </div>
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="Email"
                        name="email"
                      />
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Subject
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Content
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="Date"
                        name="timestamp"
                      />
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="border-top-0">
                  {contactData.map((contact, index) => {
                    return(
                      <tr key={index}>
                        <td>
                        <input
                            type="checkbox"
                            value={contact._id}
                            className="form-check-input"
                            {...register(`contact_id.${index}`)}
                            // onChange={(e) => handleIdChange(e)}
                            // checked={
                            //   selectedId.indexOf(resource._id.trim()) > -1
                            //     ? true
                            //     : false
                            // }
                          />
                        </td>
                        <td>{contact.email}</td>
                        <td>{contact.subject}</td>
                        <td className="small text-muted">{contact.content}</td>
                        <td>
                            <Moment
                                format="YYYY/MM/DD HH:mm:ss"
                                className="small"
                              >
                              {contact.timestamp}
                            </Moment>
                            <br />
                            <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                              <i className="bi bi-clock me-1"></i>
                              <span className="">
                                <TimeAgo date={contact.timestamp} />
                              </span>
                            </span>
                        </td>
                        <td></td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              <Pagination
                // count={size}
                currentPage={currentPage}
                pageSize={pageSize}
                path={"contacts"}
                moduleName={"contacts"}
                query={query}
              />
            </>
          ) : (
            <p>{"Not found"}</p>
          )}
        </>
      )}
    </AuthLayout>
  );
}

export default ContactList;

import authHeader from "../helpers/header";
import apiInstance from "./api";

const getAllOrders = async (query) => {
  const res = await apiInstance.get(`orders?${query}`, {
    headers: authHeader(),
  });
  return res.data;
};

const getOrderDetail = async (id) => {
  const res = await apiInstance.get(`v1/orders/mine/${id}`, {
    headers: authHeader(),
  });
  return res.data;
};

const updateOrder = async (id, updateInfo) => {
  const res = await apiInstance.patch(`v1/orders/${id}`, updateInfo, {
    headers: authHeader(),
  });
  return res.data;
};

const OrderApi = {
  getAllOrders,
  getOrderDetail,
  updateOrder,
};

export default OrderApi;

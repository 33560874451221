import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { clearMessage } from "../../redux/actions/message";
import Topbar from "../Topbar/Topbar";
import styles from "./AuthLayout.module.css";

const AuthLayout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  const [show, setShow] = useState(true);

  const handleToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    dispatch(clearMessage());
    if (!token) {
      dispatch(logout());
      navigate("/login", { replace: true });
    }
  }, [location, token]);

  return (
    <div className="wrapper d-flex">
      <Sidebar show={show} />
      <Topbar show={show} handleToggle={handleToggle} />
      <div className={`${styles.mainContent} ${show ? styles.show : ""}`}>
        <main>{children}</main> {/* Main content */}
        {/* <footer className="position-absolute bottom-0 end-0 start-0 py-3 text-end">
          <span className="small text-muted me-4">{"Magipik 1.0"}</span>
        </footer> */}
      </div>
      {/* Content */}
    </div>
  );
};

export default AuthLayout;

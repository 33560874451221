import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { logout } from "../redux/actions/auth";
import { getLoggedInUser } from "../redux/actions/user";

const Protect = (props) => {
  const { token, user } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      try {
        dispatch(getLoggedInUser());
      } catch (e) {
        dispatch(logout());
        navigate("/login", { replace: true });
      }
    } else {
      navigate("/login", { replace: true });
    }
  }, [location]);

  return (
    <>
      <Outlet />
    </>
  );
};

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  return {
    token,
    user,
  };
}

export default connect(mapStateToProps)(Protect);

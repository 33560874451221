import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import UserAPI from "../../api/userAPI";
import { setMessage } from "../../redux/actions/message";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import format from "../../helpers/format";

import TimeAgo from "react-timeago";
import Moment from "react-moment";

import { FE_WEBSITE_URL } from "../../configs";

function UserDetail({ message, messageType }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [userStat, setUserStat] = useState([]);

  // call data fetching on first load
  useEffect(() => {

     // fetch user data via API
     async function fetchUserData (id) {
      setIsLoading(true);
      await UserAPI.getUser(id)
        .then((response) => {
          setUserData(response);
          setIsLoading(false);
        })
        .catch((e) => {
          dispatch(setMessage(`${e}`, "danger"));
        });
    };

    // fetch user data via API
    async function fetchCreatorStat(id) {
      setIsLoading(true);
      await UserAPI.getCreatorStat(id)
        .then((response) => {
          setUserStat(response);
          setIsLoading(false);
        })
        .catch((e) => {
          dispatch(setMessage(`${e}`, "danger"));
        });
    };
    fetchUserData(id);
    fetchCreatorStat(id);
  }, [id]);

  return (
    <AuthLayout>
      {isLoading ? (
        <>
          <p>loading</p>
        </>
      ) : (
        <>
          <header
            className={"d-md-flex d-sm-inline justify-content-between align-items-center mb-4"}
          >
            <h3>
              {userData.username}
              <a
                target="_blank"
                rel="noreferrer"
                href={`/resources/?created_by=${userData.username}`}
                className="ms-3 mx-1 small p-2 text-muted"
              >
                <i className="bi bi-grid fs-5"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${FE_WEBSITE_URL}creator/${userData.username}`}
                className="mx-1 small p-2 text-muted"
              >
                <i className="bi bi-link fs-5"></i>
              </a>
            </h3>
            <div>
              <Link
                className={"btn btn-primary"}
                to={`/users/edit/${userData._id}`}
              >
                Edit
              </Link>
              <button
                className="btn btn-outline-secondary ms-3"
                onClick={(e) => {}}
              >
                Go Back
              </button>
            </div>
          </header>
          {message && (
            <div className="mb-4">
              <div className={`alert alert-${messageType}`} role="alert">
                {message}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-6">
              <div className="mb-4">
                {userData.avatar ? (
                  <>
                    <img
                      className="rounded-circle"
                      src={`https://media.magipik.com/${userData.avatar}`}
                      width={120}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex border align-items-center justify-content-center rounded-circle bg-light text-secondary p-2"
                      style={{ width: 120, height: 120 }}
                    >
                      <i className="bi bi-person-fill fs-2"></i>
                    </div>
                  </>
                )}
              </div>
              <div className="mb-4">
                {userStat.length > 0 ? (
                  <>
                    <div className="mb-0">
                      <span className="text-muted me-4">
                        <i className="bi bi-file-arrow-up me-1"></i>
                        <span className="small">{userStat[0].totalUpload}</span>
                      </span>
                      <span className="text-muted me-4">
                        <i className="bi bi-eye me-1"></i>
                        <span className="small">{userStat[0].totalView}</span>
                      </span>
                      <span className="text-muted me-4">
                        <i className="bi bi-file-arrow-down me-1"></i>
                        <span className="small">
                          {userStat[0].totalDownload}
                        </span>
                      </span>
                      <span className="text-muted me-4">
                        <i className="bi bi-heart me-1"></i>
                        <span className="small">{userStat[0].totalLike}</span>
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <dl className="row mb-5">
                <dt className="col-sm-3 fw-normal text-muted mb-2">Roles</dt>
                <dd className="col-sm-9">
                  {userData.roles &&
                    userData.roles.length > 0 &&
                    userData.roles.map((role, i) => {
                      return role == "USER" ? (
                        <span
                          className="mr-2 badge bg-light fw-normal text-secondary text-uppercase"
                          key={i}
                        >
                          {role.toLowerCase()}
                          {i < userData.roles.length - 1 && ","}{" "}
                        </span>
                      ) : (
                        <span
                          className="mr-2 badge bg-dark bg-opacity-25 fw-normal text-dark text-uppercase"
                          key={i}
                        >
                          {role.toLowerCase()}
                          {i < userData.roles.length - 1 && ","}{" "}
                        </span>
                      );
                    })}
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">Status</dt>
                <dd className="col-sm-9">
                  {format.formatUserStatus(userData.disabled)}
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">Fullname</dt>
                <dd className="col-sm-9">
                  {userData.first_name} {userData.last_name}
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">E-mail</dt>
                <dd className="col-sm-9">
                  {userData.email.toLowerCase()}

                  {/* <i className="bi bi-check me-1"></i>
                  <span>{userData.email_confirmed_at ? 'Y' : 'N'}</span> */}
                  {userData.email_confirmed_at ? (
                    <span className="mx-2 badge rounded-pill bg-success bg-opacity-10 text-success fw-normal py-1 px-2">
                      <i className="bi bi-check text-sucess me-1"></i>
                    </span>
                  ) : (
                    <span className="mx-2 badge rounded-pill bg-warning bg-opacity-10 text-warning fw-normal py-1 px-2">
                      <i className="bi bi-x text-warning me-1 mt-1"></i>
                    </span>
                  )}
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">
                  Member since
                </dt>
                <dd className="col-sm-9">
                  <Moment format="YYYY-MM-DD HH:mm:ss">
                    {userData.created_at}
                  </Moment>
                  <span className="mx-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                    <i className="bi bi-clock me-1"></i>
                    <span className="">
                      <TimeAgo date={userData.created_at} />
                    </span>
                  </span>
                </dd>
                {userData.user_link && (
                  <>
                    <dt className="col-sm-3 fw-normal text-muted mb-2">
                      Social
                    </dt>
                    <dd className="col-sm-9">
                      {userData.user_link.facebook && (
                        <>
                          <a
                            href={`https://facebook.com/${userData.user_link.facebook}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {userData.user_link.facebook}
                          </a>
                          <br />
                        </>
                      )}
                      {userData.user_link.twitter && (
                        <>
                          <a
                            href={`https://x.com/${userData.user_link.twitter}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {userData.user_link.twitter}
                          </a>
                          <br />
                        </>
                      )}
                      {userData.user_link.instagram && (
                        <>
                          <a
                            href={`https://instagram.com/${userData.user_link.instagram}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {userData.user_link.instagram}
                          </a>
                          <br />
                        </>
                      )}
                      {userData.user_link.linkedin && (
                        <>
                          <a
                            href={`https://linkedin.com/${userData.user_link.linkedin}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {userData.user_link.linkedin}
                          </a>
                          <br />
                        </>
                      )}
                      {userData.user_link.youtube && (
                        <>
                          <a
                            href={`https://youtube.com/u/${userData.user_link.youtube}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {userData.user_link.youtube}
                          </a>
                          <br />
                        </>
                      )}
                      {userData.user_link.dribbble && (
                        <>
                          <a
                            href={`https://youtube.com/u/${userData.user_link.dribbble}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {userData.user_link.dribbble}
                          </a>
                          <br />
                        </>
                      )}
                      {userData.user_link.pinterest && (
                        <>
                          <a
                            href={`https://pinterest.com/${userData.user_link.pinterest}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {userData.user_link.pinterest}
                          </a>
                          <br />
                        </>
                      )}
                      {userData.user_link.website && (
                        <>
                          <a
                            href={`${userData.user_link.website}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {userData.user_link.website}
                          </a>
                          <br />
                        </>
                      )}
                    </dd>
                  </>
                )}
                <dt className="col-sm-3 fw-normal text-muted mb-2">Address</dt>
                <dd className="col-sm-9">
                  {userData.address && (
                    <>
                      {userData.address.street1 && (
                        <>
                          {userData.address.street1}
                          <br />
                        </>
                      )}
                      {userData.address.street2 && (
                        <>
                          {userData.address.street2}
                          <br />
                        </>
                      )}
                      {userData.address.city && (
                        <>
                          {userData.address.city}
                          <br />
                        </>
                      )}
                      {userData.address.state && (
                        <>
                          {userData.address.state}
                          <br />
                        </>
                      )}
                      {userData.address.country && (
                        <>
                          {userData.address.country}
                          <br />
                        </>
                      )}
                      {userData.address.zipcode && (
                        <>
                          {userData.address.zipcode}
                          <br />
                        </>
                      )}
                    </>
                  )}
                </dd>
              </dl>
            </div>{" "}
            {/* user detail */}
            <div className="col-md-6"></div>
          </div>
        </>
      )}
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  const { message, messageType } = state.message;
  return {
    token,
    user,
    message,
    messageType,
  };
}

export default connect(mapStateToProps)(UserDetail);

import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../redux/actions/message";

import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { MEDIA_URL, NODE_URL } from "../../configs";
import format from "../../helpers/format";
import FileAPI from "../../api/fileAPI";

const fetcher = (url) => axios.get(url).then((res) => res.data);

const ResourcesConvertFile = () => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const { message, messageType } = useSelector((state) => state.message);
  const [searchParams, setSearchParams] = useSearchParams();
  const [resourcesId, setResourcesId] = useState(
    searchParams.get("resources_id").split(",")
  );

  const {
    data: resources,
    isLoading: isLoadingResources,
    error: errorResources,
  } = useSWR(
    `${NODE_URL}/api/resources/bulk-data?resources_id=${resourcesId}`,
    fetcher
  );

  const {
    data: resourceFiles,
    isLoading: isLoadingResourceFiles,
    error: errorResourceFiles,
  } = useSWR(
    `${NODE_URL}/api/resource/bulk-data?resources_id=${resourcesId}`,
    fetcher
  );

  const order = { ai: 0, eps: 1, svg: 2 };
  const sortResourceFiles = resourceFiles?.sort(
    (a, b) => order[a.format] - order[b.format]
  );

  const checkFiles = sortResourceFiles?.reduce((acc, current) => {
    if (!acc[current.resourceId]) {
      acc[current.resourceId] = {
        hasAI: false,
        hasEPS: false,
        hasSVG: false,
      };
    }
    if (current.format === "ai") {
      acc[current.resourceId].hasAI = true;
    }
    if (current.format === "eps") {
      acc[current.resourceId].hasEPS = true;
    }
    if (current.format === "svg") {
      acc[current.resourceId].hasSVG = true;
    }

    return acc;
  }, {});

  const convertAItoEPS = async (resouceId) => {
    let data = {};
    try {
      const { data: fileEPS } = await FileAPI.convertAItoEPS(resouceId);
      data = {
        filename: fileEPS.filename,
        resourceId: resouceId,
        path: fileEPS.filepath,
        format: "eps",
        file_size: fileEPS.file_size,
      };
    } catch (error) {
      dispatch(
        setMessage(`${resouceId} - ${error.response.data.error}`, "danger")
      );
      return;
    }

    try {
      await FileAPI.createResourceFile(data);
      mutate(`${NODE_URL}/api/resource/bulk-data?resources_id=${resourcesId}`);
      dispatch(
        setMessage(`${resouceId} - Successfully converted AI to EPS`, "success")
      );
    } catch (error) {
      dispatch(setMessage(`Create resource file failed`, "danger"));
    }
  };
  const convertAItoSVG = async (resouceId) => {
    let data = {};
    try {
      const { data: fileSVG } = await FileAPI.convertAItoSVG(resouceId);
      data = {
        filename: fileSVG.filename,
        resourceId: resouceId,
        path: fileSVG.filepath,
        format: "svg",
        file_size: fileSVG.file_size,
      };
    } catch (error) {
      dispatch(
        setMessage(`${resouceId} - ${error.response.data.error}`, "danger")
      );
      return;
    }

    try {
      await FileAPI.createResourceFile(data);
      mutate(`${NODE_URL}/api/resource/bulk-data?resources_id=${resourcesId}`);
      dispatch(
        setMessage(`${resouceId} - Successfully converted AI to SVG`, "success")
      );
    } catch (error) {
      dispatch(setMessage(`Create resource file failed`, "danger"));
    }
  };

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h2>{"Convert Files"}</h2>
      </header>
      {message && (
        <div className="mb-4">
          <div className={`alert alert-${messageType}`} role="alert">
            {message}
          </div>
        </div>
      )}

      {!isLoadingResources &&
        !isLoadingResourceFiles &&
        !errorResourceFiles &&
        !errorResources && (
          <div>
            <div className="d-flex">
              {resources?.map((resource, index) => (
                <div key={index} className="px-2 w-20">
                  <div>
                    <LazyLoadImage
                      alt={resource.name}
                      src={`${MEDIA_URL}${resource.images[0].sizes[0].path}`}
                      height={160}
                      effect="blur"
                      className="img-preview"
                    />
                  </div>

                  <div className="mb-1">
                    <div className="my-2">
                      {format.formatResourceStatusNoText(resource.status)}
                    </div>
                    <Link to={`/resources/view/${resource._id}`}>
                      <strong className="rname small">{resource.name}</strong>
                    </Link>
                    <div className="small text-muted mt-2">{resource._id}</div>
                    <div className="my-2">
                      {sortResourceFiles?.map((file, fileIndex) => {
                        if (file.resourceId === resource._id) {
                          if (file.format === "ai") {
                            return (
                              <span
                                className="format format--ai p-3"
                                key={fileIndex}
                              >
                                <i className="bi bi-filetype-ai fs-4"></i>
                              </span>
                            );
                          }
                          if (file.format === "eps") {
                            return (
                              <span
                                className="format format--eps p-3"
                                key={fileIndex}
                              >
                                <i className="bi bi-files-alt fs-4"></i>
                              </span>
                            );
                          }
                          if (file.format === "svg") {
                            return (
                              <span
                                className="format format--svg p-3"
                                key={fileIndex}
                              >
                                <i className="bi bi-filetype-svg fs-4"></i>
                              </span>
                            );
                          }
                        }
                      })}
                    </div>
                    <div className="my-2">
                      {checkFiles[resource._id].hasAI &&
                        !checkFiles[resource._id].hasEPS && (
                          <button
                            className="btn btn-secondary mx-2"
                            type="button"
                            onClick={() => {
                              convertAItoEPS(resource._id);
                            }}
                          >
                            <i className="bi bi-cloud-upload me-1"></i>
                            <span>EPS</span>
                          </button>
                        )}
                      {checkFiles[resource._id].hasAI &&
                        !checkFiles[resource._id].hasSVG && (
                          <button
                            className="btn btn-secondary mx-2"
                            type="button"
                            onClick={() => {
                              convertAItoSVG(resource._id);
                            }}
                          >
                            <i className="bi bi-cloud-upload me-1"></i>
                            <span>SVG</span>
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      {(errorResources || errorResourceFiles) && (
        <div>Some thing went wrong!</div>
      )}
      {(isLoadingResources || isLoadingResourceFiles) && <div>Loading</div>}
    </AuthLayout>
  );
};

export default ResourcesConvertFile;

import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { registerUser } from "../../redux/actions/auth";
import { setMessage } from "../../redux/actions/message";

function Register({ message, messageType }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const password = useRef({});
  password.current = watch("password", ""); //use to check password matching

  const handleRegister = (data) => {
    dispatch(registerUser(data))
      .then(() => {
        dispatch(setMessage("You have registered successfully", "success"));
        reset();
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
  };

  return (
    <>
      <div
        className="mx-auto d-flex justify-content-center align-items-center"
        style={{ height: "90vh" }}
      >
        <div className="register-box px-5">
          <div className="text-center mb-5">
            <img src="/magipik_logo_black_sm.png" alt="Magipik Backend" />
          </div>
          <form onSubmit={handleSubmit(handleRegister)}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                E-mail
              </label>
              <input
                className="form-control py-2"
                type="text"
                placeholder={"Enter your email"}
                {...register("email", {
                  required: "Please enter an email",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
              />
              {errors.email && (
                <span className="small text-danger p-1">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                className="form-control py-2"
                type="password"
                placeholder={"Enter your password"}
                {...register("password", {
                  required: "You must specify a password",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
              />
              {errors.password && (
                <span className="small text-danger">
                  {errors.password.message}
                </span>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="form-label">
                Confirm your password
              </label>
              <input
                className="form-control py-2"
                type="password"
                placeholder={"Re-type your password"}
                {...register("password_repeat", {
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
              />
              {errors.password_repeat && (
                <span className="small text-danger">
                  {errors.password_repeat.message}
                </span>
              )}
            </div>
            {message && (
              <div className="mb-4">
                <div className={`alert alert-${messageType}`} role="alert">
                  {message}
                </div>
              </div>
            )}
            <div className="d-grid">
              <button type="submit" className="btn btn-primary px-5 py-2 mb-4">
                {"Register"}
              </button>
              <Link to={"/login"} className="btn btn-link ml-4 px-5">
                {"Login"}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const token = state.auth;
  const { message, messageType } = state.message;
  return {
    token,
    message,
    messageType,
  };
}

export default connect(mapStateToProps)(Register);

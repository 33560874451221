import apiStorage from "../api/storage";

export default function authHeader() {
  const access_token = apiStorage.getLocalAccessToken();

  if (access_token) {
    return { Authorization: "Bearer " + access_token };
  } else {
    return {};
  }
}

import {
  FETCH_USERS_FAILED,
  FETCH_USERS_PENDING,
  FETCH_USERS_SUCCESS,
  UPDATE_USERS_SUCCESS,
} from "../actions/types";

const initialState = {
  users: [],
  size: 0,
  userDetail: {},
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USERS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USERS_SUCCESS:
      // console.log(action.page)
      return {
        ...state,
        users: payload.items,
        size: payload.size,
        loading: false,
      };

    case FETCH_USERS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_USERS_SUCCESS:
      const index = state.users.findIndex((item) => item._id === payload._id);
      state.users[index].disabled = payload.disabled;
      return {
        ...state,
      };

    default:
      return state;
  }
};

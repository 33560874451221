import { useEffect, useState } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import TimeAgo from "react-timeago";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
// import UserAPI from "../../api/userAPI";
import format from "../../helpers/format";
import Pagination from "../../components/Pagination";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
// import UserFilter from "../../components/User/UserFilter";
// import UserSorter from "../../components/User/UserSorter";
import Filter from "../../components/Filter/Filter";
import { Table, Dropdown } from "react-bootstrap";
import Sorter from "../../components/Sorter/Sorter";

//import { MEDIA_URL, FE_WEBSITE_URL } from "../../configs";
import { fetchUsers, updateStatusUser } from "../../redux/actions/userList";
import { setMessage } from "../../redux/actions/message";

const filters = [
  {
    type: "text",
    name: "s",
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "create_from",
    placeholder: "Create from",
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "create_to",
    placeholder: "Create to",
    defaultValue: "",
  },
  {
    type: "select",
    name: "status",
    options: [
      { title: "Status", value: "" },
      { title: "Enable", value: "0" },
      { title: "Disabled", value: "1" },
    ],
    defaultValue: "",
  },
];

const defaultFilter = filters.reduce(
  (obj, cur) => ({ ...obj, [cur.name]: cur.defaultValue }),
  {}
);

const initialQuery = {
  ...defaultFilter,
  role: "",
  created_from: "",
  created_to: "",
  sortby: "",
  direction: "",
  page: "",
};

const pageSize = 50;

function UserList() {
  const dispatch = useDispatch();
  const { users, loading, size } = useSelector((state) => state.userList);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // const [loading, setLoading] = useState(false);
  // const [users, setUsers] = useState([]);
  // const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1); //helper.useQueryString("page");
  const [query, setQuery] = useState(initialQuery);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckList = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const [filterItem] = users.filter((item) => item._id === value);
      setSelected((prev) => [
        ...prev,
        { _id: filterItem._id, disabled: filterItem.disabled },
      ]);
    } else {
      setSelected((prev) => prev.filter((item) => item._id !== value));
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelected([]);
      setSelectAll(false);
    } else {
      const listFileSelected = users.map((item) => ({
        _id: item._id,
        disabled: item.disabled,
      }));

      setSelected(listFileSelected);
      setSelectAll(true);
    }
  };

  const handleBulkActive = async () => {
    const filterSelected = selected.filter((item) => item.disabled !== false);

    if (filterSelected.length) {
      await Promise.all(
        filterSelected.map(async (item) => handleActive(item._id))
      );
      setSelected([]);
      dispatch(
        setMessage(
          `Active ${filterSelected.length} user(s) successfully`,
          "success"
        )
      );
    }
  };

  const handleBulkDeactive = async () => {
    const filterSelected = selected.filter((item) => item.disabled !== true);

    if (filterSelected.length) {
      await Promise.all(
        filterSelected.map(async (item) => handleDeactive(item._id))
      );
      setSelected([]);
      dispatch(
        setMessage(
          `Deactive ${filterSelected.length} user(s) successfully`,
          "success"
        )
      );
    }
  };

  const handleActive = (userId) => {
    updateStatusUser(dispatch, userId, { disabled: false });
  };

  const handleDeactive = (userId) => {
    updateStatusUser(dispatch, userId, { disabled: true });
  };

  // const fetchUsers = async (query = "") => {
  //   setLoading(true); //@TODO
  //   dispatch()
  //   //get users data via API
  //   await UserAPI.getAllUsers(query)
  //     .then((response) => {
  //       console.log("response", response);
  //       setCount(response.size);
  //       setUsers(response.items);
  //       setLoading(false); //@TODO
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // handling filter reset function
  const handleReset = () => {
    setQuery(initialQuery);
  };

  useEffect(async () => {
    const newQuery = Object.assign({}, query);

    if (searchParams.get("role")) {
      newQuery.role = searchParams.get("role");
    }

    Object.keys(defaultFilter).map((filter) => {
      if (searchParams.get(filter)) {
        newQuery[filter] = searchParams.get(filter);
      }
      return newQuery
    });

    if (searchParams.get("sortby")) {
      newQuery.sortby = searchParams.get("sortby");
    }
    if (searchParams.get("direction")) {
      newQuery.direction = searchParams.get("direction");
    }

    if (searchParams.get("page")) {
      newQuery.page = searchParams.get("page");
      setCurrentPage(searchParams.get("page"));
    } else {
      //fix initial page without page params
      newQuery.page = 1;
      setCurrentPage(1);
    }
    setQuery(newQuery);

    const fullQuery = Object.assign({}, newQuery);
    fullQuery.pageSize = pageSize;
    await fetchUsers(dispatch, fullQuery);
  }, [location]);

  return (
    <AuthLayout>
      <header
        className={"d-md-flex d-sm-inline justify-content-between align-items-center mb-4"}
      >
        <h2>{"Users"}</h2>
        <div className="d-flex gap-2">
          <Link className={"btn btn-primary px-4"} to={`/users/new`}>
            {"Create"}
          </Link>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-success"
              className={"px-4"}
            >
              Action
            </Dropdown.Toggle>

            <Dropdown.Menu className="min-w-100">
              <Dropdown.Item as="button" onClick={() => handleBulkActive()}>
                Enable
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleBulkDeactive()}>
                Disabled
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      {/* <Search/> */}
      <div className="mb-3">
        <Filter
          defaultFilter={defaultFilter}
          filters={filters}
          reset={handleReset}
        />
        {/* <div className="col-md-10">
          <UserFilter
            currentFilter={query}
            filterHandle={filterHandle}
            resetHandle={handleReset}
          />
        </div>
        <div className="col-md-2 text-right">
          <UserSorter sorterHandle={sorterHandle} />
        </div> */}
      </div>
      {loading ? (
        <p>Loading</p>
      ) : (
        <>
          {users.length > 0 ? (
            <>
              <Table responsive className="mb-4">
                <thead>
                  <tr>
                    <th scope="col" className="fw-normal text-muted">
                      <div className="d-flex algin-items-end">
                        <button
                          className="btn py-0 px-2 text-muted"
                          title="Select All"
                          onClick={() => handleSelectAll()}
                        >
                          #
                        </button>
                      </div>
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Username
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="Full name"
                        name="first_name"
                      />
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="Email"
                        name="email"
                      />
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Country
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Roles
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="Created At"
                        name="created_at"
                      />
                    </th>
                    <th scope="col" className="fw-normal text-center text-muted">
                      Confirmed
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Status
                    </th>
                    <th scope="col" className="fw-normal text-muted text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="border-top-0">
                  {users.map((user, index) => {
                    return (
                      <tr key={index} className="align-middle text-center">
                        <td>
                          <input
                            type="checkbox"
                            value={user._id}
                            onChange={(e) => handleCheckList(e)}
                            checked={selected.some(
                              (item) => item._id === user._id
                            )}
                          />
                        </td>
                        <td className="text-start">
                          <div className="d-flex align-items-center justify-content-start">
                          {user.avatar ? (
                            <>
                              <img
                                className="rounded-circle my-1 me-3"
                                src={`https://media.magipik.com/${user.avatar}`}
                                alt={user.username}
                                width={40}
                              />
                            </>
                          ) : (
                            <>
                              <div
                                className="rounded-circle bg-light text-muted text-center p-2 me-3"
                                style={{ width: 40, height: 40 }}
                              >
                                <i className="bi bi-person-fill"></i>
                              </div>
                            </>
                          )}
                          <div>
                          <Link to={`/users/view/${user._id}`}>
                            <strong className="rname">{user.username}</strong>
                          </Link>
                          <p className="small text-muted mb-0 mt-1">
                              {user._id}
                            </p>
                          </div>
                          
                          </div>
                        </td>
                        <td className="text-start">
                          <span className="text-muted">
                            {user.first_name} {user.last_name}
                          </span>
                        </td>
                        <td className="text-start">{user.email}</td>
                        <td className="text-muted">
                          {user.address ? user.address.country : ""}
                        </td>
                        <td className="text-muted small">
                          {user.roles &&
                            user.roles.length > 0 &&
                            user.roles.map((role, i) => {
                              return role === "USER" ? (
                                <span
                                  className="mr-1 badge bg-light ms-1 fw-normal text-secondary text-uppercase"
                                  key={i}
                                >
                                  {role.toLowerCase()}
                                  {i < user.roles.length - 1 && ","}{" "}
                                </span>
                              ) : (
                                <span
                                  className="mr-1 badge bg-dark bg-opacity-25 ms-1 fw-normal text-dark text-uppercase"
                                  key={i}
                                >
                                  {role.toLowerCase()}
                                  {i < user.roles.length - 1 && ","}{" "}
                                </span>
                              );
                            })}
                        </td>
                        <td className="text-muted text-start">
                          <Moment
                            format="YYYY-MM-DD HH:mm:ss"
                            className="small"
                          >
                            {user.created_at}
                          </Moment>
                          <br />
                          <span className="me-2 my-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                            <i className="bi bi-clock me-1"></i>
                            <span className="">
                              <TimeAgo date={user.created_at} />
                            </span>
                          </span>
                          {/* {new Date(user.created_at).toDateString()} */}
                        </td>
                        <td className="text-center text-muted">
                          {user.email_confirmed_at ? (
                            <i className="bi bi-check text-sucess"></i>
                          ) : (
                            <i className="bi bi-x"></i>
                          )}
                        </td>
                        <td>{format.formatUserStatus(user.disabled)}</td>
                        <td>
                          <div className="d-flex align-items-center gap-2 small">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`/resources/?created_by=${user.username}`}
                              className="p-1 text-muted d-inline-flex"
                            >
                              <i className="bi bi-grid"></i>
                            </a>
                            <Link
                              to={`/users/edit/${user._id}`}
                              className="p-1 text-muted d-inline-flex"
                              title="Edit"
                            >
                              <i className="bi bi-pencil d-flex text-muted"></i>
                            </Link>
                            {user.disabled ? (
                              <button
                                className="btn p-1"
                                type="button"
                                title="Active"
                                onClick={() => handleActive(user._id)}
                              >
                                <i className="bi bi-check d-flex text-muted"></i>
                              </button>
                            ) : (
                              <button
                                className="btn p-1"
                                type="button"
                                title="Deactive"
                                onClick={() => handleDeactive(user._id)}
                              >
                                <i className="bi bi-trash2 d-flex text-muted"></i>
                              </button>
                            )}

                            {/* <Link
                              to={`/users/delete/${user._id}`}
                              className="btn btn-sm"
                            >
                              <i className="bi bi-trash-fill text-muted"></i>
                            </Link> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination
                count={size}
                currentPage={currentPage}
                pageSize={pageSize}
                path={"users"}
                moduleName={"users"}
                query={query}
              />
            </>
          ) : (
            <p>{"Not found"}</p>
          )}
        </>
      )}
    </AuthLayout>
  );
}

export default UserList;

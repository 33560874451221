import { useEffect, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

function ProfileView(props) {
  const { token, user } = props;
  const [profile, setProfile] = useState("");

  useEffect(() => {
    if (!token) {
      return <Navigate to="/login" />;
    }
    if (user) {
      setProfile(user);
    }
  }, [user]);

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h1>{"Profile"}</h1>
        <Link className={"btn btn-primary"} to={`/profile/settings`}>
          Edit
        </Link>
      </header>
      <div className="row">
        <div className="col-md-5">
          <dl className="row mb-5">
            <dt className="col-sm-3 fw-normal text-muted mb-2">UID</dt>
            <dd className="col-sm-9">{profile._id}</dd>
            <dt className="col-sm-3 fw-normal text-muted mb-2">Status</dt>
            <dd className="col-sm-9">
              {!profile.disabled ? (
                <span className="badge bg-success">{"Active"}</span>
              ) : (
                <span className="badge bg-danger">{"Disabled"}</span>
              )}
            </dd>
            <dt className="col-sm-3 fw-normal text-muted mb-2">Email</dt>
            <dd className="col-sm-9">{profile.email}</dd>
            <dt className="col-sm-3 fw-normal text-muted mb-2">Firstname</dt>
            <dd className="col-sm-9">{profile.first_name}</dd>
            <dt className="col-sm-3 fw-normal text-muted mb-2">Lastname</dt>
            <dd className="col-sm-9">{profile.last_name}</dd>
            <dt className="col-sm-3 fw-normal text-muted mb-2">Bio</dt>
            <dd className="col-sm-9">{profile.bio}</dd>
            <dt className="col-sm-3 fw-normal text-muted mb-2">Registered</dt>
            <dd className="col-sm-9">
              {new Date(profile.created_at).toDateString()}
            </dd>
          </dl>
        </div>
      </div>
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  return {
    token,
    user,
  };
}

export default connect(mapStateToProps)(ProfileView);

import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import Search from "../../components/Search";
//import Filter from "./Filter";
import Pagination from "../../components/Pagination";
import Limit from "../../components/Limit";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import FileAPI from "../../api/fileAPI";
import helper from "../../helpers/query";

// import {
//   LOAD_DATA,
//   FETCH_RESOURCES_SUCCESS,
//   APPLY_TAG_FILTER,
//   HOME_PAGE_LOADED,
//   ADD_FETCHED_DATA,
//   SET_PARAM
// } from "../../actions/types";

import { history } from "../../redux/store";

const initialQuery = {
  status: "",
  pageSize: 4,
  page: "",
};

function PaymentList(props) {
  // const location = useLocation();
  // const [searchParams, setSearchParams] = useSearchParams();

  // const [loading, setLoading] = useState(false)
  // const [files, setFiles] = useState([])
  const [pageSize, setPageSize] = useState(initialQuery.pageSize);
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1); //helper.useQueryString("page");
  // const [query, setQuery] = useState(initialQuery)

  // const loadPage = (params) => {
  //   let q = helper.arrayToQueryString(params)
  //   setSearchParams(params)

  //   history.push({
  //     pathname: "/files",
  //     search: `?${q}`,
  //     state: params
  //   })
  // }

  // const fetchFiles = async (query = '') => {
  //   setLoading(true) //@TODO
  //   //get files data via API
  //   await FileAPI.getAllFiles(query).then((response) => {
  //     setCount(response.size)
  //     setFiles(response.items)
  //     setLoading(false) //@TODO
  //     console.log(response.items)
  //   }).catch((e) => {
  //     console.log(e);
  //   });
  // }

  // const handlePageChange = (page) => {
  //   //console.log(query)
  //   let params = {}
  //   if (query) {
  //     if (query.status) {
  //       params.status = query.status
  //     }
  //   }
  //   params.page = page
  //   loadPage(params)
  // }

  // const filterHandle = (data) => {
  //   console.log(location)
  //   let params = {}

  //   if (data.status) {
  //     params.status = data.status
  //   }
  //   params.page = 1
  //   loadPage(params)
  // }

  // {/* handling filter reset function */ }
  // const handleReset = () => {
  //   setQuery(initialQuery)
  //   loadPage({ 'page': 1 })
  // }

  // useEffect(async () => {
  //   const newQuery = Object.assign({}, query);

  //   if (searchParams.get("status")) {
  //     newQuery.status = searchParams.get("status")
  //   }

  //   if (searchParams.get("page")) {
  //     newQuery.page = searchParams.get("page")
  //     setCurrentPage(searchParams.get("page"))
  //   }

  //   setQuery(newQuery)
  //   await fetchFiles(newQuery)
  //   console.log(newQuery)

  // }, [location])

  useEffect(() => {
    // console.log(query)
    // console.log(count)
    // console.log(pageSize)
  }, [currentPage, count, pageSize]);

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h2>{"Payments"}</h2>
        <Link className={"btn btn-primary px-4"} to={`/payments/new`}>
          {"Create"}
        </Link>
      </header>
      {/* <Search/> */}
      {/* <Filter currentFilter={query} filterHandle={filterHandle} resetHandle={handleReset} /> */}
      <p className="text-center py-5">{"Updating..."}</p>
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  return {
    token,
    user,
  };
}

export default connect(mapStateToProps)(PaymentList);

import { Link } from "react-router-dom";
import styles from "./Sidebar.module.css";

const itemArr = [
  {
    href: "/",
    icon: <i className="bi bi-speedometer2"></i>,
    text: "Home",
  },
  {
    href: "/resources",
    icon: <i className="bi bi-layers"></i>,
    text: "Resources",
  },
  {
    href: "/files",
    icon: <i className="bi bi-archive"></i>,
    text: "Files",
  },
  {
    href: "/orders",
    icon: <i className="bi bi-box2"></i>,
    text: "Orders",
  },
  {
    href: "/payments",
    icon: <i className="bi bi-wallet"></i>,
    text: "Payments",
  },
  {
    href: "/users",
    icon: <i className="bi bi-people"></i>,
    text: "Users",
  },
  {
    href: "/reports",
    icon: <i className="bi bi-graph-up-arrow"></i>,
    text: "Reports",
  },
  // {
  //   href: "/scan-ai",
  //   icon: <i className="bi bi-database-fill"></i>,
  //   text: "Scan Ai Files",
  // },
];

const Sidebar = ({ show }) => {
  return (
    <div
      className={`sidebar ${styles.sidebar} ${show ? 'show' : ''}`}
      // style={{ width: show ? "200px" : "60px" }}
    >
      <div
        className={`${styles.wrapper} sidebar-content`}
        // style={{ width: show ? "200px" : "60px" }}
      >
        <div className={styles.logoWrapper}>
          <Link to="/" className={`w-100 `}>
            <img
              src="/magipik_m_logo_black.svg"
              alt="Magipik Backend"
              className={styles.logoImg}
            />
          </Link>
        </div>
        <ul className="nav flex-column mt-4 mb-auto">
          {itemArr.map((item, index) => (
            <li className="nav-item" key={index}>
              <Link to={item.href} className={styles.itemLink}>
                <span className="me-3">{item.icon}</span>
                <span
                  className={`item-name ${
                    show ? "d-block opacity-1000" : "d-none opacity-0"
                  }`}
                >
                  {item.text}
                </span>
              </Link>
            </li>
          ))}

          {/* <li className="nav-item">
          <Link to="/invoices" className={styles.itemLink}>
          
            {"Invoices"}
          </Link>
        </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { history } from "../redux/store";
import helper from "./query";

export const useLoadPage = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  return (params) => {
    let q = helper.arrayToQueryString(params);
    setSearchParams(params);
    history.push({
      pathname: location.pathname,
      search: `?${q}`,
      state: params,
    });
  };
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLoadPage } from "../helpers/useLoadPage";

const Pagination = ({
  pageSize,
  count,
  currentPage,
  path,
  moduleName,
  query,
}) => {
  const loadPage = useLoadPage();
  const [pageRage, setPageRange] = useState([]);
  const getPageRange = (count, pageSize) => {
    const range = [];
    const totalPage = Math.ceil(count / pageSize);
    for (let i = 1; i <= totalPage; i++) {
      range.push(i);
    }
    return range;
  };

  const handlePageChange = (page) => {
    let params = {};
    if (query) {
      Object.keys(query).map((item) => {
        if (query[item]) {
          params[item] = query[item];
        }
        return query
      });
    }
    params.page = page;
    loadPage(params);
  };

  const goToPage = (e, page) => {
    e.preventDefault();
    handlePageChange(page);
  };

  useEffect(() => {
    let r = getPageRange(count, pageSize);
    setPageRange(r);
  }, [count, pageSize]);

  return (
    <>
      <div className="d-md-flex d-sm-inline justify-content-between">
        <div className="my-4">
          <span className="badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
            {count} {moduleName}
          </span>
        </div>
        {count > pageSize && (
          <>
            <div className="text-right">
              {/* previous page*/}
              {
                <Link
                  className={`btn btn-default px-3 rounded-pill border-0 ${
                    currentPage === 1 && "disabled"
                  }`}
                  to={`${path}?page=${parseInt(currentPage) - 1}`}
                  onClick={(e) => goToPage(e, parseInt(currentPage) - 1)}
                >
                  <i className="bi bi-chevron-left"></i>
                </Link>
              }
              {/* page list*/}
              {pageRage.map((page, index) => {
                if (parseInt(page) === parseInt(currentPage)) {
                  return (
                    <Link
                      className={"btn btn-default bg-dark text-white px-3 rounded-pill"}
                      key={index}
                      to={`${path}?page=${page}`}
                      onClick={(e) => goToPage(e, page)}
                    >
                      <strong>{page}</strong>
                    </Link>
                  );
                } else {
                  // reduce number of display page
                  if (
                    page < parseInt(currentPage) + 3 &&
                    page > parseInt(currentPage) - 3
                  ) {
                    return (
                      <Link
                        className={"btn btn-default px-3 rounded-pill"}
                        key={index}
                        to={`${path}?page=${page}`}
                        onClick={(e) => goToPage(e, page)}
                      >
                        {page}
                      </Link>
                    );
                  }
                }
              })}
              {/* next page*/}
              {
                <Link
                  className={`btn btn-default px-3 rounded-pill ${
                    pageRage && currentPage === pageRage.length
                      ? "disabled"
                      : ""
                  }`}
                  to={`${path}?page=${parseInt(currentPage) + 1}`}
                  onClick={(e) => goToPage(e, parseInt(currentPage) + 1)}
                >
                  <i className="bi bi-chevron-right"></i>
                </Link>
              }
            </div>
          </>
        )}
      </div>
    </>
  );
};

// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     onSetPage: (page, payload, tags) => { dispatch({ type: LOAD_DATA, page, payload, tags }) }
//   }
// }
//export default connect(null, mapDispatchToProps)(Pagination);
export default Pagination;

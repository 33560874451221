import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { API_URL } from "../../configs";

const ReportTags = () => {
  const [searchParams] = useSearchParams(); // Getting query parameters
  const [tags, setTags] = useState([]);     // Store tags and their counts
  const [totalCount, setTotalCount] = useState(0);  // Total number of tags
  const [error, setError] = useState(null);  // Handle any errors
  const [page, setPage] = useState(1);       // Track the current page
  const limit = 50;                          // Items per page
  const offset = (page - 1) * limit;         // Calculate offset based on current page
  const [tagFilter, setTagFilter] = useState('');   // Filter tags by name

  // Fetch tags based on current query params, tag name filter, and pagination
  useEffect(() => {
    async function fetchTagsData() {
      try {
        const response = await axios.get(`${API_URL}reports/tags`, {
          params: {
            tag_name: tagFilter,  // Apply filter if any
            limit: limit,
            offset: offset
          }
        });

        if (response.status === 200) {
          setTags(response.data.data);  // Set the tags data from API
          setTotalCount(response.data.total);  // Set total count from API response
        } else {
          setTags([]);
          setTotalCount(0);
        }
      } catch (err) {
        console.error("Error fetching tags:", err);
        setError("Failed to fetch tags. Please try again.");
      }
    }

    fetchTagsData();
  }, [tagFilter, page]); // Refetch tags whenever tagFilter or page changes

  // Handle page navigation
  const handleNextPage = () => {
    if (offset + limit < totalCount) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / limit);

  // Handle filtering by tag name
  const handleTagFilterChange = (event) => {
    setTagFilter(event.target.value);
    setPage(1);  // Reset to the first page when filter changes
  };

  return (
    <AuthLayout>
    <header className={"d-flex justify-content-between align-items-center mb-4"}>
        <h2>{"Tags Report"}</h2>
        <Link 
            to={`/reports/tagReplace`} 
            rel="noreferrer"
            className="btn btn-secondary me-3"
        >
            Replace tag
        </Link>
    </header>
    <div>
      {error && <div className="error">{error}</div>}

      {/* Filter input */}
      <div>
        <input
          type="text"
          className="form-control"
          placeholder="Filter by tag name"
          value={tagFilter}
          onChange={handleTagFilterChange}
        />
      </div>

      {/* Display tags */}
      {tags.length > 0 ? (
        <ul className="my-4">
          {tags.map((tag, index) => (
            <li key={index}>
              {tag._id} - <span className='text-muted'>{tag.count} resources</span>
            </li>
          ))}
        </ul>
      ) : (
        <p>No tags found</p>
      )}

      {/* Show total records and total pages */}

      {/* Pagination controls */}
      <div className="pagination">
          <button className="btn btn-light btn-sm me-2" onClick={handlePreviousPage} disabled={page === 1}>
          Previous
          </button>
          <span className="py-3 me-2">Page {page} of {totalPages}</span>
          <button className="btn btn-light btn-sm me-2" onClick={handleNextPage}disabled={page >= totalPages}>
          Next
          </button>
          <span className="text-muted py-3 me-2">Total : {totalCount}</span>
          <span className="text-muted py-3 me-2">No of Pages : {totalPages}</span>
      </div>
    </div>
    </AuthLayout>
  );
};

export default ReportTags;

import React from "react";
import ReactDOM from "react-dom/client"; // Updated import for React 18
import { Provider } from "react-redux";
//import WebFont from "webfontloader";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { store, history } from "../src/redux/store";
import setupAPI from "../src/api/setup";
// import { ConnectedRouter } from 'connected-react-router';

/* Fix : ResizeObserver loop completed with undelivered notifications
https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications
*/

// Updated to use createRoot for React 18
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container); // Use createRoot for React 18

root.render(
  <Provider store={store}>
    {/* <BrowserRouter location={history.location} navigator={history}> */}
    <BrowserRouter history={history}>
      <App />
    </BrowserRouter>
  </Provider>
);

setupAPI(store);

// WebFont.load({
//   google: {
//     families: ["Inter:wght@100;200;300;400;500;600;700;800;900", "sans-serif"],
//   },
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import { setMessage } from "../../redux/actions/message";
import UserAPI from "../../api/userAPI";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

function UserCreate({ message, messageType }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);

  const onSubmit = async (data) => {
    data.disabled = data.status == "1" ? false : true;
    setIsLoading(true);

    await UserAPI.createUser(data)
      .then((response) => {
        setIsLoading(false);
        dispatch(setMessage("Create user success fully", "success"));
      })
      .catch((e) => {
        console.log(e);
        dispatch(setMessage(`${e}`, "danger"));
      });
  };
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h1>{"Create"}</h1>
        <button className="btn btn-outline-secondary" onClick={(e) => {}}>
          Go Back
        </button>
      </header>
      {message && (
        <div className="mb-4">
          <div className={`alert alert-${messageType}`} role="alert">
            {message}
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="mb-3 col-md-6">
            <label htmlFor="email" className="form-label">
              E-mail
            </label>
            <input
              className="form-control"
              placeholder={"Enter a valid e-mail address"}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="small text-danger">This field is required</span>
            )}
          </div>

          <div className="mb-3 col-md-3">
            <label htmlFor="status" className="form-label">
              Status
            </label>
            <select
              className="form-select"
              defaultValue={"1"}
              {...register("status")}
            >
              <option value="1">Enable</option>
              <option value="0">Disable</option>
            </select>
          </div>
          <div className="mb-3 col-md-3">
            <label htmlFor="roles" className="form-label">
              Roles
            </label>
            <select
              className="form-select"
              defaultValue={"USER"}
              {...register("roles")}
            >
              <option value="ADMIN">Admin</option>
              <option value="STAFF">Staff</option>
              <option value="USER">User</option>
            </select>
          </div>

          <div className="mb-3 col-md-6">
            <label htmlFor="first_name" className="form-label">
              First name
            </label>
            <input
              className="form-control"
              placeholder={"First name"}
              {...register("first_name")}
            />
            {errors.first_name && (
              <span className="small text-danger">This field is required</span>
            )}
          </div>

          <div className="mb-3 col-md-6">
            <label htmlFor="last_name" className="form-label">
              Last name
            </label>
            <input
              className="form-control"
              placeholder={"Last name"}
              {...register("last_name")}
            />
            {errors.last_name && (
              <span className="small text-danger">This field is required</span>
            )}
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </form>
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  const { message, messageType } = state.message;
  return {
    token,
    user,
    message,
    messageType,
  };
}

export default connect(mapStateToProps)(UserCreate);

import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import message from "./message";
import userList from "./userList";
import files from "./files";
//import resources  from "./resourceList";
//import { connectRouter } from 'connected-react-router';
import {
  createRouterMiddleware,
  createRouterReducer,
  push,
  ReduxRouter,
  ReduxRouterSelector,
} from "@lagunovsky/redux-react-router";
// export default combineReducers({
//   auth,
//   message,
//   resources,
//   //pager
//   //paginations
//   //resourceList
// });

//import { history} from '../store';

export default (history) =>
  combineReducers({
    auth,
    user,
    message,
    userList,
    files,
    //resources,
    router: createRouterReducer(history),
  });

import axios from "axios";
//import authHeader from "../../helpers/header";
import AWS from 'aws-sdk';
import fileSaver from 'file-saver';

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import format from "../../helpers/format";


import { API_URL, MEDIA_URL, S3_KEY, S3_SECRET, S3_REGION, S3_BUCKET } from "../../configs";

  
function ResourcesProcess(props) {
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const [downloadCount, setDownloadCount] = useState(0);
    const [data, setData] = useState([]);

    const fileDownloadHandler = async (data) => {

        let tmpCount = 0;
        const s3 = new AWS.S3();

        s3.config.update({
            accessKeyId: S3_KEY,
            secretAccessKey: S3_SECRET,
            region: S3_REGION,
            endpoint: `s3-${S3_REGION}.amazonaws.com`,
            signatureVersion: "v4",
        });

        for (var i = 0; i < data.length; i++) {
            //console.log(data[i].files)
            if(data[i].files.length) {
                for (const file of data[i].files) {
                    console.log(file)
                    // console.log(file.aws_path)
                    const params = { Bucket: S3_BUCKET, Key: file.aws_path };

                    // Get signed URL for each file
                    const url = await new Promise((resolve, reject) => {
                      s3.getSignedUrl('getObject', params, (err, url) => {
                        if (err) {
                          return reject(err); // Handle error in generating URL
                        }
                        resolve(url);
                      });
                    });
              
                    // Download the file as a blob
                    const res = await axios.get(url, { responseType: 'blob' });
              
                    // Create a blob and save it to disk
                    const blob = new File([res.data], file.filename);
                    fileSaver.saveAs(blob);
                    tmpCount++;
                }
            }
        }
        setDownloadCount(tmpCount)
    }

    const handleDownload = (item)=>{
        fileDownloadHandler(item)
    }

    useEffect(() => {

        let ids = searchParams.get('resources_id')
        let format = searchParams.get('format')

        async function getResourcesFileData(ids) {
            //create api request url
            let requestUrl = API_URL + `files/getbulk/?ids=${ids}`
            
            //check if format request and create new request
            if(format) {
                requestUrl += `&format=${format}`
            }

            //get response from api
            const res = await axios.get(requestUrl)

            console.log(requestUrl)
            if(res.status === 200){
                setData(res.data)
            }
        }
        getResourcesFileData(ids)
    }, [])
    

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Download resource files"}</h2>
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                        handleDownload(data)
                    }}
                  >
                    Download {data.length} files 
                </button>
            </header>
            <div>
                {data && 
                <div className="row">
                    {data.map((data, index) => {
                        return(
                            <div key={index} className="col-md-2">
                                <p className="text-muted text-center mt-2 mb-1 py-0">
                                    <Link to={`/resources/view/${data.resourceId}`} target="_blank" rel="noreferrer">
                                        <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data.resourceId}</span>
                                    </Link>
                                </p>
                                
                                {data.files &&
                                <>
                                {data.files.map((file, i) => {
                                    return(
                                        <p key={i} className="text-muted text-center mb-2 py-0">
                                            <span key={i} className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2 text-uppercase">
                                            {file.format}
                                            </span>
                                            <span className="me-2 pe-3 text-capitalize">
                                                <i className="bi bi-app-indicator me-2"></i>
                                                {file.file_size > 0 ? (
                                                <>
                                                    <span className="small fw-semibold">
                                                    {format.formatBytes(file.file_size)}
                                                    </span>
                                                </>
                                                ) : (
                                                <>
                                                    <span
                                                    className="bg-danger d-inline-flex rounded-circle ml-auto"
                                                    style={{ width: "10px", height: "10px" }}
                                                    ></span>
                                                </>
                                                )}
                                            </span>
                                        </p>
                                    )
                                })}
                                </>
                                }
                            </div>
                        )
                    })
                    }
                </div>
                }
                {downloadCount > 0 &&
                    <div className={`alert alert-success py-2 my-4`} role="alert">{`${downloadCount} file is downloaded`}</div>
                }
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ResourcesProcess);
      
import { useLoadPage } from "../../helpers/useLoadPage";

const Sorter = ({ defaultFilter, query, title, name }) => {
  const loadPage = useLoadPage();
  const sortData = () => {
    let params = {};

    Object.keys(defaultFilter).map((filter) => {
      if (query[filter]) {
        params[filter] = query[filter];
      }
    });

    params.sortby = name;
    if (query.direction === "asc") {
      params.direction = "desc";
    } else {
      params.direction = "asc";
    }
    params.page = 1;
    loadPage(params);
  };
  return (
    <div className="d-flex align-items-center">
      <span className="me-2">{title}</span>
      <span className="cursor--pointer" onClick={() => sortData()}>
        {query.sortby === name ? (
          query.direction === "desc" ? (
            <i className="bi bi-sort-down fs-6"></i>
          ) : (
            <i className="bi bi-sort-up-alt fs-6"></i>
          )
        ) : (
          <i className="bi bi-arrow-down-up"></i>
        )}
      </span>
    </div>
  );
};

export default Sorter;

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import FileAPI from "../../api/fileAPI";
import Moment from "react-moment";
import TimeAgo from "react-timeago";
import { Link } from "react-router-dom";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import ResourceFiles from "../../components/Resource/Files";

import {
  S3_KEY,
  S3_REGION,
  S3_SECRET,
  S3_ENDPOINT,
  S3_BUCKET,
  MEDIA_URL,
} from "../../configs";
import AWS from "aws-sdk";
import fileSaver from "file-saver";
import axios from "axios";
import format from "../../helpers/format";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../redux/actions/message";

const FilesDetail = () => {
  const { id } = useParams();
  const [file, setFile] = useState({});
  const [status, setStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [relatedFile, setRelatedFile] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { message, messageType } = useSelector((state) => state.message);

  const fetchData = async () => {
    await FileAPI.getFileById(id)
      .then((res) => {
        setFile(res.data);
        setStatus(res.data.status);
      })
      .catch((err) => console.error(err));
    setLoading(false);
  };

  const fetchRelatedFiles = async () => {
    await FileAPI.getFilesSameResources(id)
      .then((response) => {
        setRelatedFile(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const downloadS3 = (filepath, filename) => {
    const s3 = new AWS.S3();
    s3.config.update({
      accessKeyId: S3_KEY,
      secretAccessKey: S3_SECRET,
      region: S3_REGION,
      endpoint: S3_ENDPOINT,
      signatureVersion: "v4",
    });

    const params = { Bucket: S3_BUCKET, Key: filepath };
    s3.getSignedUrl("getObject", params, function (err, url) {
      axios.get(`${url}`, { responseType: "blob" }).then((res) => {
        const blob = new File([res.data], `${filename}`);
        fileSaver.saveAs(blob);
      });
    });
  };

  useEffect(() => {
    fetchData();
    fetchRelatedFiles();
  }, [id]);

  const removeFile = async () => {
    setIsSubmitting(true);
    await FileAPI.deleteFile(file._id)
      .then((response) => {
        if (response.status === 204) {
          setStatus("deleted");
          dispatch(setMessage(`File ${file.filename} was deleted`, "success"));
        }
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
    setIsSubmitting(false);
  };

  const activeFile = async () => {
    setIsSubmitting(true);
    await FileAPI.updateFile(file._id, { status: "active" })
      .then((response) => {
        if (response.status === 204) {
          setStatus("active");
          dispatch(
            setMessage(`File ${file.filename} was activated`, "success")
          );
        }
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
    setIsSubmitting(false);
  };

  return (
    <AuthLayout>
      <div>
        {loading ? (
          <div>Loading...</div>
        ) : Object.keys(file).length ? (
          <>
            <div className="row">
              <h3 className="col-12 mb-4">{file?.filename}</h3>
              {message && (
                <div className="mb-4">
                  <div className={`alert alert-${messageType}`} role="alert">
                    {message}
                  </div>
                </div>
              )}
              <div className="col-6">
                <h6 className="col-12 mb-3">File detail</h6>
                <dl className="row">
                  <dt className="col-3 fw-normal text-muted">Status</dt>
                  <dd className="col-9">{format.formatStatus(status)}</dd>
                  <dt className="col-3 fw-normal text-muted">Storage</dt>
                  <dd className="col-9">
                    {file.aws_path && (
                      <button
                        type="button"
                        className="me-2 ps-0 border-0 bg-transparent"
                        onClick={() => {
                          downloadS3(file.aws_path, file.filename);
                        }}
                      >
                        <span className="badge bg-warning fw-normal">
                          {"S3"}
                        </span>
                      </button>
                    )}
                    {file.path && (
                      <a
                        href={`${MEDIA_URL}${file.path}`}
                        className="me-2"
                        alt={file.filename}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="badge bg-dark fw-normal">
                          {"Local"}
                        </span>
                      </a>
                    )}
                  </dd>
                  <dt className="col-3 fw-normal text-muted">Resource</dt>
                  <dd className="col-9">
                    <Link to={`/resources/view/${file.resourceId}`}>
                      {file.resourceId}
                    </Link>
                  </dd>
                  <dt className="col-3 fw-normal text-muted">File size</dt>
                  <dd className="col-9 fw-normal">
                    {file.file_size > 0 ? (
                      <>
                        <span className="small fw-semibold">
                          {format.formatBytes(file.file_size)}
                        </span>
                      </>
                    ) : (
                      <>
                        <div
                          className="bg-danger d-inline-flex rounded-circle ml-auto"
                          style={{ width: "14px", height: "14px" }}
                        ></div>
                      </>
                    )}
                  </dd>
                  <dt className="col-3 fw-normal text-muted">Format</dt>
                  <dd className="col-9 text-uppercase fw-semibold">
                    {file?.format}
                  </dd>
                  <dt className="col-3 fw-normal text-muted">Created at</dt>
                  <dd className="col-9">
                    <Moment format="YYYY-MM-DD hh:mm:ss">
                      {new Date(file.created_at)}
                    </Moment>
                    <span className="ms-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                      <i className="bi bi-clock me-1"></i>
                      <span className="">
                        <TimeAgo date={file.created_at} />
                      </span>
                    </span>
                  </dd>
                  <dt className="col-3 fw-normal text-muted">Download</dt>
                  <dd className="col-9">
                    {file?.stats && file.stats.downloaded.length}
                  </dd>
                </dl>
              </div>
              <div className="col-6">
                {file?.metaData && (
                  <>
                  <h6 className="col-12 mb-3">Metadata</h6>
                  <div className="row">
                    <dt className="col-3 fw-normal text-muted">Document title</dt>
                    <dd className="col-9">{file.metaData.documentTitle}</dd>
                    <dt className="col-3 fw-normal text-muted mb-2">Author(s)</dt>
                    <dd className="col-9">
                      {file.metaData.author?.map((item, index) => {
                        if (index < file.metaData.author.length - 1) {
                          return (
                            <span key={index} className="pe-1">
                              {item},
                            </span>
                          );
                        } else {
                          return <span key={index}>{item}</span>;
                        }
                      })}
                    </dd>
                    {file.metaData.authorTitle && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Author Title</dt>
                        <dd className="col-9">{file.metaData.authorTitle}</dd>
                      </>
                    )}
                    {file.metaData.copyrightInfoURL && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Copyright Info URL</dt>
                        <dd className="col-9">
                          {file.metaData.copyrightInfoURL}
                        </dd>
                      </>
                    )}
                    {file.metaData.copyrightNotice && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Copyright Notice</dt>
                        <dd className="col-9">
                          {file.metaData.copyrightNotice}
                        </dd>
                      </>
                    )}
                    {file.metaData.copyringStatus && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Copyring Status</dt>
                        <dd className="col-9">
                          {file.metaData.copyringStatus}
                        </dd>
                      </>
                    )}
                    {file.metaData.description && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Description</dt>
                        <dd className="col-9">{file.metaData.description}</dd>
                      </>
                    )}
                    {file.metaData.descriptionWriter && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Description writer</dt>
                        <dd className="col-9">
                          {file.metaData.descriptionWriter}
                        </dd>
                      </>
                    )}
                    {file.metaData.format && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Format</dt>
                        <dd className="col-9">{file.metaData.format}</dd>
                      </>
                    )}
                    {file.metaData.rating && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Rating</dt>
                        <dd className="col-9">{file.metaData.rating}</dd>
                      </>
                    )}
                    {file.metaData.creationDate && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Creation date</dt>
                        <dd className="col-9">
                          <Moment format="YYYY-MM-DD hh:mm:ss">
                            {file.metaData.creationDate}
                          </Moment>
                        </dd>
                      </>
                    )}
                    {file.metaData.modificationDate && (
                      <>
                        <dt className="col-3 fw-normal text-muted">Modification date</dt>
                        <dd className="col-9">
                          <Moment format="YYYY-MM-DD hh:mm:ss">
                            {file.metaData.modificationDate}
                          </Moment>
                        </dd>
                      </>
                    )}
                    {file.metaData.instanceID && (
                      <>
                        <dt className="col-3">InstanceID</dt>
                        <dd className="col-9">{file.metaData.instanceID}</dd>
                      </>
                    )}
                    {file.metaData.derivedFromInstanceID && (
                      <>
                        <dt className="col-3">DerivedFromInstanceID</dt>
                        <dd className="col-9">
                          {file.metaData.derivedFromInstanceID}
                        </dd>
                      </>
                    )}
                    {file.metaData.historyAction && (
                      <>
                        <dt className="col-3">HistoryAction</dt>
                        <dd className="col-9">{file.metaData.historyAction}</dd>
                      </>
                    )}
                    {file.metaData.historyInstanceID && (
                      <>
                        <dt className="col-3">HistoryInstanceID</dt>
                        <dd className="col-9">
                          {file.metaData.historyInstanceID}
                        </dd>
                      </>
                    )}
                    {file.metaData.historyWhen && (
                      <>
                        <dt className="col-3">HistoryWhen</dt>
                        <dd className="col-9">{file.metaData.historyWhen}</dd>
                      </>
                    )}
                  </div>
                  </>
                )}
              </div>
            </div>
            <div className="mt-4">
              {status === "deleted" && (
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => activeFile()}
                  disabled={isSubmitting}
                >
                  Active
                </button>
              )}
              {status === "active" && (
                <button
                  className="btn btn-secondary"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => removeFile()}
                >
                  Disable
                </button>
              )}
            </div>
            <div className="row mt-5">
              {relatedFile && relatedFile.length > 0 && (
                <>
                  <h6 className="mb-4">Other files from same resources</h6>
                  <ResourceFiles resourceFiles={relatedFile} />
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <p>{"Not found"}</p>
          </>
        )}
      </div>
    </AuthLayout>
  );
};

export default FilesDetail;

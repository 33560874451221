import {
  FETCH_FILES_FAILED,
  FETCH_FILES_PENDING,
  FETCH_FILES_SUCCESS,
  UPDATE_FILES_SUCCESS,
} from "./types";
import FileAPI from "../../api/fileAPI";

export const fetchFiles = async (dispatch, query) => {
  dispatch({ type: FETCH_FILES_PENDING });

  try {
    const res = await FileAPI.getAllFiles(query);
    dispatch({ type: FETCH_FILES_SUCCESS, payload: res });
  } catch (error) {
    console.log("err", error);
    dispatch({ type: FETCH_FILES_FAILED });
  }
};

export const updateFile = async (dispatch, fileId, statusObj) => {
  // dispatch({ type: UPDATE_USERS_PENDING });
  try {
    await FileAPI.updateFile(fileId, statusObj);
    dispatch({
      type: UPDATE_FILES_SUCCESS,
      payload: { ...statusObj, _id: fileId },
    });
  } catch (error) {
    console.log("err", error);
    // dispatch({ type: UPDATE_USERS_FAILED });
  }
};

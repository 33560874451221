import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

function Filter({ filterHandle, resetHandle }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      status: searchParams.get("status") || "",
      orderType: searchParams.get("orderType") || "",
    },
  });

  const onSubmit = (data) => {
    if (data.status || data.orderType) {
      filterHandle(data);
    }
  };

  // useEffect(() => {
  //   if (searchParams.get("status")) {
  //     setValue("status", searchParams.get("status")); //@TODO check currentFilter
  //   }
  // }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row g-3 mb-4">
      <div className="col-auto">
        <select className="form-select" {...register("status")}>
          <option value="">Select status</option>
          <option value="SUCCESS">Success</option>
          <option value="PENDING">Pending</option>
          <option value="CANCEL">Cancel</option>
          <option value="FALSE">False</option>
        </select>
      </div>
      <div className="col-auto">
        <select className="form-select" {...register("orderType")}>
          <option value="">Select order type</option>
          <option value="subscription">Subscription</option>
          <option value="payment">Payment</option>
        </select>
      </div>
      <div className="col-auto">
        <button type="submit" className="btn btn-secondary me-2">
          {"Find"}
        </button>
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={() => {
            reset({
              status: "",
              orderType: "",
            });
            resetHandle();
          }}
        >
          {"Reset"}
        </button>
      </div>
    </form>
  );
}

export default Filter;

import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Moment from "react-moment";
import TimeAgo from "react-timeago";

// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";

import { useForm } from "react-hook-form";
import { TagsInput } from "react-tag-input-component";
import ResourceAPI from "../../api/resourceAPI";
import CollectionAPI from "../../api/collectionAPI";
// import ResourceFilter from "../../components/Resource/ResourceFilter";
// import Sorter from "../../components/Resource/ResourceSorter";
import format from "../../helpers/format";
import {
  MEDIA_URL,
  PROMPT_ALL,
  PROMPT_DESC,
  PROMPT_KEYWORDS,
  PROMPT_TITLE_TAGS,
} from "../../configs";
import Pagination from "../../components/Pagination";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
//import PreviewImage from "../../components/Resource/PreviewImage";
import { setMessage } from "../../redux/actions/message";
//import { Table } from "react-bootstrap";
import Filter from "../../components/Filter/Filter";
import Sorter from "../../components/Sorter/Sorter";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { removeExtraSpaces } from "../../helpers/removeExtraSpaces";

const filters = [
  {
    type: "text",
    name: "q",
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "created_from",
    placeholder: "Created date from",
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "created_to",
    placeholder: "Created date to",
    defaultValue: "",
  },
  {
    type: "select",
    name: "preview",
    options: [
      { title: "Preview", value: "" },
      { title: "Yes", value: "yes" },
      { title: "No", value: "no" },
    ],
    defaultValue: "",
  },
  {
    type: "select",
    name: "status",
    options: [
      { title: "Select status", value: "" },
      { title: "Reject", value: "reject" },
      { title: "Schedule", value: "schedule" },
      { title: "Active", value: "active" },
      { title: "Missing", value: "missing" },
      { title: "Pending", value: "pending" },
      { title: "Draft", value: "draft" },
      { title: "Deleted", value: "deleted" },
    ],
    defaultValue: "",
  },
];

const defaultFilter = filters.reduce(
  (obj, cur) => ({ ...obj, [cur.name]: cur.defaultValue }),
  {}
);

const initialQuery = {
  ...defaultFilter,
  sortby: "",
  direction: "",
  page: "",
};

const pageSize = 48;

const exclude_tags = [
  "of",
  "a",
  "an",
  "the",
  "or",
  "for",
  "any",
  "some",
  "with",
  "these",
  "to",
  "your",
  "you",
  "that",
  "this",
  "types",
  "type",
  "best",
  "use",
  "other",
  "free",
  "view",
  "are",
  "is",
  "new",
  "different",
  "vector",
  "and",
];

function ResourceGrid(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { message, messageType } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); //helper.useQueryString("page");
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState(initialQuery);
  const [fullQuery, setFullQuery] = useState({ ...initialQuery, pageSize });
  const [selectedTags, setSelectedTags] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState();
  //const [categories, setCategories] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingTile, setIsGeneratingTitle] = useState(false);
  const [isGeneratingDesc, setIsGeneratingDesc] = useState(false);
  const [isGeneratingKeywords, setIsGeneratingKeywords] = useState(false);

  const [mergeImage, setMergeImage] = useState("");
  const [mergeImageBase64, setMergeImageBase64] = useState("");

  //Collection form
  const [showCollection, setShowCollection] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    //formState: { errors },
  } = useForm();
  const watchTitle = watch("new_name", false);
  const watchDescription = watch("description", false);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = async () => {
    if (selectedId.length > 0) {
      setShowEdit(true);

      if (!mergeImageBase64) {
        try {
          const res = await ResourceAPI.getMergeImgeBase64({
            resourcesId: selectedId,
          });
          if (res.data?.image) setMergeImageBase64(res.data.image);
        } catch (error) {
          console.log("error", error);
          dispatch(setMessage(`${error.response?.data?.error}`, "danger"));
        }
      }

      // if (!watch("new_name") && !watch("new_tags") && !isGenerating) {
      //   const selectedResources = resources.filter((resource) =>
      //     selectedId.includes(resource._id)
      //   );
      //   setIsGenerating(true);
      //   try {
      //     const data = {
      //       resources: selectedResources,
      //       prompt: PROMPT_ALL,
      //     };
      //     const res = await ResourceAPI.generateResourcesInfoWithOpenai(data);

      //     if (res?.data?.title) setValue("new_name", res.data.title);

      //     if (res?.data?.tags) {
      //       setValue("new_tags", res.data?.tags.join(","));
      //       setSelectedTags(res.data?.tags);
      //     }
      //     if (res.data?.description)
      //       setValue("description", res.data.description);
      //     if (res.data?.keywords) setValue("new_keywords", res.data.keywords);
      //     if (res.data?.image) setMergeImageBase64(res.data.image);

      //     setIsGenerating(false);
      //   } catch (error) {
      //     console.log("err", error);
      //     if (error?.response?.data?.image) {
      //       setMergeImageBase64(error.response.data?.image);
      //     }
      //     dispatch(setMessage(`${error.response?.data?.error}`, "danger"));
      //     setIsGenerating(false);
      //   }
      // }
    } else {
      dispatch(setMessage("Please select an item", "danger"));
    }
  };

  const handleCloseCollection = () => setShowCollection(false);
  const handleShowCollection = () => {
    if (selectedId.length > 0) {
      setShowCollection(true);
    } else {
      dispatch(setMessage("Please select an item", "danger"));
    }
  };

  /**
   *
   * @param {string} tag
   */
  const fetchRelatedTags = async (tag) => {
    await ResourceAPI.getRelatedTag(tag)
      .then((response) => {
        //add new tags to suggested tags
        setSuggestedTags((current) => [...current, ...response.data]);
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
  };

  /**
   * Add tag
   * @param {array} tag
   */
  const handleEditTags = async (tag) => {
    //get new tag to get related
    let new_tag = tag.filter((x) => !selectedTags.includes(x));
    //process if paste value with (,)
    if (new_tag[0] && new_tag[0].split(",")) {
      //remove new_tag from tag
      tag.splice(tag.indexOf(new_tag), 1);
      //append splited value to tag
      let tmp = new_tag[0].split(",");
      tmp.map((element) => {
        //Add only none exist tag
        if (tag.indexOf(element) === -1) {
          tag.push(element);
          fetchRelatedTags(element);
        }
        return tag;
      });
    } else {
      //get related tag
      if (new_tag !== "") {
        fetchRelatedTags(new_tag[0]);
      }
    }
    //set selected tag state
    setSelectedTags(tag);
    //process tags input value
    let tag_values = getValues("new_tags").split(",");

    tag.map((t) => {
      // console.log(t.length)
      if (t !== "" && !tag_values.includes(t)) {
        //avoid adding empty value
        if (getValues("new_tags").length > 0) {
          tag_values.push(t);
        } else {
          tag_values = [t];
        }
      }
      return tag_values;
    });
    //set tags value
    setValue("new_tags", tag_values.join(","));
  };

  /**
   * Add tag
   * @param {array} tag
   */
  const handleCollectionTags = async (tag) => {
    //get new tag to get related
    let new_tag = tag.filter((x) => !selectedTags.includes(x));
    //process if paste value with (,)
    if (new_tag[0] && new_tag[0].split(",")) {
      //remove new_tag from tag
      tag.splice(tag.indexOf(new_tag), 1);
      //append splited value to tag
      let tmp = new_tag[0].split(",");
      tmp.map((element) => {
        //Add only none exist tag
        if (tag.indexOf(element) === -1) {
          tag.push(element);
          fetchRelatedTags(element);
        }
        return tag;
      });
    } else {
      //get related tag
      if (new_tag !== "") {
        fetchRelatedTags(new_tag[0]);
      }
    }
    //set selected tag state
    setSelectedTags(tag);
    //process tags input value
    let tag_values = getValues("collection_tags").split(",");

    tag.map((t) => {
      // console.log(t.length)
      if (t !== "" && !tag_values.includes(t)) {
        //avoid adding empty value
        if (getValues("collection_tags").length > 0) {
          tag_values.push(t);
        } else {
          tag_values = [t];
        }
      }
      return tag_values;
    });
    //set tags value
    setValue("collection_tags", tag_values.join(","));
  };

  /**
   * Remove tag
   * @param {string} tag
   */
  const handleRemoveTag = (tag) => {
    let tag_values = getValues("new_tags").split(",");
    let index = tag_values.indexOf(tag);
    if (index > -1) {
      //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
      tag_values.splice(index, 1);
    }
    setValue("new_tags", tag_values.join(","));
    //rebuild suggested tags list
    suggestedTags.map((tg, i) => {
      //if (tg._id.includes(tag)) {
      if (tg._id.indexOf(tag) !== -1) {
        setSuggestedTags((prevState) =>
          prevState.filter((prevItem) => prevItem !== tg)
        );
      }
      return suggestedTags;
    });
  };

  /**
   * Remove tag
   * @param {string} tag
   */
  const handleRemoveCollectionTag = (tag) => {
    let tag_values = getValues("collection_tags").split(",");
    let index = tag_values.indexOf(tag);
    if (index > -1) {
      //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
      tag_values.splice(index, 1);
    }
    setValue("collection_tags", tag_values.join(","));
    //rebuild suggested tags list
    suggestedTags.map((tg, i) => {
      //if (tg._id.includes(tag)) {
      if (tg._id.indexOf(tag) !== -1) {
        setSuggestedTags((prevState) =>
          prevState.filter((prevItem) => prevItem !== tg)
        );
      }
      return suggestedTags;
    });
  };

  /**
   * add suggested tag to selected
   * @param {string} tag
   */
  const addSugggestTag = (tag) => {
    if (selectedTags.indexOf(tag) === -1) {
      //add tagg to selected tags
      setSelectedTags((current) => [...current, tag]);
      //remove added tag from suggestedTags
      if (suggestedTags.length > 0) {
        suggestedTags.map((tg, i) => {
          if (tg._id === tag) {
            setSuggestedTags((current) =>
              current.filter((item, itemIndex) => itemIndex !== i)
            );
          }
          return suggestedTags;
        });
      }
    }
  };
  /**
   * create new collection for selected resource
   * @param {form} data
   */
  const createCollection = async (data) => {
    data.resource_ids = selectedId;
    await CollectionAPI.createNewCollection(data)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          dispatch(
            setMessage(
              `Collection "${data["collection_name"]}" with ID ${response.data._id}  has been created for ${selectedId.length} resources`,
              "success"
            )
          );
          fetchResources(query);
          reset();
          setShowCollection(false);
          setSuggestedTags([]);
          setSelectedTags([]);
          setSelectedId([]);
        }
      })
      .catch((e) => {
        dispatch(
          setMessage(
            `There was an error white create "${data["collection_name"]}" collection`,
            "danger"
          )
        );

        setIsLoading(false);
      });
  };
  /**
   * bulk update resource
   * @param {form} data
   */
  const bulkResourceSubmit = async (data) => {
    data.resource_ids = selectedId;

    await ResourceAPI.bulkUpdateResource(data)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          dispatch(
            setMessage(`${selectedId.length} resources  was updated`, "success")
          );
          fetchResources(query);
          setSelectedId([]);
          reset();
          setMergeImage("");
          setMergeImageBase64("");
          setShowEdit(false);
          setSuggestedTags([]);
          setSelectedTags([]);
        }
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
        setIsLoading(false);
      });
  };

  const handleIdChange = (e) => {
    if (selectedId.length === 0) {
      setSelectedId([...selectedId, e.target.value]);
    } else {
      let index = selectedId.indexOf(e.target.value);
      if (index === -1) {
        setSelectedId((selectedId) => [...selectedId, e.target.value]);
      } else {
        selectedId.splice(index, 1);
        setSelectedId([...selectedId]);
      }
    }
  };

  const handleCheckAll = () => {
    if (selectedId.length === 0) {
      resources.map((resource) => {
        setSelectedId((selectedId) => [...selectedId, resource._id]);
        return selectedId;
      });
    } else {
      setSelectedId([]);
    }
  };

  const generateTags = (e) => {
    let tags = [];
    //check if name is not empty
    if (e.target.value.trim() !== "") {
      let list = e.target.value.split(" ");

      for (let i = 0; i < list.length; i++) {
        //exclude unuse words
        let new_tag = list[i].trim().replace(/[^\w\s]/gi, "");
        //exclude tag with <= chars
        if (
          !exclude_tags.includes(list[i].trim()) &&
          tags.indexOf(new_tag) < 0 &&
          new_tag.length > 2
        ) {
          tags.push(new_tag);
          fetchRelatedTags(new_tag);
        }
      }
      if (tags.length > 0) {
        setValue("new_tags", tags.join(","));
        setSelectedTags(tags);
      }
    }
  };

  const generateCollectionTags = (e) => {
    let tags = [];
    //check if name is not empty
    if (e.target.value.trim() !== "") {
      let list = e.target.value.split(" ");

      for (let i = 0; i < list.length; i++) {
        //exclude unuse words
        let new_tag = list[i].trim().replace(/[^\w\s]/gi, "");
        //exclude tag with <= chars
        if (
          !exclude_tags.includes(list[i].trim()) &&
          tags.indexOf(new_tag) < 0 &&
          new_tag.length > 2
        ) {
          tags.push(new_tag);
          fetchRelatedTags(new_tag);
        }
      }
      if (tags.length > 0) {
        setValue("collection_tags", tags.join(","));
        setSelectedTags(tags);
      }
    }
  };

  const bulkDownloadImages = async (data) => {
    if (selectedId.length > 0) {
      window.open(
        `/resources/processImage?resources_id=${selectedId.join(",")}`,
        "_blank"
      );
    }
    // await ResourceAPI.getCategories()
    //   .then((response) => {
    //     setCategories(response.data);
    //   })
    //   .catch((e) => {
    //     dispatch(setMessage(`${e}`, "danger"));
    //   });
  };

  const convertFiles = async () => {
    if (selectedId.length > 0) {
      window.open(
        `/resources/convert-file/?resources_id=${selectedId.join(",")}`,
        "_blank"
      );
    }
  };

  const fetchResources = async (query = "") => {
    setIsLoading(true); //@TODO
    //get users data via API
    await ResourceAPI.getAllResources(query)
      .then((response) => {
        setCount(response.size);
        setResources(response.items);
        setIsLoading(false); //@TODO
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
        setIsLoading(false); //@TODO
      });
  };

  const removeResource = async (id) => {
    setIsLoading(true);
    await ResourceAPI.removeResource(id)
      .then((response) => {
        if (response.status === 204) {
          setIsLoading(false);
          dispatch(setMessage(`Resource ${id} was deleted`, "success"));
          fetchResources(query);
        }
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
        setIsLoading(false);
      });
  };
  /**
   * check text lengh
   * @param {str} text
   * @param {int} min
   * @param {int} max
   * @returns html message
   */
  const checkLength = (text, min, max) => {
    if (text.length > min && text.length < max) {
      return `<span className="badge bg-success mx-1">${text.length}</span>`;
    } else {
      return `<span className="badge bg-warning mx-1">${text.length}</span>`;
    }
  };

  const handleReset = () => {
    setQuery(initialQuery);
    setFullQuery({ ...initialQuery, pageSize });
  };

  useEffect(() => {
    (async () => {
      // await fetchCategories();
      const newQuery = Object.assign({}, query);

      Object.keys(defaultFilter).map((filter) => {
        if (searchParams.get(filter)) {
          newQuery[filter] = searchParams.get(filter);
        }
        return newQuery;
      });

      if (!searchParams.get("q")) {
        newQuery["q"] = "";
      }
      if (searchParams.get("sortby")) {
        newQuery.sortby = searchParams.get("sortby");
      }
      if (searchParams.get("direction")) {
        newQuery.direction = searchParams.get("direction");
      }
      if (searchParams.get("created_by")) {
        newQuery.created_by = searchParams.get("created_by");
      }

      if (searchParams.get("page")) {
        newQuery.page = searchParams.get("page");
        setCurrentPage(searchParams.get("page"));
      } else {
        //fix initial page without page params
        newQuery.page = 1;
        setCurrentPage(1);
      }
      setQuery(newQuery);

      const fullQuery = Object.assign({}, newQuery);
      fullQuery.pageSize = pageSize;

      setFullQuery(fullQuery);
      await fetchResources(fullQuery);

      //reset selected id on location change
      setSelectedId([]);
    })();
  }, [location]);

  // useEffect(() => {
  //   console.log(selectedId);
  // }, [selectedId]);

  const copyImageToClipboard = () => {
    if (!mergeImageBase64) return;

    // Tạo một phần tử canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = `data:image/png;base64,${mergeImageBase64}`;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      // Chuyển đổi canvas sang Blob và sao chép vào clipboard
      canvas.toBlob((blob) => {
        const item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]);
        // alert("Image copied to clipboard!");
      });
    };
  };

  const generateResourceTitleAndTags = async () => {
    if (mergeImageBase64) {
      setIsGeneratingTitle(true);
      const data = {
        imageBase64: mergeImageBase64,
        prompt: PROMPT_TITLE_TAGS,
      };
      await ResourceAPI.regenerateWithOpenai(data)
        .then((res) => {
          let tags = [];
          if (res.data?.title) {
            setValue("new_name", res.data.title);
            let list = res.data.title.split(" ");

            for (let i = 0; i < list.length; i++) {
              //exclude unuse words
              let new_tag = list[i].trim().replace(/[^\w\s]/gi, "");
              //exclude tag with <= chars
              if (
                !exclude_tags.includes(list[i].trim()) &&
                tags.indexOf(new_tag) < 0 &&
                new_tag.length > 2
              ) {
                tags.push(new_tag);
                fetchRelatedTags(new_tag);
              }
            }
          }
          if (res.data?.tags) {
            const arrTags = res.data.tags.split(",");
            arrTags.map((tag) => {
              const text = removeExtraSpaces(tag);
              tags.push(text);
            });

            setValue("new_tags", tags.join(","));
            setSelectedTags(tags);
          }
        })
        .catch((e) => {
          dispatch(setMessage(`${e.response?.data?.error}`, "danger"));
        });
      setIsGeneratingTitle(false);
    }
  };

  const generateResourcesDesc = async () => {
    const title = getValues("new_name");
    const prompt = PROMPT_DESC.replaceAll("THE_TITLE_RESOURCE", title);

    if (mergeImageBase64 && title) {
      setIsGeneratingDesc(true);
      const data = {
        imageBase64: mergeImageBase64,
        prompt,
      };
      await ResourceAPI.regenerateWithOpenai(data)
        .then((res) => {
          if (res.data?.description)
            setValue("description", res.data.description);
        })
        .catch((e) => {
          dispatch(setMessage(`${e.response?.data?.error}`, "danger"));
        });
      setIsGeneratingDesc(false);
    }
  };

  const generateResourcesKeywords = async () => {
    // const title = getValues("new_name");
    const title = getValues("new_name");
    const prompt = PROMPT_KEYWORDS.replaceAll("THE_TITLE_RESOURCE", title);

    if (title && prompt) {
      if (mergeImageBase64) {
        setIsGeneratingKeywords(true);
        const data = {
          imageBase64: mergeImageBase64,
          prompt,
        };
        await ResourceAPI.regenerateWithOpenai(data)
          .then((res) => {
            if (res.data?.keywords) setValue("new_keywords", res.data.keywords);
          })
          .catch((e) => {
            dispatch(setMessage(`${e.response?.data?.error}`, "danger"));
          });
        setIsGeneratingKeywords(false);
      }
    }
  };

  return (
    <AuthLayout>
      <header
        className={
          "d-md-flex d-sm-inline justify-content-between align-items-center mb-4"
        }
      >
        <h2>{"Resouces"}</h2>
        <div className="d-flex align-self-end">
          <Link className={"btn btn-primary px-4 me-2"} to={`/resources/new`}>
            <i className="bi bi-file-arrow-up me-2 text-white"></i>
            {"Create"}
          </Link>
          <Dropdown>
            <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
              <i className="bi bi-check"></i>
              <span className="ms-2">Action</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleShowEdit}>Edit</Dropdown.Item>
              <Dropdown.Item onClick={handleShowCollection}>
                Create collection
              </Dropdown.Item>
              <Dropdown.Item onClick={convertFiles}>Convert file</Dropdown.Item>
              <Dropdown.Item onClick={bulkDownloadImages}>
                Download images
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {}}>Export data</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <a
            className={"btn btn-outline-success px-4 ms-2"}
            onClick={handleShowEdit}
          >
            {"Action"}
          </a> */}
          {/* <Button className="btn btn-outline-success px-4 ms-2" onClick={handleShowEdit}>
        Toggle static offcanvas
      </Button> */}
          <Offcanvas
            className="shadow"
            show={showCollection}
            onHide={handleCloseCollection}
            backdrop={false}
            scroll={true}
            placement={"end"}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className="px-4">
                Create a collection
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <form onSubmit={handleSubmit(createCollection)} className={"p-4"}>
                <div className="mb-3">
                  <label
                    htmlFor="new_name"
                    className="form-label sr-only text-muted"
                  >
                    Collection name
                  </label>
                  <input
                    id="collection_name"
                    type={"text"}
                    className="form-control"
                    defaultValue={""}
                    placeholder={"Enter new name"}
                    {...register("collection_name")}
                    onBlur={(event) => {
                      generateCollectionTags(event);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="tags" className="form-label sr-only">
                    Tags
                  </label>
                  <input
                    id="collection_tags"
                    className="form-control"
                    defaultValue={""}
                    hidden
                    placeholder={"Enter tags"}
                    //onChange={generateTags}
                    {...register("collection_tags")}
                  />
                  <TagsInput
                    value={selectedTags}
                    onChange={handleCollectionTags}
                    onRemoved={handleRemoveCollectionTag}
                    name="input-tags"
                    placeHolder="Enter tags"
                  />
                  {suggestedTags ? (
                    <div className="mt-2 tags-lbox">
                      {suggestedTags.map((tg, i) => {
                        return (
                          <span
                            key={i}
                            className="badge bg-white border rounded me-2 text-dark fw-normal fs-6 my-1 py-2 px-3 suggested-tag"
                            onClick={() => {
                              addSugggestTag(tg._id);
                            }}
                          >
                            {tg._id}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="d-grid gap-2">
                  <input
                    value={selectedId}
                    type={"hidden"}
                    {...register("collection_resource_ids")}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    Create collection
                  </button>
                </div>
              </form>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas
            className="shadow"
            show={showEdit}
            onHide={handleCloseEdit}
            backdrop={false}
            scroll={true}
            placement={"end"}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className="px-4">Update data</Offcanvas.Title>
              {mergeImageBase64 && (
                <button
                  className="btn btn-outline-secondary"
                  onClick={(e) => copyImageToClipboard()}
                >
                  Copy Image
                </button>
              )}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <form
                onSubmit={handleSubmit(bulkResourceSubmit)}
                className={"p-4"}
              >
                <div className="">
                  <div className="mb-3">
                    <label
                      htmlFor="new_name"
                      className="form-label sr-only text-muted d-flex align-items-center"
                    >
                      <span>Resource name</span>
                      <button
                        type="button"
                        onClick={(e) => generateResourceTitleAndTags()}
                        className="ms-2 btn btn-light btn-xs me-2"
                        disabled={
                          isGenerating || isGeneratingTile || !mergeImageBase64
                        }
                      >
                        <i className="bi bi-activity"></i>
                      </button>
                      {(isGenerating || isGeneratingTile) && (
                        <div className="loader"></div>
                      )}
                    </label>
                    <input
                      id="new_name"
                      type={"text"}
                      className="form-control"
                      defaultValue={""}
                      placeholder={"Enter new name"}
                      {...register("new_name")}
                      disabled={isGenerating || isGeneratingTile}
                      onChange={(evt) => {
                        //onInputChange(evt.target.value)}
                        //console.log(evt.target.value)
                      }}
                      onBlur={(event) => {
                        if (!watch("new_tags")) generateTags(event);
                        setValue("new_name", event.target.value);
                      }}
                    />
                    <div className="text-muted text-sm small mt-1">
                      {"Title should be 30 to 65 characters"}
                      {/* {getValues("meta_title").length} */}
                      {watchTitle && (
                        <div
                          className="d-inline"
                          dangerouslySetInnerHTML={{
                            __html: checkLength(watchTitle, 30, 65),
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="tags" className="form-label sr-only">
                      Tags
                    </label>
                    <input
                      id="tags"
                      className="form-control"
                      defaultValue={""}
                      hidden
                      placeholder={"Enter tags"}
                      //onChange={generateTags}
                      {...register("new_tags")}
                      disabled={isGenerating || isGeneratingTile}
                    />
                    <TagsInput
                      disabled={isGenerating || isGeneratingTile}
                      value={selectedTags}
                      onChange={handleEditTags}
                      onRemoved={handleRemoveTag}
                      name="tags"
                      placeHolder="Enter tags"
                    />
                    {suggestedTags ? (
                      <div className="mt-2 tags-lbox">
                        {suggestedTags.map((tg, i) => {
                          return (
                            <span
                              key={i}
                              className="badge bg-white border rounded me-2 text-dark fw-normal fs-6 my-1 py-2 px-3 suggested-tag"
                              onClick={() => {
                                addSugggestTag(tg._id);
                              }}
                            >
                              {tg._id}
                            </span>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-12 mb-4">
                      <label htmlFor="new_date" className="form-label sr-only">
                        Created at
                      </label>
                      <input
                        id="new_date"
                        type={"date"}
                        className="form-control"
                        defaultValue={""}
                        placeholder={"Enter new date"}
                        {...register("new_date")}
                      />
                    </div>
                    <div className="col-md-8 mb-4">
                      <label htmlFor="status" className="form-label sr-only">
                        Status
                      </label>
                      <select
                        id="status"
                        className="form-select"
                        {...register("new_status")}
                      >
                        <option value="">Status</option>
                        <option value="draft">Draft</option>
                        <option value="reject">Reject</option>
                        <option value="schedule">Schedule</option>
                        <option value="pending">Pending</option>
                        <option value="missing">Missing</option>
                        <option value="active">Active</option>
                        <option value="deleted">Deleted</option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-0">
                      {/* <div className="form-check py-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="is_picked"
                    aria-label="Checkbox for following text input"
                    {...register("is_picked")}
                  />
                  <label htmlFor="is_picked" className="form-check-label">
                    Picked
                  </label>
                </div> */}
                      <div className="form-check form-switch py-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="is_picked"
                          role="switch"
                          value=""
                          {...register("is_picked")}
                        />
                        <label className="form-check-label" htmlFor="is_picked">
                          Picked
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="description"
                    className="form-label sr-only d-flex align-items-center"
                  >
                    <span>Descrtiption</span>
                    <button
                      type="button"
                      onClick={(e) => generateResourcesDesc()}
                      className="ms-2 btn btn-light btn-xs me-2"
                      disabled={
                        isGenerating || isGeneratingDesc || !mergeImageBase64
                      }
                    >
                      <i className="bi bi-activity"></i>
                    </button>
                    {(isGenerating || isGeneratingDesc) && (
                      <div className="loader"></div>
                    )}
                  </label>
                  {/* include validation with required or other standard HTML validation rules */}
                  <textarea
                    id="description"
                    className="form-control"
                    defaultValue={""}
                    rows={6}
                    placeholder={"Add short description for resource"}
                    {...register("description")}
                    disabled={isGenerating || isGeneratingDesc}
                  />
                  <div className="text-muted text-sm small my-2">
                    {"Description should be 120 to 320 characters"}
                    {/* {getValues("meta_title").length} */}
                    {watchDescription && (
                      <div
                        className="d-inline"
                        dangerouslySetInnerHTML={{
                          __html: checkLength(watchDescription, 120, 320),
                        }}
                      />
                    )}
                  </div>
                  <div className="form-check py-2 d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="generate_meta"
                      aria-label="Checkbox for following text input"
                      {...register("generate_meta")}
                    />
                    <label
                      htmlFor="generate_meta"
                      className="form-check-label ms-2"
                    >
                      <span> Generate meta data</span>
                    </label>
                    <button
                      type="button"
                      onClick={(e) => generateResourcesKeywords()}
                      className="ms-2 me-2 btn btn-light btn-xs"
                      disabled={
                        isGenerating ||
                        isGeneratingKeywords ||
                        !mergeImageBase64
                      }
                    >
                      <i className="bi bi-activity"></i>
                    </button>
                    {(isGenerating || isGeneratingKeywords) && (
                      <div className="loader"></div>
                    )}
                  </div>
                  <div className="mb-3">
                    <input
                      type={"text"}
                      className="form-control"
                      defaultValue={""}
                      placeholder={"Enter canonical resources id"}
                      {...register("canonical_resource")}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type={"text"}
                      className="form-control"
                      defaultValue={""}
                      placeholder={"Enter resource keywords"}
                      {...register("new_keywords")}
                    />
                  </div>
                </div>

                <div className="d-grid gap-2">
                  <input
                    value={selectedId}
                    type={"hidden"}
                    {...register("resource_ids")}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    Update
                  </button>
                </div>
              </form>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </header>
      {message && (
        <div className="mb-4">
          <div className={`alert text-break alert-${messageType}`} role="alert">
            {message}
          </div>
        </div>
      )}
      {/* <Search/> */}
      <div className="mb-3">
        <Filter
          defaultFilter={defaultFilter}
          filters={filters}
          reset={handleReset}
        />
        {/* <ResourceFilter
          defaultFilter={defaultFilter}
          categories={categories}
          filterHandle={filterHandle}
          resetHandle={handleReset}
        /> */}

        {/* <Limit pageSize={pageSize} sizeHandle={handlePageSize}/> */}
        {/* <div className="col-md-2 text-right">
              <Sorter sorterHandle={sorterHandle} />
            </div> */}
      </div>
      {isLoading ? (
        <p className="text-center p-5">Loading</p>
      ) : (
        <>
          {resources && resources.length > 0 ? (
            <>
              {/* <Table className="table-hover mb-4" responsive> */}
              {/* <thead>
                  <tr>
                    <th scope="col" className="fw-normal text-muted">
                      
                    </th>
                    <th
                      scope="col"
                      className="fw-normal text-muted ps-0"
                      style={{ width: "20%" }}
                    >
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="Name"
                        name="name"
                      />
                    </th>
                    <th
                      scope="col"
                      className="fw-normal text-muted text-center"
                      style={{ width: "15%" }}
                    >
                      Preview
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Picked
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Type
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Orientation
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Creator
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      License
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="Created At"
                        name="created_at"
                      />
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Last Update
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Status
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Action
                    </th>
                  </tr>
                </thead> */}
              <div className="rgrid-toolbar d-flex align-items-center justify-content-between mb-4">
                <div className="check-action d-flex align-items-center justify-content-between">
                  <button
                    className="btn bg-light px-3 btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCheckAll();
                    }}
                  >
                    {selectedId.length > 0 ? "Unselect all" : "Select all"}
                  </button>
                  {selectedId.length > 0 && (
                    <div className="small mx-4 text-muted">
                      {selectedId.length} {"selected"}
                    </div>
                  )}
                </div>

                <div className="rgrid-sorter d-flex align-items-center">
                  <div className="me-4 d-none d-md-block">
                    <strong>Sort by</strong>
                  </div>
                  <div className="me-4">
                    <Sorter
                      defaultFilter={defaultFilter}
                      query={query}
                      title="Name"
                      name="name"
                    />
                  </div>
                  <div className="me-4">
                    <Sorter
                      defaultFilter={defaultFilter}
                      query={query}
                      title="Created At"
                      name="created_at"
                    />
                  </div>
                </div>
              </div>
              <div className="row border-top-0 gx-2">
                {resources.map((resource, index) => {
                  return (
                    <div
                      key={index}
                      className={`col-2 mb-4 rgrid-item align-middle`}
                    >
                      <div
                        className={`rcontent position-relative p-3 border ${
                          selectedId.indexOf(resource._id.trim()) > -1
                            ? "checked"
                            : ""
                        }`}
                      >
                        {selectedId.indexOf(resource._id.trim()) > -1 ? (
                          <div className="item-check position-absolute">
                            <i className="bi bi-check-lg text-success fs-5"></i>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="text-center">
                          {resource?.thumbnail?.path ? (
                            <>
                              {/* <PreviewImage
                                  image={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                  height={120}
                                  alt={resource.name}
                                /> */}
                              {/* <img src={`http://localhost/magipik-fastapi/${resource.preview[0].path}`} width={80} className='me-auto'/> */}

                              {resource?.originalImage?.path ? (
                                <a
                                  href={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LazyLoadImage
                                    alt={resource.name}
                                    src={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                    height={160}
                                    effect="blur"
                                    className="img-preview"
                                  />
                                </a>
                              ) : (
                                <>
                                  <LazyLoadImage
                                    alt={resource.name}
                                    src={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                    height={160}
                                    effect="blur"
                                    className="img-preview"
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {resource?.originalImage?.path && (
                                <a
                                  href={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LazyLoadImage
                                    alt={resource.name}
                                    src={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                    height={160}
                                    delayMethod="debounce"
                                    effect="blur"
                                    className="img-preview"
                                  />
                                </a>
                              )}
                            </>
                          )}
                        </div>
                        <div className="d-flex justify-content-start align-items-center py-1">
                          <span>
                            {format.formatResourceStatusNoText(resource.status)}
                          </span>
                          <div className="small mt-1 ms-2">
                            <span className="ms-2">
                              {format.formatResourceOrientation(
                                resource.orientation
                              )}
                            </span>
                          </div>
                        </div>
                        <div>
                          <Link to={`/resources/view/${resource._id}`}>
                            <strong className="rname small">
                              {resource.name}
                            </strong>
                          </Link>
                          <div className="d-flex align-items-center py-1 small">
                            <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                              {resource.slug.split("-").pop()}
                            </small>
                            {resource.metaSeo &&
                            resource.metaSeo.canonicalURL ? (
                              <>
                                <span className="badge bg-primary bg-opacity-10 text-primary rounded-pill ms-2">
                                  {"C"}
                                </span>
                                <span className="badge bg-light ms-1 text-dark fw-normal ">
                                  {resource.metaSeo.canonicalURL
                                    .split("-")
                                    .pop()}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {resource.metaSeo &&
                            resource.metaSeo.redirectURL ? (
                              <>
                                <span className="badge bg-danger rounded-pill ms-2">
                                  {"R"}
                                </span>
                                <span className="badge bg-light ms-1 text-dark">
                                  {resource.metaSeo.redirectURL
                                    .split("-")
                                    .pop()}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="d-none rdetail p-3 position-absolute top-0 bottom-0 start-0 end-0">
                          {/* <dl className="row mb-5">
                            <dt className="col-sm-3 fw-normal text-muted mb-2">Status</dt>
                            <dd className="col-sm-9 text-capitalize">
                              {format.formatResourceStatus(resource.status)}
                            </dd>
                          </dl> */}
                          <div className="mb-1">
                            <Link to={`/resources/view/${resource._id}`}>
                              <strong className="rname small">
                                {resource.name}
                              </strong>
                            </Link>
                          </div>
                          <p className="small text-muted mb-1">
                            {resource._id}
                            <span className="small text-left ms-2">
                              {resource.is_picked}
                              {resource.is_picked && (
                                <>
                                  <i className="bi bi-check-lg text-success"></i>
                                </>
                              )}
                            </span>
                          </p>
                          <div className="d-flex align-items-center py-1 small mb-1">
                            <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                              {resource.slug.split("-").pop()}
                            </small>
                            {resource.metaSeo &&
                            resource.metaSeo.canonicalURL ? (
                              <>
                                <span className="badge bg-primary bg-opacity-10 text-primary rounded-pill ms-2">
                                  {"C"}
                                </span>
                                <span className="badge bg-light ms-1 text-dark fw-normal ">
                                  {resource.metaSeo.canonicalURL
                                    .split("-")
                                    .pop()}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {resource.metaSeo &&
                            resource.metaSeo.redirectURL ? (
                              <>
                                <span className="badge bg-danger rounded-pill ms-2">
                                  {"R"}
                                </span>
                                <span className="badge bg-light ms-1 text-dark">
                                  {resource.metaSeo.redirectURL
                                    .split("-")
                                    .pop()}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="text-muted mb-1 small">
                            <Link
                              to={`/resources/?created_by=${resource.creator.username}`}
                            >
                              {resource.creator.username}
                            </Link>
                          </div>
                          <div className="small mb-1">
                            <span className="badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                              {resource.contentType.toUpperCase()}
                            </span>
                            <span className="ms-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                              {resource.license.toUpperCase()}
                            </span>
                          </div>

                          <div className="text-muted no-break mb-1 d-flex align-items-center">
                            <span className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                              <i className="bi bi-clock me-1"></i>
                              <span className="">
                                <TimeAgo date={resource.created_at} />
                              </span>
                            </span>
                            <Moment
                              format="YYYY/MM/DD HH:mm:ss"
                              className="small my-1"
                            >
                              {resource.created_at}
                            </Moment>
                            {/* {new Date(resource.created_at).toDateString()} */}
                          </div>
                          <div className="text-muted no-break mb-1 d-flex align-items-center">
                            {resource.updated_at && (
                              <>
                                <span className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                                  <i className="bi bi-clock me-1"></i>
                                  <span className="">
                                    <TimeAgo date={resource.updated_at} />
                                  </span>
                                </span>
                                <Moment
                                  format="YYYY/MM/DD HH:mm:ss"
                                  className="small my-1"
                                >
                                  {resource.updated_at}
                                </Moment>
                              </>
                            )}
                            {/* {resource.updated_at && new Date(resource.updated_at).toDateString()} */}
                          </div>
                        </div>
                        <div className="d-none p-3 item-action d-flex align-items-center justify-content-between position-absolute bottom-0 start-0 end-0">
                          <Link
                            to={`/resources/edit/${resource._id}`}
                            className="btn btn-sm mx-1 rounded"
                          >
                            <i className="bi bi-pencil text-muted"></i>
                          </Link>
                          <Link
                            to={`/resources/view/${resource._id}`}
                            className="btn btn-sm mx-1"
                          >
                            <i className="bi bi-eye text-muted"></i>
                          </Link>
                          <button
                            className="btn btn-sm mx-1"
                            onClick={(e) => {
                              e.preventDefault();
                              removeResource(resource._id);
                            }}
                          >
                            <i className="bi bi-trash2 text-muted"></i>
                          </button>
                          {resource?.originalImage?.path && (
                            <a
                              href={`${MEDIA_URL}${resource?.originalImage?.path}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-sm mx-1"
                            >
                              <i className="bi bi-image text-muted"></i>
                            </a>
                          )}
                          <div className="mx-1">
                            <input
                              type="checkbox"
                              value={resource._id}
                              className="form-check-input"
                              {...register(`resource_id.${index}`)}
                              //name={resource._id}
                              onChange={(e) => handleIdChange(e)}
                              //checked={selectedId.includes(resource._id) ? 'checked' : ''}
                              checked={
                                selectedId.indexOf(resource._id.trim()) > -1
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="my-4">
                <Pagination
                  count={count}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  path={"resources"}
                  moduleName={"resource"}
                  query={query}
                />
              </div>
            </>
          ) : (
            <p className="my-5 py-2 text-center bg-warning bg-opacity-10 text-warning rounded">
              {"No resource founded"}
            </p>
          )}
        </>
      )}
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;

  const { message, messageType } = state.message;
  return {
    token,
    message,
    messageType,
  };
}

export default connect(mapStateToProps)(ResourceGrid);

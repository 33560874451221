import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REFRESH_TOKEN,
  SET_MESSAGE,
} from "./types";

import AuthAPI from "../../api/authAPI";

export const registerUser = (data) => (dispatch) => {
  // console.log(data)
  return AuthAPI.register(data.email, data.password).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: response.data.message,
      // });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password) => (dispatch) => {
  return AuthAPI.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token: data },
      });
      return Promise.resolve();
    },
    (error) => {
      // const message =
      //   ( error.response &&
      //     error.response.data &&
      //     error.response.data.message) ||
      //     error.message ||
      //     error.toString();
      console.log(error.response.data.detail);
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: error.response.data.detail,
      });
      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthAPI.logout();
  dispatch({
    type: LOGOUT,
  });
};

export const refreshToken = (refresh_token) => (dispatch) => {
  AuthAPI.refresh(refresh_token).then(
    (data) => {
      //console.log(data)
      dispatch({
        type: REFRESH_TOKEN,
        payload: data,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
  // dispatch({
  //   type: REFRESH_TOKEN,
  //   payload: {},
  // })
};
